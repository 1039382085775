import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import PinOnMapIcon from "../../../../assets/icons/pin-on-map.svg?react";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { LocalisationCardSkeleton } from "./LocalisationCardSkeleton";
import {MapView} from "../../../MapView/MapView";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {useRentalLocalisationUpdate} from "../../../../hooks/api/rental";
import {useParams} from "react-router-dom";
import {geocodeLatLng, getCityAndZip} from "../../../../helpers/geocodeHelper";
import axios from "axios";
import countries from "../../../../constants/countries";

export const RentalLocalisationsCard: ({rentals, rentalInformations}: {
  rentalInformations: any
}) => (React.JSX.Element) = ({
  rentalInformations,
}) => {
  const {
    rental,
    onUpdateRental,
    loadingState: { loading, setLoading },
    informations: {
      form,
    },
  } = useRentalPageContext();
  const { rentalId } = useParams();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [address, setAddress] = useState<string>(rentalInformations?.address);
  const [marker, setMarker] = useState({
    lat: rentalInformations?.latitude ?? 0,
    lng: rentalInformations?.longitude ?? 0,
  });
  const [informations, setInformations] = useState({rentalInformations})

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const { t } = useTranslation();

  const requiredFields: { address: boolean; latitude: boolean; longitute: boolean } = {
    longitute: true,
    latitude: true,
    address: true,
  };

  useEffect(() => {
    if (rentalInformations && editMode === true) {
      setMarker({
        lat: parseFloat(rentalInformations.latitude) ,
        lng: parseFloat(rentalInformations.longitude),
      })
      setAddress(rentalInformations.address);
      form.setValue("longitude", rentalInformations?.longitude);
      form.setValue("latitude", rentalInformations?.latitude);
      form.setValue("address", rentalInformations?.address);
      setInformations(rentalInformations);
      setValidationLoading(false);
    }
  }, [informations, editMode]);
  useEffect(() => {
    const updateAddressAndMarker = async () => {
      const latitude = parseFloat(form.watch("latitude") ?? 0);
      const longitude = parseFloat(form.watch("longitude") ?? 0);

      if (latitude && longitude) {
        try {
          const address = await geocodeLatLng(latitude, longitude);
          setAddress(address);

          setMarker({
            lat: latitude,
            lng: longitude,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    updateAddressAndMarker();
  }, [form.watch('longitude'), form.watch('latitude')]);

  useEffect(() => {
    form.setValue("longitude", marker.lng);
    form.setValue("latitude", marker.lat);
  }, [marker]);

  const handleEditLocalisation = async ()=> {
    //console.log('handleEditLocalisation', marker, address);
    initSubmit()
    setEditMode(true);
    const { city, zip,countryCode } = await getCityAndZip(address);

    const data = {
      address: address,
      latitude: form.getValues("latitude"),
      longitude: form.getValues("longitude"),
      postal_code: zip,
      city: city,
      country_code: countryCode,
    }

    useRentalLocalisationUpdate(
        rentalId,
        data,
        (data: any) => {
          onUpdateRental({
            ...rental,
            details: {
                ...rental.details,
               informations: {
                    ...rental.details.informations,
                 address: data.details.informations.address,
                 latitude: data.details.informations.latitude,
                 longitude: data.details.informations.longitude,
               }
            }
          });
          setValidationLoading(false);
          setEditMode(false);
        },
        (message: string | undefined) => {
            setError(message);
            setValidationLoading(false
    )

    });
}


  if (loading) return <LocalisationCardSkeleton />;

  return <Card
    loading={validationLoading}
    Icon={PinOnMapIcon}
    label={t("Rental.Localisation.title")}
    button={{
      Icon: editMode ? CheckIcon : EditIcon,
      type: editMode ? "primary" : "secondary",
      label: editMode ? t("Global.record") : t("Global.edit"),
      onClick: editMode
        ? form.handleSubmit(handleEditLocalisation)
        : () => setEditMode(!editMode),
    }}
    secondaryButton={
      editMode
        ? {
            label: t("Global.cancel"),
            LeftIcon: ArrowLeftIcon,
            onClick: () => setEditMode(false),
          }
        : undefined
    }
  >
    <div className="flex flex-col gap-y-4">
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      {editMode ? <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
              <p className="text-low-contrast text-md">
                {t("Rental.Localisation.address")}
              </p>
              <p className="text-lg font-extrabold">
                {address}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-low-contrast text-md">
                {t("Rental.Localisation.content1")}
              </p>
              <p className="text-low-contrast text-md">
                {t("Rental.Localisation.content2")}
              </p>
            </div>

            <div className="flex flex-col gap-3">
              <MapView marker={marker} setMarker={setMarker} setNewAddress={setAddress}/>
              <div className="flex flex-row gap-2 mt-3">
                <TextInput
                    label={t("AddRental.ConfirmAddress.latitude")}
                    required={requiredFields.latitude}
                    register={form.register("latitude", {
                      required: {
                        value: requiredFields.latitude,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("AddRental.ConfirmAddress.latitude"),
                        }),
                      },
                    })
                    }
                />
                <TextInput
                    label={t("AddRental.ConfirmAddress.longitude")}
                    required={requiredFields.longitute}
                    register={form.register("longitude", {
                      required: {
                        value: requiredFields.longitute,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("AddRental.ConfirmAddress.longitude"),
                        }),
                      },
                    })
                    }
                />
              </div>
            </div>


          </div> : <>
            <div className="flex flex-col justify-between gap-3">
              <div className="flex flex-col mt-2">
                <p className="text-low-contrast text-md">
                  {t("Rental.Localisation.address")}
                </p>
                <p className="text-lg font-extrabold">
                  {rentalInformations?.address}
                </p>
              </div>
              <div className="flex flex-col mt-2">
                <p className="text-low-contrast text-md">
                  {t("Rental.Localisation.longitude")}
                </p>
                <p className="text-ld font-extrabold">
                {rentalInformations?.longitude}
              </p>
            </div>
            <div className="flex flex-col mt-2">
              <p className="text-low-contrast text-md">
                {t("Rental.Localisation.latitude")}
              </p>
              <p className="text-lg font-extrabold">
                {rentalInformations?.latitude}
              </p>
            </div>
          </div>
        </>}
    </div>
  </Card>;
};
