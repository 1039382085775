import React from "react";
import InformationIcon from "../../../assets/icons/information.svg?react";

const InfoCard = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <div className="flex p-4 rounded-lg gap-x-3 bg-element-background items-start">
      <div className="flex flex-col">
        <InformationIcon />
      </div>
      <div className="flex flex-col whitespace-pre-line">
        <p
          className={`font-bold text-low-contrast ${
            title && "pb-3"
          }`}
        >
          {title}
        </p>
        <p className="font-light text-low-contrast">{description}</p>
      </div>
    </div>
  );
};

export default InfoCard;
