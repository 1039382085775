import React, {useState} from "react";
import {
  GuestPageExtra,
  GuestPageExtraDetail,
  GuestPageExtraOrder,
  GuestPageGuestConnectData,
  GuestPageLoginDetailsResponse,
  RentalExtraAvailableListItemResponse,
} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import {ExtrasOrder} from "./ExtraComponent";
import useExtrasStore from "../../../stores/guestPageStore/useExtrasStore";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {useTranslation} from "react-i18next";
import {getListAvailableUntilData} from "../../../helpers/extraHelper";

const ExtraCardComponent = ({
  extra,
  onChangeExtra,
  fromPage = "home",
  loading,
}: {
  extra:
    | GuestPageExtra
    | GuestPageExtraDetail
    | RentalExtraAvailableListItemResponse;
  guest: GuestPageGuestConnectData | undefined;
  onChangeExtra?: (extraOrder: ExtrasOrder, toRemove?: boolean) => void;
  fromPage: "home" | "extra" | "extraPayment" | "extraHistorical";
  loading?: boolean;
}) => {
  const {t} = useTranslation();
  const defaultLimitQuantity = 10;
  const {extrasOrder} = useExtrasStore();
  const {guestPageData, userPreview, isPreviewMode} = useGuestPageDataStore();
  const {guest: guestData, rental} = guestPageData || {};
  const {currency} = rental || {};
  const extraOrder = extrasOrder?.find((extr) => extr.id === extra.id);
  const {id, name, description, photo, extra_enabled, available_until} = extra;
  const unit_price =
    "unit_price" in extra
      ? extra.unit_price
      : "unit_price_value" in extra
      ? extra.unit_price_value
      : undefined;
  const maximum_quantity =
    "maximum_quantity" in extra
      ? extra.maximum_quantity === -1
        ? Infinity
        : extra.maximum_quantity
      : defaultLimitQuantity;

  const guest = isPreviewMode ? userPreview : guestData;
  const savedLanguage = localStorage.getItem("language")
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  const nameExtra = name ? (name?.[lang] ? name[lang] : name["en"]) : "";
  const descriptionExtra = description
    ? description?.[lang]
      ? description[lang]
      : description["en"]
    : "";
  const [isOpen, setIsOpen] = useState(extraOrder ? true : false);
  const defaultQuantity = 1;
  const quantityExtra =
    "quantity" in extra
      ? extra.quantity
      : extraOrder?.quantity
      ? extraOrder.quantity
      : defaultQuantity;
  const [quantity, setQuantity] = useState(
    fromPage === "extraPayment" || fromPage === "extraHistorical"
      ? quantityExtra
      : extraOrder
      ? extraOrder?.quantity
        ? extraOrder.quantity
        : defaultQuantity
      : defaultQuantity
  );
  const src = photo?.[0]?.original_url ?? "";
  const availableUntil = getListAvailableUntilData(t, available_until)?.label;
  const handleOpenExtra = () => {
    const status = !isOpen;
    setIsOpen(status);
    if (!status) {
      setQuantity(defaultQuantity);
      onChangeExtra && onChangeExtra({id}, true);
    } else {
      onChangeExtra && onChangeExtra({id, quantity: defaultQuantity});
    }
  };

  const deleteExtra = () => {
    onChangeExtra && onChangeExtra({id}, true);
  };
  const decreaseQuantity = () => {
    if (quantity > defaultQuantity) {
      setQuantity(quantity - 1);
      onChangeExtra && onChangeExtra({id, quantity: quantity - 1});
      return;
    }
    onChangeExtra && onChangeExtra({id, quantity: defaultQuantity});
  };
  const increaseQuantity = () => {
    if (maximum_quantity > quantity) {
      setQuantity(quantity + 1);
      onChangeExtra && onChangeExtra({id, quantity: quantity + 1});
    }
  };
  const fromHomeOrExtra = fromPage === "home" || fromPage === "extra";
  const fromHistorical = fromPage === "extraHistorical";

  if (!extra_enabled && !fromHistorical) {
    return null;
  }
  return (
    <div className="flex items-center space-x-4 mb-4 md:w-full">
      <img
        src={src}
        alt={nameExtra}
        className="w-[96px] h-[96px] md:w-[150px] md:h-[150px] rounded-md object-cover"
      />
      <div className="flex-1">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h2 className="text-high-contrast font-semibold text-base md:text-lg w-[80%] md:w-auto">
              {nameExtra}
            </h2>
            <p className="text-low-contrast text-xs md:text-sm w-[80%] md:w-auto">
              {descriptionExtra}
            </p>
            {fromPage !== "extraPayment" && availableUntil && (
              <span className="text-low-contrast text-xs md:text-sm w-[80%] md:w-auto mt-2 italic">
                (
                {`${t(
                  "Rental.Infos.TravelerPage.ExtraCard.available"
                )} ${availableUntil}`}
                )
              </span>
            )}
            {!fromHomeOrExtra ? (
              <div className="flex items-center justify-between">
                <span
                  id="quantity"
                  className="text-high-contrast font-bold text-lg md:text-xl"
                >
                  {quantity} x {unit_price} {currency?.symbol ?? "€"}
                </span>
              </div>
            ) : (
              isOpen && (
                <div className="flex items-center justify-between w-1/3">
                  <Button
                    type="secondary"
                    className="px-4 pl-0 py-1 h-8 border-none"
                    buttonClassName="border-0 shadow-none ml-0 pl-2"
                    textClassName="md:text-xl"
                    onClick={decreaseQuantity}
                    disabled={loading}
                  >
                    <MinusIcon className="w-5 h-5" />
                  </Button>
                  <span
                    id="quantity"
                    className="text-high-contrast font-bold text-lg"
                  >
                    {quantity}
                  </span>
                  <Button
                    type="secondary"
                    className="px-4 py-1 h-8"
                    buttonClassName="border-0 shadow-none"
                    textClassName="md:text-xl"
                    onClick={increaseQuantity}
                    disabled={loading}
                  >
                    <PlusIcon className="w-5 h-5" />
                  </Button>
                </div>
              )
            )}
          </div>
          <div className="flex flex-col">
            {fromHomeOrExtra && (
              <div className="flex flex-1 flex-row text-active font-semibold text-lg md:text-xl">
                <span>{unit_price} </span>
                <span className="ml-1">{currency?.symbol ?? "€"}</span>
              </div>
            )}
            <div className="flex mt-4">
              {!fromHomeOrExtra &&
              !fromHistorical ? null : fromHistorical ? null : isOpen ? ( // </Button> //   <TrashIcon className="w-4 h-4" /> // > //   onClick={deleteExtra} //   className="w-6 h-6 flex items-center justify-center border border-gray-300 rounded" //   type="secondary" // <Button
                <Button
                  type="primary"
                  className="w-6 h-6 flex items-center justify-center border border-gray-300 rounded md:h-12"
                  textClassName="md:text-xl"
                  onClick={handleOpenExtra}
                  disabled={loading}
                >
                  <CheckWhiteIcon className="w-3 h-3" />
                </Button>
              ) : (
                <Button
                  type="secondary"
                  className="w-6 h-6 flex items-center justify-center border border-gray-300 rounded md:h-12"
                  textClassName="md:text-xl"
                  onClick={handleOpenExtra}
                  disabled={loading}
                >
                  <PlusIcon className="w-3 h-3" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraCardComponent;
