import React from "react";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { Badge } from "../../Common/Badge/Badge";
import { ManagementTeamRoleBadge } from "./ManagementTeamRoleBadge";

export const ManagementTeamMemberItem: React.FC<{
  member: OperationalManagementTeamMemberResponse;
  onView: (member: OperationalManagementTeamMemberResponse) => void;
  onEdit: (member: OperationalManagementTeamMemberResponse) => void;
}> = ({ member, onView, onEdit }) => {
  const { t } = useTranslation();

  const isAccepted = () => {
    return (
      member.accepted === 1 &&
      member.first_name !== "" &&
      member.last_name !== ""
    );
  };

  return (
    <div className="flex flex-row items-center gap-2">
      {/* Avatar */}
      {isAccepted() ? (
        <img
          src={member.photo}
          className="size-12 rounded-full bg-element-background border-element-border border-1 shrink-0 cursor-pointer"
          onClick={() => onView(member)}
        />
      ) : (
        <div
          className="size-12 rounded-full bg-element-background border-element-border border-1 shrink-0 cursor-pointer"
          onClick={() => onView(member)}
        />
      )}

      {/* Name, phone and email */}
      <div
        className="flex flex-col w-80 cursor-pointer"
        onClick={() => onView(member)}
      >
        {isAccepted() && (
          <>
            <p className="font-semibold text-high-contrast">
              {member.first_name} {member.last_name}
            </p>

            <p className="font-light text-low-contrast">
              {member.email} - {member.phone}
            </p>
          </>
        )}

        {!isAccepted() && (
          <>
            <p className="font-light text-low-contrast mb-1 cursor-pointer">
              {member.email}
            </p>

            <Badge label={t("Management.TeamList.invitSent")} size="small" />
          </>
        )}
      </div>

      {/* Nb of rentals */}
      <div className="flex-1 cursor-pointer" onClick={() => onView(member)}>
        <p className="font-light text-low-contrast">
          {t("Management.TeamList.rentalsAssigned", {
            count: member.rentals_access?.length,
          })}
        </p>
      </div>

      {/* Roles */}
      <div
        className="w-fit flex flex-row items-center gap-1 cursor-pointer"
        onClick={() => onView(member)}
      >
        {member.postes?.map((role) => (
          <ManagementTeamRoleBadge
            key={role.id}
            role={role as OperationalManagementRoleListItemResponse}
          />
        ))}
      </div>

      {/* Actions */}
      <div className="flex items-center gap-2">
        <EyeIcon
          className="size-5 cursor-pointer"
          onClick={() => onView(member)}
        />
        <EditIcon
          className="size-5 cursor-pointer"
          onClick={() => onEdit(member)}
        />
      </div>
    </div>
  );
};
