import React, {useEffect, useState} from "react";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {useTranslation} from "react-i18next";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {TableListWithGroup} from "../../components/Common/TableList/TableListWithGroup";
import {ShortcodeListPageHeader} from "../../components/ShortcodeList/ShortcodeListPageHeader";
import {ShortcodeListPageItem} from "../../components/ShortcodeList/ShortcodeListPageItem";
import {ShortcodeListPageSkeleton} from "../../components/ShortcodeList/ShortcodeListPageSkeleton";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import {ShortcodeListPageFiler} from "../../components/ShortcodeList/ShortcodeListPageFilter";
import {GroupButton} from "../../components/Common/GroupButton/GroupButton";
import {ShortcodeCategoryEnum} from "./ShortcodeListPage.type";
import {ValueType} from "../../types/commonTypes";
import {useModal} from "../../hooks/useModal";
import {ShortcodeListPageRemoveModal} from "../../components/ShortcodeList/ShortcodeListPageRemoveModal";
import {ShortcodeListItemResponse} from "../../types/GETTypes";
import {ShortcodeUpsertModal} from "../../components/ShortcodeList/ShortcodeUpsertModal";

export const ShortcodeListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [currentCategory, setCurrentCategory] = useState<ShortcodeCategoryEnum>(
    ShortcodeCategoryEnum.ALL
  );

  const removeModal = useModal<ValueType>();
  const upsertModal = useModal<ShortcodeListItemResponse>();

  const transform = (data: any) => {
    if (!data) return data;

    let tmpCodes: any[] = [];
    if (data.custom) tmpCodes = [...tmpCodes, ...data.custom];
    if (data.default) tmpCodes = [...tmpCodes, ...data.default];

    const result: any[] = [];

    tmpCodes?.forEach((code: any) => {
      const indexGroup = result.findIndex((g: any) => g.id === code.type);

      if (indexGroup === -1) {
        result.push({
          id: code.type,
          name: capitalizeFirstLetter(code.type),
          rentals: [{ ...code }],
        });
      } else {
        result[indexGroup].rentals.push(code);
      }
    });

    return result;
  };

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}`,
    "shortcodes",
    {
      transform,
      otherMetas: ["total_custom_shortcodes", "total_default_shortcodes"],
    }
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  const handleChangeCategory = (value: ShortcodeCategoryEnum) => {
    setCurrentCategory(value);
    tablePage.handleFilterCustom("category", value);
  };

  const handleClickItem = async (id: ValueType | null, action: ValueType) => {
    if (action === "remove") {
      removeModal.open(id ?? 0);
    } else {
      const indexCustom = tablePage?.data.findIndex(
        (d: any) => d.id === "custom"
      );
      const index = tablePage.data[indexCustom].rentals.findIndex(
        (d: any) => d.id === id
      );
      if (index !== -1) {
        upsertModal.open(tablePage.data[indexCustom].rentals[index]);
      }
    }
  };

  const handleRemoveSuccess = () => {
    removeModal.close();
    tablePage.fetch({ search: tablePage.currentSearch });
  };

  const handleUpsertSuccess = () => {
    upsertModal.close();
    setCurrentCategory(ShortcodeCategoryEnum.ALL);
    tablePage.fetch({ search: tablePage.currentSearch });
  };

  return (
    <>
      <ShortcodeListPageRemoveModal
        id={removeModal.data}
        isVisible={removeModal.isVisible}
        onClose={removeModal.close}
        onSuccess={handleRemoveSuccess}
      />

      <ShortcodeUpsertModal
        data={upsertModal.data}
        isVisible={upsertModal.isVisible}
        onClose={upsertModal.close}
        onUpsertSuccess={handleUpsertSuccess}
      />

      <MainLayout
        title={t("ShortcodeList.title")}
        sidebarActiveItem="auto_messages"
        sidebarActiveSubItem="shortcodes"
      >
        <div className="flex flex-col">
          <ShortcodeListPageFiler
            onSearchText={tablePage.handleChangeSearch}
            onAdd={upsertModal.open}
          />

          <div className="h-4 w-full" />

          <GroupButton
            items={[
              {
                label: t("ShortcodeList.all"),
                value: ShortcodeCategoryEnum.ALL,
                isActive: currentCategory === ShortcodeCategoryEnum.ALL,
                badge: tablePage.total?.toString(),
              },
              {
                label: t("ShortcodeList.default"),
                value: ShortcodeCategoryEnum.DEFAULT,
                isActive: currentCategory === ShortcodeCategoryEnum.DEFAULT,
                badge: tablePage.currentOtherMetas
                  .find((m) => m.key === "total_default_shortcodes")
                  ?.value?.toString(),
              },
              {
                label: t("ShortcodeList.custom"),
                value: ShortcodeCategoryEnum.CUSTOM,
                isActive: currentCategory === ShortcodeCategoryEnum.CUSTOM,
                badge: tablePage.currentOtherMetas
                  .find((m) => m.key === "total_custom_shortcodes")
                  ?.value?.toString(),
              },
            ]}
            onClick={(value) =>
              handleChangeCategory(value as ShortcodeCategoryEnum)
            }
          />

          <TableListWithGroup
            i18nElement="ShortcodeList.shortcode"
            // Filters={getGroupViewFiltersNode()}
            // ViewTypeGroupButton={getGroupButtonViewTypeNode()}
            Header={ShortcodeListPageHeader}
            Item={ShortcodeListPageItem}
            Skeleton={ShortcodeListPageSkeleton}
            NoData={<div>NODATA</div>}
            tablePage={tablePage}
            nbCols={3}
            onClick={(id) => handleClickItem(id, "edit")}
            onClickAction={handleClickItem}
          />
        </div>
      </MainLayout>
    </>
  );
};
