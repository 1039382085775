import React from "react";
import { Card } from "../../Common/Card/Card";
import {
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import UsersIcon from "../../../assets/icons/users.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { ManagementTeamMemberItem } from "./ManagementTeamMemberItem";
import { ValueType } from "../../../types/commonTypes";
import { ManagementTeamListEmpty } from "./ManagementTeamListEmpty";

export const ManagementTeamListGroupItem: React.FC<{
  team: OperationalManagementTeamListItemResponse;
  onAdd: (teamId: ValueType) => void;
  onEdit: (team: OperationalManagementTeamListItemResponse) => void;
  onViewMember: (params: {
    member: OperationalManagementTeamMemberResponse;
    team: OperationalManagementTeamListItemResponse;
  }) => void;
  onEditMember: (params: {
    member: OperationalManagementTeamMemberResponse;
  }) => void;
}> = ({ team, onAdd, onEdit, onViewMember, onEditMember }) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={UsersIcon}
      label={team.name}
      button={{
        label: t("Management.addMember"),
        Icon: PlusIcon,
        onClick: () => onAdd(team.id),
      }}
      secondaryButton={{
        label: "",
        Icon: EditIcon,
        onClick: () => onEdit(team),
      }}
    >
      <div className="flex flex-col gap-3">
        {team.members?.length === 0 && <ManagementTeamListEmpty />}

        {team.members?.map((member) => (
          <ManagementTeamMemberItem
            key={member.id}
            member={member}
            onView={() => onViewMember({ member, team })}
            onEdit={() => onEditMember({ member })}
          />
        ))}
      </div>
    </Card>
  );
};
