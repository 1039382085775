import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useFetchDays } from "../../../hooks/api/calendar";
import { CalendarDay } from "../../../pages/CalendarList/CalendarListPage.type";
import { CalendarInputValueRange } from "../CalendarInput/CalendarInput.type";
import { DateFilterInit, DateRangePreset } from "./DateFilter.type";

/**
 *
 * @returns
 */
export const useInitDateFilterStates = () => {
  const [isDateRangePreset, setIsDateRangePreset] = useState<boolean>(false);

  return {
    isDateRangePresetStates: [isDateRangePreset, setIsDateRangePreset],
  } as const;
};

/**
 *
 * @param dates
 * @param onStart
 * @param onSuccessFetchDays
 * @param onErrorFetchDays
 * @param onStartFetchDays
 * @param onEndFetchDays
 */
export const useHandleChangeDates = (
  dates: CalendarInputValueRange,
  onStart: (nbRangeDate: number) => void,
  onSuccessFetchDays: (days: CalendarDay | undefined) => void,
  onErrorFetchDays: (message: string | undefined) => void,
  onStartFetchDays?: () => void,
  onEndFetchDays?: () => void
) => {
  if (dates.length >= 2) {
    const nextStartDate: Date | null = dates[0];
    const nextEndDate: Date | null = dates[1];

    onStart(moment(nextEndDate).diff(moment(nextStartDate), "days") + 1);

    if (
      (nextStartDate === null && nextEndDate === null) ||
      (nextStartDate instanceof Date && nextEndDate instanceof Date)
    ) {
      useFetchDays(
        nextStartDate,
        nextEndDate,
        onSuccessFetchDays,
        onErrorFetchDays,
        onStartFetchDays,
        onEndFetchDays
      );
    }
  }
};

/**
 *
 * @param params {DateFilterInit}
 */
export const useInitDateFilter = (params: DateFilterInit) => {
  const today: Date = new Date();

  const { dateRangePreset, dates, onChangeDates, onDatesChanged } = params;

  // * We update the date when we click on the predefined date range selection option.
  useEffect(() => {
    const nextSevenDays: Date = moment(today).add(6, "days").toDate();
    const lastFourWeeks: Date = moment(today).add(-4, "weeks").toDate();
    const lastThreeMonths: Date = moment(today).add(-3, "months").toDate();
    const lastTwelveMonths: Date = moment(today).add(-12, "months").toDate();

    switch (dateRangePreset) {
      case "all":
        onChangeDates([null, null]);
        return;
      case "today":
        onChangeDates([today, today]);
        return;
      case "next_seven_days":
        onChangeDates([today, nextSevenDays]);
        return;
      case "last_four_weeks":
        onChangeDates([lastFourWeeks, today]);
        return;
      case "last_three_months":
        onChangeDates([lastThreeMonths, today]);
        return;
      case "last_twelve_months":
        onChangeDates([lastTwelveMonths, today]);
        return;
    }
  }, [dateRangePreset]);

  useEffect(() => {
    onDatesChanged();
  }, [dates]);
};

/**
 *
 * @param value
 * @param setDateRangePreset
 * @param setIsDateRangePreset
 */
export const useChangeDateRangePreset = (
  value: ValueType,
  setDateRangePreset: Dispatch<SetStateAction<DateRangePreset>>,
  setIsDateRangePreset: Dispatch<SetStateAction<boolean>>
) => {
  setDateRangePreset(value as DateRangePreset);
  setIsDateRangePreset(false);
};
