import React, {useState} from "react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import CopyIcon from "../../../assets/icons/copy.svg?react";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {Tooltip} from "../../Common/Tooltip/Tooltip";
import {cn} from "../../../helpers/classHelper";

const WifiComponent = () => {
  const {t} = useTranslation();
  const {guestPageData, rentalPreview, isPreviewMode} = useGuestPageDataStore();
  const {wifi_name, wifi_password} = guestPageData || {};
  const {
    guest_page: {
      wifi_name: wifiNamePreview,
      wifi_password: wifiPasswordPreview,
    } = {},
  } = rentalPreview || {};

  const [copyPopUp, setCopyPopUp] = useState({name: false, password: false});

  const handleCopy = (text: string | undefined, type: "name" | "password") => {
    if (text) {
      setCopyPopUp((prevState) => ({
        ...prevState,
        [type]: true,
      }));
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        setCopyPopUp((prevState) => ({
          ...prevState,
          [type]: false,
        }));
      }, 2000);
    }
  };

  const wifiName = isPreviewMode ? wifiNamePreview : wifi_name;
  const wifiPassword = isPreviewMode ? wifiPasswordPreview : wifi_password;
  const wifiConnectUrl = `wifi://S:${wifiName};T:WPA;P:${wifiPassword};`;

  return (
    <LayoutBlockComponent
      title={`${t("GuestPage.Wifi.title")} 🛜`}
      blockStyle={{
        titleStyle: "flex flex-row items-center md:text-[32px] md:pl-0",
        childrenDivStyle: "pl-2 md:pl-1",
      }}
      displayTitles
    >
      <div className="flex flex-1 flex-col md:mt-3 w-full mb-6">
        <div className="flex flex-1 flex-row w-full mb-4 items-center">
          {wifiName ? (
            <>
              <div className="flex flex-1 flex-row items-center justify-between">
                <span className=" text-high-contrast  md:text-lg">
                  {t("GuestPage.Wifi.wifi_name")}
                </span>
              </div>
              <div className="flex flex-col">
                {copyPopUp.name && (
                  <div
                    className={`flex flex-1 bg-element-border opacity-100  w-12 px-8 py-2 rounded shadow-lg transition-opacity duration-300 justify-center self-end mb-2 md:mr-4`}
                  >
                    <span className="text-white text-center  justify-center">
                      {t("GuestPage.Wifi.copy")}
                    </span>
                  </div>
                )}
                <div className="flex flex-row">
                  <span
                    className="text-high-contrast font-bold md:text-lg cursor-pointer"
                    onClick={() => handleCopy(wifiName, "name")}
                  >
                    {wifiName}
                  </span>
                  <div
                    className="flex md:text-lg md:items-center md:mr-[10px] cursor-pointer"
                    onClick={() => handleCopy(wifiName, "name")}
                  >
                    <CopyIcon className="ml-1 " />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="flex flex-1 flex-row w-full mb-4 items-center">
          {wifiPassword ? (
            <>
              <div className="flex flex-1 flex-row items-center justify-between">
                <span className=" text-high-contrast  md:text-lg">
                  {t("GuestPage.Wifi.wifi_password")}
                </span>
              </div>
              <div className="flex flex-col">
                {copyPopUp.password && (
                  <div
                    className={`flex flex-1 bg-element-border  w-12 px-8 py-2 rounded shadow-lg transition-opacity duration-300 justify-center self-end mb-2 md:mr-4`}
                  >
                    <span className="text-white text-center font-bold justify-center">
                      {t("GuestPage.Wifi.copy")}
                    </span>
                  </div>
                )}
                <div className="flex flex-row">
                  <span
                    className="text-high-contrast font-bold md:text-lg cursor-pointer"
                    onClick={() => handleCopy(wifiPassword, "password")}
                  >
                    {wifiPassword}
                  </span>
                  <div
                    className="flex md:text-lg md:items-center md:mr-[10px] cursor-pointer"
                    onClick={() => handleCopy(wifiPassword, "password")}
                  >
                    <CopyIcon className="ml-1" />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <>
        {wifiName && wifiPassword ? (
          <a href={wifiConnectUrl} className="block md:hidden">
            <Button
              buttonClassName="md:h-12"
              textClassName="md:text-xl"
              RightIcon={ArrowRightIcon}
              displayLargeBtn={true}
              type="secondary"
            >
              {t("GuestPage.Wifi.connect")}
            </Button>
          </a>
        ) : null}
      </>
    </LayoutBlockComponent>
  );
};

export default WifiComponent;
