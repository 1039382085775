import React from "react";
import { useTranslation } from "react-i18next";
import { RentalPlatformsCardAirbnbProps } from "./Platforms.type";
import { RentalPlatformsCardElement } from "./PlatformsCardElement";

import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import InfoIcon from "../../../../assets/icons/information.svg?react";

export const RentalPlatformsCardAirbnb: React.FC<
  RentalPlatformsCardAirbnbProps
> = ({ platformAirbnb,airBnbListingData, onClick }) => {
  const { t } = useTranslation();
  const {email} = airBnbListingData || {}

    if (platformAirbnb === null || !platformAirbnb?.enabled)
    return (
      <RentalPlatformsCardElement
        classNames={{
          container: "cursor-default",
        }}
        onClick={onClick}
      >
        <div className="rounded-full h-3 w-3 bg-[#E5484D]" />

        <AirbnbIcon className="w-6 h-6" />

        <p className="font-semibold text-low-contrast">
          {t("Rental.Infos.Platforms.inactive")}
        </p>
      </RentalPlatformsCardElement>
    );
  else
    return (
      <RentalPlatformsCardElement onClick={onClick}>
        <div className="w-3 h-3 rounded-full bg-success" />

        <AirbnbIcon className="w-6 h-6" />

        <p className="font-semibold text-high-contrast">
          {t("Rental.Infos.Platforms.active")} •{" "}
          {t("Rental.Infos.Platforms.airbnbAssign")} {platformAirbnb.email}
        </p>
        <InfoIcon className="w-5 h-5" />
      </RentalPlatformsCardElement>
    );
};
