import React from "react";
import { Card } from "../../Common/Card/Card";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import CloseCircleIcon from "../../../assets/icons/close-circle.svg?react";
import CloseWhiteIcon from "../../../assets/icons/close-white.svg?react";
import { ReservationResponse } from "../../../types/GETTypes";
import { ReservationStatusEnum } from "../../../enums/GETenums";
import { useModal } from "../../../hooks/useModal";
import { ValueType } from "../../../types/commonTypes";
import { ResaCancelModal } from "../../Calendar/ResaCancelModal";

export const ReservationCancelCard: React.FC<{
  loading: boolean;
  reservation: ReservationResponse | undefined;
  onUpdate: (nextStatus: ReservationStatusEnum) => void;
}> = ({ reservation, loading, onUpdate }) => {
  const { t } = useTranslation();

  const cancelResaModal = useModal<ValueType>();

  const handleSuccessCancelResa = (nextStatus: ReservationStatusEnum) => {
    cancelResaModal.close();

    onUpdate(nextStatus);
  };

  if (
    reservation?.status_reservation !==
      ReservationStatusEnum.CONFIRMED_COMING &&
    reservation?.status_reservation !==
      ReservationStatusEnum?.CONFIRMED_PROGRESS
  )
    return null;
  else if (reservation?.platform?.name.toLowerCase() !== "direct") return null;

  return (
    <>
      <ResaCancelModal
        isVisible={cancelResaModal.isVisible}
        onClose={cancelResaModal.close}
        reservationId={cancelResaModal.data}
        onSuccess={handleSuccessCancelResa}
      />

      <Card label={t("Booking.CancelCard.title")} Icon={CloseCircleIcon}>
        <Button
          type="destructive"
          RightIcon={CloseWhiteIcon}
          disabled={loading}
          onClick={() => cancelResaModal.open(reservation?.id)}
        >
          {t("Booking.CancelCard.button")}
        </Button>
      </Card>
    </>
  );
};
