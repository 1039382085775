import React from "react";
import {useTranslation} from "react-i18next";

import moment from "moment";
import ExportIcon from "../../../../assets/icons/export.svg?react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import {PaymentReservationStatusEnum} from "../../../../enums/GETenums";
import {Button} from "../../../Common/Button/Button";
import {CalendarInputValueRange} from "../../../Common/CalendarInput/CalendarInput.type";
import {DateFilter} from "../../../Common/DateFilter/DateFilter";
import {GroupButton} from "../../../Common/GroupButton/GroupButton";
import {GroupButtonItemType} from "../../../Common/GroupButton/GroupButton.type";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {useInitPaymentReservationFiltersStates} from "./PaymentReservationFilters.hooks";
import {PaymentReservationFiltersProps} from "./PaymentReservationFilters.type";

export const PaymentReservationFilters: React.FC<
  PaymentReservationFiltersProps
> = ({
  onSearchText,
  totals,
  onFilterButtonClick,
  currentActiveButtonFilter,
  tablePage,
}) => {
  const { t } = useTranslation();

  const initStates = useInitPaymentReservationFiltersStates();
  const [dates, setDates] = initStates.datesState;
  const [, setNbRangeDate] = initStates.nbRangeDateState;
  const [dateRangePreset, setDateRangePreset] = initStates.dateRangePresetState;

  const getGroupButtonItems = (): GroupButtonItemType[] => [
    {
      label: t("Global.all", {
        context: "male",
        count: 2,
      }),
      value: "ALL",
      badge: totals.all,
      isActive: currentActiveButtonFilter === "ALL",
    },
    {
      label: t("Global.unpaid", {
        context: "male",
      }),
      value: PaymentReservationStatusEnum.UNPAID,
      badge: totals.unpaid,
      isActive:
        currentActiveButtonFilter === PaymentReservationStatusEnum.UNPAID,
    },
    {
      label: t("Global.partiallyPaid", {
        context: "male",
      }),
      value: PaymentReservationStatusEnum.PARTIALLY_PAID,
      badge: totals.partiallyPaid,
      isActive:
        currentActiveButtonFilter ===
        PaymentReservationStatusEnum.PARTIALLY_PAID,
    },
    {
      label: t("Global.paid", {
        context: "male",
        count: Number(totals.paid),
      }),
      value: PaymentReservationStatusEnum.PAID,
      badge: totals.paid,
      isActive: currentActiveButtonFilter === PaymentReservationStatusEnum.PAID,
    },
    {
      label: t("Global.overdue"),
      value: PaymentReservationStatusEnum.OVERDUE,
      badge: totals.overdue,
      isActive:
        currentActiveButtonFilter === PaymentReservationStatusEnum.OVERDUE,
    },
    {
      label: t("Global.canceled", {
        context: "male",
      }),
      value: PaymentReservationStatusEnum.REFUNDED,
      badge: totals.canceled,
      isActive:
        currentActiveButtonFilter === PaymentReservationStatusEnum.REFUNDED,
    }
  ];

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-row items-center space-x-3">
        <div className="flex-1">
          <TextInput
            size="large"
            Icon={SearchIcon}
            placeholder={t("Global.search")}
            onChangeText={onSearchText}
          />
        </div>

        {/* TODO: [PaymentReservation] Implement export button logic */}
        <div title={t("Global.wip")}>
          <Button
            type="secondary"
            onClick={() => {}}
            RightIcon={ExportIcon}
            disabled={true}
          >
            {t("Global.export")}
          </Button>
        </div>
      </div>

      <div className="flex flex-row items-center space-x-3">
        <GroupButton
          items={getGroupButtonItems()}
          onClick={onFilterButtonClick}
        />
      </div>

      <div className="flex flex-row items-center space-x-3">
        <div className="flex">
          <DateFilter
            initParams={{
              dateRangePreset: dateRangePreset,
              dates: dates as CalendarInputValueRange,
              onChangeDates: (dates: CalendarInputValueRange) =>
                setDates(dates),
              onDatesChanged: () => {
                if (Array.isArray(dates) && dates.length >= 2) {
                  const startDate: Date | null = dates[0];
                  const endDate: Date | null = dates[1];
                  if (
                    (startDate === null && endDate === null) ||
                    (startDate instanceof Date && endDate instanceof Date)
                  ) {
                    if (startDate === null || endDate === null) {
                      tablePage.fetch({});
                    } else {
                      tablePage.fetch({
                        from: moment(startDate).format("YYYY-MM-DD"),
                        to: moment(endDate).format("YYYY-MM-DD"),
                      });
                    }
                  }
                }
              },
            }}
            datesState={[dates, setDates]}
            dateRangePresetState={[dateRangePreset, setDateRangePreset]}
          />
        </div>
      </div>
    </div>
  );
};
