import React from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { WorkInProgress } from "../../components/WorkInProgress/WorkInProgress";

export const ManagementTaskListPage: React.FC<{}> = () => {
  return (
    <ManagementLayout subTitle="tasks" sidebarActiveSubItem="tasks">
      <WorkInProgress />
    </ManagementLayout>
  );
};
