import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {PaymentExtraProps} from "../Contact/ContactCard.type";
import {CodeCardSkeleton} from "./CodeCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import {Controller, useForm} from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import {TextAreaInput} from "../../../Common/TextAreaInput/TextAreaInput";
import {CodeForm, WifiForm} from "../Instructions/InstructionCard.type";
import {useUpdateWifi} from "../../../../hooks/api/guestPage";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {useCheckAuth} from "../../../../hooks/useCheckAuth";
import {capitalizeFirstLetter} from "../../../../helpers/stringHelper";
import KeyIcon from "../../../../assets/icons/key.svg?react";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {Rental} from "../../../../pages/RentalPage/RentalPage.type";

export const CodeCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
}) => {
  const {user} = useCheckAuth();
  const {t} = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const instructionArrival = guestPage?.instructions?.find(
    (i: any) => i.type === "arrival_instruction"
  );

  const [entryCode, setEntryCode] = useState(instructionArrival?.entry_code);
  const [keyBoxCode, setKeyBoxeCode] = useState(
    instructionArrival?.key_box_code
  );
  if (loading) return <CodeCardSkeleton />;

  const form = useForm<CodeForm>({
    defaultValues: {
      entry_code: entryCode,
      key_box_code: keyBoxCode,
    },
  });

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleUpdateCode = async (data: CodeForm) => {
    initSubmit();

    if (instructionArrival) {
      try {
        const res = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.RENTALS_UPDATE
          }/${rentalId}${paths.API.GUEST_PAGE_UPDATE_CODE}/${
            instructionArrival.id
          }`,
          data
        );
        if (res?.data.success) {
          const data: Rental = res?.data?.result;
          if (data) {
            const instruction = data?.guest_page?.instructions?.find(
              (i: any) => i.type === "arrival_instruction"
            );
            if (instruction) {
              setEntryCode(instruction?.entry_code);
              setKeyBoxeCode(instruction?.key_box_code);
            }
            setValidationLoading(false);
            setEditMode(false);
          }
        } else {
          setError(res?.data?.message ?? res.message);
          setValidationLoading(false);
        }
      } catch (error: any) {
        setError(error?.message);
      }
    }
  };

  useEffect(() => {}, [user]);

  return (
    <Card
      loading={validationLoading}
      Icon={KeyIcon}
      label={t("Rental.Infos.TravelerPage.CodeCard.title")}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit((data: CodeForm) => {
              handleUpdateCode(data);
            })
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            <div className="flex flex-1 flex-row items-center text-sm text-low-contrast">
              <span>
                {t("GuestPage.Reservation.codeDescription")}{" "}
                <span className="font-bold">
                  {t("GuestPage.Reservation.codeDescription2")}
                </span>{" "}
                {t("GuestPage.Reservation.codeDescription3")}{" "}
                <span className="font-bold">
                  {t("GuestPage.Reservation.codeDescription4")}
                </span>{" "}
                {t("GuestPage.Reservation.codeDescription5")}
              </span>
            </div>
            <Controller
              control={form.control}
              name="entry_code"
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("GuestPage.Reservation.codeBuilding")}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                    />
                  </div>
                );
              }}
            />
            <Controller
              control={form.control}
              name="key_box_code"
              render={({field: {value, onChange}}) => {
                return (
                  <div className="flex flex-col">
                    <TextInput
                      label={t("GuestPage.Reservation.codeKeys")}
                      value={value}
                      onChangeText={(text) => {
                        onChange(text);
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-y-2">
                <p className="text-low-contrast">
                  {t("GuestPage.Reservation.codeBuilding")}
                </p>
                <p className="text-high-contrast font-bold">{entryCode}</p>
              </div>
              <div className="flex flex-col gap-y-2">
              <p className="text-low-contrast">
                  {t("GuestPage.Reservation.codeKeys")}
                </p>
                <p className="text-high-contrast font-bold">{keyBoxCode}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
