import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { Button } from "../../components/Common/Button/Button";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { EditPlatformCard } from "../../components/Setting/EditPlatformCard";
import { EditProfilCard } from "../../components/Setting/EditProfilCard";
import { ResetPasswordModal } from "../../components/Setting/ResetPasswordModal";
import { useCheckAuth } from "../../hooks/useCheckAuth";

export const SettingsPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { user, setUser } = useCheckAuth();
  const [isResetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);

  const types: string[] = JSON.parse(user?.lead?.types ?? "[]");
  const platforms: string[] = JSON.parse(user?.lead?.platforms ?? "[]");
  const isSynchroWithAirbnb = Boolean(user?.is_synchro_with_airbnb);
  const isSynchroWithBooking = Boolean(user?.is_synchro_with_booking);
  const synchroList = {
    isSynchroWithAirbnb: isSynchroWithAirbnb,
    isSynchroWithBooking: isSynchroWithBooking,
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Settings.title")} <ChevronRightIcon />
        {t("Settings.profil")}
      </p>
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="settings"
        sidebarActiveSubItem="profil"
      >
        <div className="flex flex-row space-x-5">
          <div className="w-3/5 space-y-4">
            <EditProfilCard user={user} onUpdate={setUser} />
            <ResetPasswordModal
              isVisible={isResetPasswordModalVisible}
              onClose={() => setResetPasswordModalVisible(false)}
              onFinish={() => {
                setResetPasswordModalVisible(false);
              }}
            />
            <Button
              type="secondary"
              onClick={() => setResetPasswordModalVisible(true)}
            >
              {t("Settings.edit_password")}
            </Button>
          </div>
          <div className="w-2/5">
            <EditPlatformCard
              lead={user?.lead}
              types={types}
              platforms={platforms}
              onUpdate={setUser}
              synchroList={synchroList}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
};
