import React, {useEffect, useState} from "react";
import {Button} from "../Button/Button";
import {MultiSelectRentalsProps} from "./MultiSelectRentals.type";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ValueType} from "../../../types/commonTypes";
import {RentalLightListItemResponse} from "../../../types/GETTypes";
import {MultiSelectRentalsModal} from "./MultiSelectRentalsModal";

import FilterIcon from "../../../assets/icons/filter_.svg?react";
import FilterFullIcon from "../../../assets/icons/filter-active.svg?react";

export const MultiSelectRentals: React.FC<MultiSelectRentalsProps> = ({
  size = "normal",
  onSelect = () => {},
}) => {
  const [items, setItems] = useState<RentalLightListItemResponse[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [allRentals, setAllRentals] = useState<ValueType[]>([]);
  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const fetchRentals = async () => {
    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}?limit=-1`;
    const res = await get(URL);

    if (res.data?.success) {
      const resRentals: RentalLightListItemResponse[] =
        res.data?.result?.rentals;

      setAllRentals(resRentals?.map((r: RentalLightListItemResponse) => r.id));

      setSelectedRentals(
        resRentals?.map((r: RentalLightListItemResponse) => r.id)
      );
      setItems(resRentals);
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleSearchRentals = async(text: string) => {
    setLoadingSearch(true);

    const URL = `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}?search=${text}&limit=-1`;
    const res = await get(URL);

    if (res.data?.success) {
      const resRentals: RentalLightListItemResponse[] =
        res.data?.result?.rentals;
      setItems(resRentals);
    } else {
      setError(res?.response?.data?.message);
    }

    setLoadingSearch(false);
  };

  useEffect(() => {
    setError(null);

    if (isModalVisible && items.length === 0) fetchRentals();
  }, [isModalVisible]);

  const handleApply = () => {
    onSelect(selectedRentals);
    setIsModalVisible(false);

    setIsFiltered(allRentals?.length !== selectedRentals?.length);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedRentals([]);
  };

  return (
    <>
      <MultiSelectRentalsModal
        isVisible={isModalVisible}
        loading={loading}
        loadingSearch={loadingSearch}
        error={error}
        items={items}
        allRentals={allRentals}
        selectedRentals={selectedRentals}
        onApply={handleApply}
        onSelect={(ids) => setSelectedRentals(ids)}
        onSearch={handleSearchRentals}
        onClose={handleClose}
      />

      <Button
        type="secondary"
        size={size}
        onClick={() => setIsModalVisible(true)}
        borderButton={isFiltered ? "border-1 border-active" : "border-1 border-black/12"}
      >
        {isFiltered ? (
          <FilterFullIcon className="w-4 h-4" />
        ) : (
          <FilterIcon className="w-4 h-4" />
        )}
      </Button>
    </>
  );
};
