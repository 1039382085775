import React from "react";
import { Switch } from "../../Common/Switch/Switch";
import { useTranslation } from "react-i18next";
import { Separator } from "../../Common/Separator/Separator";
import {
  OperationalManagementRolePermissionNameResponse,
  OperationalManagementRoleRightResponse,
} from "../../../types/GETTypes";
import DashboardIcon from "../../../assets/icons/dashboard.svg?react";

import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import UserCheckIcon from "../../../assets/icons/user-check.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import RobotIcon from "../../../assets/icons/robot.svg?react";
import ClipBoardIcon from "../../../assets/icons/clipboard.svg?react";
import PieChartIcon from "../../../assets/icons/pie-chart.svg?react";
import EyeOffIcon from "../../../assets/icons/eye-off.svg?react";
import { PermissionItem } from "./PermissionItem";

export type AccessControleType = {
  permissions: OperationalManagementRolePermissionNameResponse[];
  rights: OperationalManagementRoleRightResponse[];
};

export const ManagementAccessControl: React.FC<{
  value: AccessControleType;
  onChange: (newAccess: AccessControleType) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const handleClickPermission = (
    perm: OperationalManagementRolePermissionNameResponse
  ) => {
    const permIndex = value.permissions.findIndex((p) => p === perm);
    if (permIndex === -1)
      onChange({ ...value, permissions: [...value.permissions, perm] });
    else
      onChange({
        ...value,
        permissions: value.permissions.filter((p) => p !== perm),
      });
  };

  const handleClickRight = (right: OperationalManagementRoleRightResponse) => {
    console.log("handleClickRight", right);
    const rightIndex = value.rights.findIndex((r) => r === right);
    if (rightIndex === -1)
      onChange({ ...value, rights: [...value.rights, right] });
    else
      onChange({
        ...value,
        rights: value.rights.filter((r) => r !== right),
      });
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="font-semibold text-low-contrast">
        {t("Management.Right.accessTitle")}
      </p>

      <p className="font-light text-low-contrast">
        {t("Management.Right.accessSubTitle")}
      </p>

      <div className="flex flex-col gap-4 pb-2 w-full">
        <PermissionItem
          Icon={DashboardIcon}
          accessName="dashboard"
          subItems={["dashboard_reservations", "dashboard_prestation"]}
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={CalendarIcon}
          accessName="calendars"
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={UserCheckIcon}
          accessName="reservations"
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={MoneyIcon}
          accessName="payments"
          subItems={[
            "payment_reservations",
            "payment_cautions",
            "payment_extras",
            "payment_mean_of_collection",
            "payment_invoices",
          ]}
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={MailIcon}
          accessName="messages"
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={RobotIcon}
          accessName="auto_messages"
          subItems={["automated_messages", "message_dynamics_fields"]}
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={ClipBoardIcon}
          accessName="management"
          subItems={[
            "operational_management_tasks",
            "operational_management_team",
            "operational_management_automation",
            "operational_management_payment",
          ]}
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />

        <PermissionItem
          Icon={PieChartIcon}
          accessName="analytics"
          selectedAccesses={value.permissions}
          onChange={handleClickPermission}
        />
      </div>

      <Separator />

      <p className="font-semibold text-low-contrast">
        {t("Management.Right.visibilityTitle")}
      </p>

      {/* Hide prices */}
      <div
        className="flex items-center cursor-pointer p-2 border-1 rounded-6px border-element-border bg-element-background gap-3"
        onClick={() => handleClickRight("hide_price")}
      >
        <EyeOffIcon className="size-5" />

        <div className="flex-1">
          <p className="font-semibold text-high-contrast">
            {t("Management.Right.hidePrices")}
          </p>

          <p className="font-light text-low-contrast text-xs">
            {t("Management.Right.hidePricesDescription")}
          </p>
        </div>

        <Switch
          value={value.rights?.findIndex((r) => r === "hide_price") !== -1}
        />
      </div>

      {/* Hide infos */}
      <div
        className="flex items-center cursor-pointer p-2 border-1 rounded-6px border-element-border bg-element-background gap-3"
        onClick={() => handleClickRight("hide_sensitive")}
      >
        <EyeOffIcon className="size-5" />

        <div className="flex-1">
          <p className="font-semibold text-high-contrast">
            {t("Management.Right.hideInfos")}
          </p>

          <p className="font-light text-low-contrast text-xs">
            {t("Management.Right.hideInfosDescription")}
          </p>
        </div>

        <Switch
          value={value.rights?.findIndex((r) => r === "hide_sensitive") !== -1}
        />
      </div>

      {/* Can edit */}
      <div
        className="flex items-center cursor-pointer p-2 border-1 rounded-6px border-element-border bg-element-background gap-3"
        onClick={() => handleClickRight("no_edit")}
      >
        <EyeOffIcon className="size-5" />

        <div className="flex-1">
          <p className="font-semibold text-high-contrast">
            {t("Management.Right.noEdit")}
          </p>

          <p className="font-light text-low-contrast text-xs">
            {t("Management.Right.noEditDescription")}
          </p>
        </div>

        <Switch
          value={value.rights?.findIndex((r) => r === "no_edit") !== -1}
        />
      </div>
    </div>
  );
};
