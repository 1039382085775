import React from "react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import { RentalSmartlocksDeviceItemResponse } from "../../../types/GETTypes";

export const ReservationLockItem: React.FC<{
  lock: RentalSmartlocksDeviceItemResponse;
}> = ({ lock }) => {
  return (
    <div className="flex items-center justify-between w-full p-2 rounded bg-subtle border-1 border-element-border">
      <div className="flex items-center space-x-4">
        <ArrowRightIcon />
        <div className="flex flex-col space-y-1">
          <p className="text-base font-bold text-nowrap text-high-contrast">
            {lock.name}
          </p>
          {/* {lock.provider !== "manual" ? (
          <p className="text-base break-all whitespace-normal text-low-contrast">
            0206468U
          </p>
        ) : null} */}
        </div>
      </div>

      {/* <div className="flex flex-col items-end space-y-2">
      <div className="flex items-center space-x-4">
        <p className="text-base font-light truncate select-none max-w-96 text-low-contrast">
          {lock.lockCode}
        </p>
        <p className="text-xl font-bold text-active text-nowrap">
          {lock.code}
        </p>
      </div>

      <p className="p-1 font-bold bg-white rounded select-none text-nowrap border-1 border-element-border text-low-contrast">
        {lock.provider === "manual"
          ? "Code fixe"
          : "Code dynamique"}
      </p>

      {lock.provider === "manual" ? (
        <p className="text-low-contrast">Actif tout le temps</p>
      ) : (
        <>
          <p className="text-low-contrast">
            Actif du 21/10/2024 à 10h00
          </p>
          <p className="text-low-contrast">
            Jusqu'au 22/10/2024 à 15h00
          </p>
        </>
      )}
    </div> */}
    </div>
  );
};
