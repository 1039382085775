import React, { useEffect } from "react";
import { Separator } from "../Separator/Separator";
import { Title } from "../Title/Title";
import { RightModalProps } from "./RightModal.type";

import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import { cn } from "../../../helpers/classHelper";

export const RightModal: React.FC<RightModalProps> = ({
  title,
  subTitle = "",
  subTitleStyle,
  isVisible,
  children,
  isSplit = false,
  isLarge = false,
  closeOnEmpty = true,
  rightHeaderNode,
  bottomHeaderNode,
  hasSkeleton = false,
  isDouble = false,
  onClose = () => {},
  secondTitle,
  isVisibleSecondModal,
  onBack,
  onCloseSecondModal,
  separatorAccent,
  classNames,
}) => {
  const width =
    isSplit || isLarge
      ? "w-modal-xl"
      : isDouble && isVisibleSecondModal
      ? "w-modal-xl"
      : "w-modal";

  useEffect(() => {
    // Prevents unwanted scrolling while the modal is open
    const body: HTMLElement | null = document.querySelector("body");
    if (body != null) {
      body.style.overflow = isVisible ? "hidden" : "visible";
    }
  }, [isVisible]);

  const handleCloseOnEmpty = () => {
    if (closeOnEmpty) onClose();
  };

  return (
    <>
      {isVisible ? (
        <div
          className={cn(
            "fixed top-0 left-0 flex flex-row w-screen h-screen bg-black/25 z-[99]",
            classNames?.divParent
          )}
        >
          <div className="flex flex-1 " onClick={handleCloseOnEmpty}></div>

          {/* Right Modal */}
          <div className={`bg-white ${width} min-h-screen flex relative`}>
            {/* Vertical separator */}
            {isSplit && (
              <div className="absolute w-1 h-full border-l-1 border-element-border left-[32rem]" />
            )}

            <div className="flex flex-row w-full">
              {isDouble && isVisibleSecondModal && (
                <div className="flex flex-col flex-1 h-screen pt-4">
                  <div
                    className={cn(
                      `flex flex-row items-center px-4 space-x-3`,
                      `${hasSkeleton ? "animate-pulse" : null}`
                    )}
                  >
                    {hasSkeleton ? (
                      <>
                        <h1 className="flex-1 w-32 h-6 rounded bg-slate-200"></h1>
                        <div className="flex items-center space-x-3">
                          <div className="w-6 h-6 rounded bg-slate-200"></div>
                        </div>
                      </>
                    ) : (
                      <>
                        {typeof title === "string" ? (
                          <div className="flex-1 text-left">
                            <Title>{title}</Title>
                            {subTitle && (
                              <Title style={subTitleStyle}>{subTitle}</Title>
                            )}
                          </div>
                        ) : (
                          <div className="flex-1">{secondTitle}</div>
                        )}

                        {rightHeaderNode !== undefined ? rightHeaderNode : null}

                        <div className="flex items-center space-x-3">
                          {onBack ? (
                            <div
                              className="pt-1 hover:cursor-pointer"
                              onClick={onBack}
                            >
                              <ArrowLeftIcon />
                            </div>
                          ) : (
                            <div
                              className="pt-1 hover:cursor-pointer"
                              onClick={onCloseSecondModal}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <Separator accent={separatorAccent} />

                  {Array.isArray(children) && children.length > 0 ? (
                    <div
                      className={cn(
                        "flex flex-1 p-4 pt-2 overflow-y-scroll text-sm border-r-1",
                        classNames?.mainContentParent
                      )}
                    >
                      {children[1]}
                    </div>
                  ) : null}
                </div>
              )}
              <div
                className={cn(
                  "flex flex-col flex-1 h-screen pt-4",
                  classNames?.divModal
                )}
              >
                <div
                  className={cn(
                    `flex flex-row items-center px-4 space-x-3`,
                    `${hasSkeleton ? "animate-pulse" : null}`
                  )}
                >
                  {hasSkeleton ? (
                    <>
                      <h1 className="flex-1 w-32 h-6 rounded bg-slate-200"></h1>
                      <div className="flex items-center space-x-3">
                        <div className="w-6 h-6 rounded bg-slate-200"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {typeof title === "string" ? (
                        <div className="flex-1 text-left">
                          <Title>{title}</Title>
                        </div>
                      ) : (
                        <div className="flex-1">{title}</div>
                      )}

                      {rightHeaderNode !== undefined ? rightHeaderNode : null}

                      <div className="flex items-center space-x-3">
                        {onBack ? (
                          <div
                            className="pt-1 hover:cursor-pointer"
                            onClick={onBack}
                          >
                            <ArrowLeftIcon />
                          </div>
                        ) : (
                          <div
                            className="pt-1 hover:cursor-pointer"
                            onClick={onClose}
                          >
                            <CloseIcon />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="px-4 mt-2">
                  {bottomHeaderNode !== undefined ? bottomHeaderNode : null}
                </div>

                <Separator accent={separatorAccent} />

                <div
                  className={cn(
                    "flex flex-1 p-4 pt-2 overflow-y-scroll text-sm",
                    classNames?.mainContentParent
                  )}
                >
                  {isDouble
                    ? Array.isArray(children) && children.length > 0
                      ? children[0]
                      : null
                    : children}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};