import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "../../../Common/Card/Card";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {PaymentExtraProps} from "../Contact/ContactCard.type";
import {PaymentExtraCardSkeleton} from "./PaymentExtraCardSkeleton";
import {EditStripeForm} from "../../../../pages/Settings/Settings.type";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import {Controller, useForm} from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {RequiredFields} from "../../../../types/commonTypes";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {useNavigate} from "react-router-dom";
import {Button} from "../../../Common/Button/Button";
import PlusWhiteIcon from "../../../../assets/icons/plus-white.svg?react";

export const PaymentExtartCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [paymentExtra, setPaymentExtra] = useState(
    guestPage?.extras_payment_account_id
  );

  const list_paymentAccounts = guestPage?.payment_accounts
    ? guestPage.payment_accounts
    : [];
  const reformatedPaymentAccounts = list_paymentAccounts.map(
    (paymentAccount: any) => {
      return {
        value: paymentAccount.id,
        label: paymentAccount.name,
      };
    }
  );

  if (loading) return <PaymentExtraCardSkeleton />;

  const {
    control,
    handleSubmit,
    formState: {isValid, errors},
    setValue,
    getValues,
  } = useForm<EditStripeForm>({
    mode: "all",
  });

  const requiredFields: RequiredFields<EditStripeForm> = {
    stripe_account: true,
  };

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  useEffect(() => {
    if (paymentExtra && editMode === true) {
      setValue("stripe_account", paymentExtra.id);
    }
  }, [paymentExtra, editMode]);

  const handleUpdatAccountStripe = async (data: EditStripeForm) => {
    initSubmit();
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }${paths.API.GUEST_PAGE_EXTRAS_PAYMENT_ACCOUNT}`,
      {
        extras_payment_account_id: data.stripe_account,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data?.success) {
      setValidationLoading(false);
      setEditMode(false);
    } else {
      setError(response.data?.message);
      setValidationLoading(false);
    }
  };
  const isEmpty =
    (!reformatedPaymentAccounts || reformatedPaymentAccounts?.length === 0) &&
    !paymentExtra;
  return (
    <Card
      loading={validationLoading}
      Icon={MoneyIcon}
      label={t("Rental.Infos.TravelerPage.PaymentExtraCard.title")}
      {...(!isEmpty && {
        button: {
          Icon: editMode ? CheckIcon : EditIcon,
          type: editMode ? "primary" : "secondary",
          label: editMode ? t("Global.record") : t("Global.edit"),
          onClick: editMode
            ? handleSubmit((data: EditStripeForm) => {
                handleUpdatAccountStripe(data);
              })
            : () => setEditMode(!editMode),
        },
      })}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {isEmpty ? (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2 items-center">
              <p className="text-low-contrast">
                {t(
                  "Rental.Infos.TravelerPage.PaymentExtraCard.emptyStripeTitle"
                )}
              </p>
              <Button
                RightIcon={PlusWhiteIcon}
                onClick={() => navigate(`${paths.PAYMENTS_ACCOUNTS}?modal=1`, { replace: true })}
              >
                {t(
                  "Rental.Infos.TravelerPage.PaymentExtraCard.addStripeAccount"
                )}
              </Button>
            </div>
          </div>
        ) : editMode ? (
          <div className="flex flex-col">
            <div>
              <Controller
                control={control}
                name="stripe_account"
                rules={{
                  required: {
                    value: requiredFields.stripe_account,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Profil.country"),
                    }),
                  },
                  onChange: (event) => {
                    setValue("stripe_account", event.target.value);
                  },
                }}
                render={({field: {onChange}}) => (
                  <div className="flex-1">
                    <InputSelect
                      required={requiredFields.stripe_account}
                      disabled={validationLoading}
                      label={t(
                        "Rental.Infos.TravelerPage.PaymentExtraCard.choiceAccountStripe"
                      )}
                      selectedValue={getValues("stripe_account")}
                      defaultValue={getValues("stripe_account")}
                      items={reformatedPaymentAccounts}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.PaymentExtraCard.stripeTitle")}
              </p>
              {paymentExtra && (
                <div className="flex flex-row justify-between">
                  <p className="text-low-contrast text-xs">
                    {t("Rental.Infos.TravelerPage.PaymentExtraCard.by_stripe")}
                  </p>
                  <p className="font-bold">{paymentExtra.name}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
