import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import BookmarkIcon from "../../../../assets/icons/bookmark-2.svg?react";
import CalendarTimerIcon from "../../../../assets/icons/calendar-timer.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import EggTimerIcon from "../../../../assets/icons/egg-timer.svg?react";
import FastForwardIcon from "../../../../assets/icons/fast-forward.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { Separator } from "../../../Common/Separator/Separator";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import { TimeInput } from "../../../Common/TimeInput/TimeInput";
import {
  RentalReservationRulesCardForm,
  RentalReservationRulesCardProps,
  RentalReservationRulesCardUpdateRequest,
} from "./ReservationRulesCard.type";

export const RentalReservationRulesCard: React.FC<
  RentalReservationRulesCardProps
> = ({ rentalReservationRules }) => {
  const { t } = useTranslation();

  const { rentalId } = useParams();
  const { rental, onUpdateRental } = useRentalPageContext();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const getWindowReservationItems = () => [
    {
      label: t(
        "Rental.Infos.ReservationRules.WindowReservationFields.atLeastMonth",
        { count: 24 }
      ),
      value: 0,
      id: 24,
    },
    {
      label: t(
        "Rental.Infos.ReservationRules.WindowReservationFields.atLeastMonth",
        { count: 12 }
      ),
      value: 1,
      id: 12,
    },
    {
      label: t(
        "Rental.Infos.ReservationRules.WindowReservationFields.atLeastMonth",
        { count: 9 }
      ),
      value: 2,
      id: 9,
    },
    {
      label: t(
        "Rental.Infos.ReservationRules.WindowReservationFields.atLeastMonth",
        { count: 6 }
      ),
      value: 3,
      id: 6,
    },
    {
      label: t(
        "Rental.Infos.ReservationRules.WindowReservationFields.atLeastMonth",
        { count: 3 }
      ),
      value: 4,
      id: 3,
    },
  ];

  const getWindowReservationLabel = (): string => {
    const max_days_prior_reservations: number | null =
      rentalReservationRules?.max_days_prior_reservations ?? null;
    let string = "";

    let value = max_days_prior_reservations;
    if (value != 0 && value != 1 && value != 2 && value != 3 && value != 4) {
      value = convertDaysToMonths(max_days_prior_reservations);
    }

    if (value != null) {
      getWindowReservationItems().forEach((i) => {
        if (value === i.value) {
          string = i.label;
        }
      });
    }
    return string;
  };

  const convertDaysToMonths = (days) => {
    const daysPerMonth = 30.44;
    const month = Math.round(days / daysPerMonth);
    return getWindowReservationItems().find((i) => i.id === month)?.value;
  };

  const getSetUpLabel = (): string => {
    const label =
      rentalReservationRules?.duration_between_reservations > 0
        ? t(
            "Rental.Infos.ReservationRules.SetUpFields.nbNightAfterReservation_other",
            { count: rentalReservationRules?.duration_between_reservations }
          )
        : t("Rental.Infos.ReservationRules.SetUpFields.none");

    return label;
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useForm<RentalReservationRulesCardForm>({
    mode: "all",
  });

  useEffect(() => {
    if (
      rentalReservationRules?.min_days_prior_reservations !== null &&
      rentalReservationRules?.min_days_prior_reservations !== 0
    ) {
      setValue("reservation_daytime_type", "day");
    } else {
      setValue("reservation_daytime_type", "hour");
    }

    setValue(
      "reservation_daytime_limit",
      rentalReservationRules?.reservation_daytime_limit ?? null
    );
    setValue(
      "min_days_prior_reservations",
      rentalReservationRules?.min_days_prior_reservations ?? null
    );
    setValue(
      "max_days_prior_reservations",
      rentalReservationRules?.max_days_prior_reservations ?? null
    );
    setValue(
      "duration_between_reservations",
      rentalReservationRules?.duration_between_reservations ?? null
    );
    setValue(
      "smoking_allowed",
      Boolean(rentalReservationRules?.smoking_allowed)
    );
    setValue(
      "infants_allowed",
      Boolean(rentalReservationRules?.infants_allowed)
    );
    setValue(
      "children_allowed",
      Boolean(rentalReservationRules?.children_allowed)
    );
    setValue("pets_allowed", Boolean(rentalReservationRules?.pets_allowed));
    setValue("events_allowed", Boolean(rentalReservationRules?.events_allowed));
    setValue(
      "additional_rules",
      rentalReservationRules?.additional_rules ?? null
    );
  }, [rentalReservationRules]);

  useEffect(() => {
    if (getValues("reservation_daytime_type") === "hour") {
      setValue("min_days_prior_reservations", null);
    }

    if (getValues("reservation_daytime_type") === "day") {
      setValue("reservation_daytime_limit", "");
    }
  }, [getValues("reservation_daytime_type")]);

  const updateReservationRules = async () => {
    setValidationLoading(true);

    const data: RentalReservationRulesCardUpdateRequest = {
      reservation_daytime_limit: getValues("reservation_daytime_limit"),
      min_days_prior_reservations: getValues("min_days_prior_reservations")
        ? getValues("min_days_prior_reservations")?.toString()
        : null,
      max_days_prior_reservations:
        getValues("max_days_prior_reservations") !== null
          ? getValues("max_days_prior_reservations")?.toString()
          : null,
      duration_between_reservations: getValues("duration_between_reservations")
        ? getValues("duration_between_reservations")?.toString()
        : null,
      smoking_allowed: getValues("smoking_allowed") ? "1" : "0",
      infants_allowed: getValues("infants_allowed") ? "1" : "0",
      children_allowed: getValues("children_allowed") ? "1" : "0",
      pets_allowed: getValues("pets_allowed") ? "1" : "0",
      events_allowed: getValues("events_allowed") ? "1" : "0",
      additional_rules: getValues("additional_rules"),
    };

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_RESERVATIONS_RULES
      }`,
      data
    );

    if (res.data.success) {
      setEditMode(false);
      if (rentalReservationRules) {
        if (rental) {
          onUpdateRental({
              ...rental,
              details: {
                  ...rental.details,
                  rules: {
                      reservation_daytime_limit: getValues(
                          "reservation_daytime_limit"
                      ),
                      min_days_prior_reservations: getValues(
                          "min_days_prior_reservations"
                      ),
                      max_days_prior_reservations: getValues(
                          "max_days_prior_reservations"
                      ),
                      duration_between_reservations: getValues(
                          "duration_between_reservations"
                      ),
                      smoking_allowed: getValues("smoking_allowed") ? 1 : 0,
                      infants_allowed: getValues("infants_allowed") ? 1 : 0,
                      children_allowed: getValues("children_allowed") ? 1 : 0,
                      pets_allowed: getValues("pets_allowed") ? 1 : 0,
                      events_allowed: getValues("events_allowed") ? 1 : 0,
                      additional_rules: getValues("additional_rules") ?? "",
                  },
              },
          });
        }
      }
    } else {
      setError(res.data.message);
    }
    setValidationLoading(false);
  };

  const handleSave = () => {
    updateReservationRules();
  };

  watch();

  return (
    <Card
      loading={validationLoading}
      Icon={BookmarkIcon}
      label={t("Rental.Infos.ReservationRules.title")}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? handleSubmit(handleSave)
          : () => setEditMode(!editMode),
        disabled: editMode && (!isValid || validationLoading),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        <ErrorMessage>{error}</ErrorMessage>
        <div>
          {editMode ? (
            <>
              {/* -- RESERVATION RULES -- */}
              <p className="mb-2 font-bold text-low-contrast">
                {t("Rental.Infos.ReservationRules.reservationRules")}
              </p>

              <div className="flex flex-col space-y-2">
                {/* ADVANCE RESERVATION NOTICE */}
                <SettingElement
                  Icon={CalendarTimerIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.advanceReservationNoticeTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.advanceReservationNoticeDescription"
                  )}
                  secondChildren={
                    <>
                      {getValues("reservation_daytime_limit") !== null ||
                      getValues("min_days_prior_reservations") !== null ? (
                        <div className="flex items-center justify-between space-x-14">
                          <div className="flex-1">
                            {getValues("reservation_daytime_type") ===
                            "hour" ? (
                              <TimeInput
                                disabled={validationLoading}
                                placeholder={t(
                                  "Rental.Infos.ReservationRules.advanceReservationNoticePlaceholder"
                                )}
                                register={register(
                                  "reservation_daytime_limit",
                                  {
                                    required: {
                                      value: false,
                                      message: t(
                                        "Global.Errors.requiredField",
                                        {
                                          fieldName: t(
                                            "Rental.Infos.ReservationRules.advanceReservationNoticeTitle"
                                          ),
                                        }
                                      ),
                                    },
                                  }
                                )}
                                error={
                                  errors?.reservation_daytime_limit?.message
                                }
                                value={
                                  getValues("reservation_daytime_limit") ?? ""
                                }
                              />
                            ) : null}

                            {getValues("reservation_daytime_type") === "day" ? (
                              <NumberInput
                                disabled={validationLoading}
                                acceptDecimal={false}
                                register={register(
                                  "min_days_prior_reservations",
                                  {
                                    required: {
                                      value: false,
                                      message: t(
                                        "Global.Errors.requiredField",
                                        {
                                          fieldName: t(
                                            "Rental.Infos.ReservationRules.advanceReservationNoticeTitle"
                                          ),
                                        }
                                      ),
                                    },
                                  }
                                )}
                                error={
                                  errors.min_days_prior_reservations?.message
                                }
                                value={
                                  getValues("min_days_prior_reservations") ?? 0
                                }
                              />
                            ) : null}
                          </div>

                          <div className="flex space-x-6">
                            <Controller
                              control={control}
                              name="reservation_daytime_type"
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <>
                                    <SimpleRadio
                                      label={t(
                                        "Rental.Infos.ReservationRules.hourLabel"
                                      )}
                                      onClick={() => onChange("hour")}
                                      value={value === "hour"}
                                      classNames={{ style: "font-bold" }}
                                    />
                                    <SimpleRadio
                                      label={t(
                                        "Rental.Infos.ReservationRules.dayLabel"
                                      )}
                                      onClick={() => onChange("day")}
                                      value={value === "day"}
                                      classNames={{ style: "font-bold" }}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  }
                ></SettingElement>

                {/* WINDOW RESERVATION */}
                <SettingElement
                  Icon={FastForwardIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.windowReservationTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.windowReservationDescription"
                  )}
                  secondChildren={
                    <>
                      <Controller
                        control={control}
                        name="max_days_prior_reservations"
                        rules={{
                          onChange: (event) => {
                            setValue(
                              "max_days_prior_reservations",
                              event.target.value
                            );
                          },
                        }}
                        render={({ field: { onChange } }) => (
                          <div className="flex-1">
                            <InputSelect
                              disabled={validationLoading}
                              selectedValue={
                                getValues("max_days_prior_reservations") > 24
                                  ? convertDaysToMonths(
                                      getValues("max_days_prior_reservations")
                                    ) ?? 0
                                  : getValues("max_days_prior_reservations")
                              }
                              items={getWindowReservationItems()}
                              onSelect={onChange}
                            />
                          </div>
                        )}
                      />
                    </>
                  }
                ></SettingElement>

                {/* SET UP */}
                <SettingElement
                  Icon={EggTimerIcon}
                  title={t("Rental.Infos.ReservationRules.setUpTimeTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.setUpTimeDescription"
                  )}
                  secondChildren={
                    <>
                      <Controller
                        control={control}
                        name="duration_between_reservations"
                        rules={{
                          onChange: (event) => {
                            setValue(
                              "duration_between_reservations",
                              event.target.value
                            );
                          },
                        }}
                        render={({ field: { onChange } }) => (
                          <div className="flex-1">
                            <InputSelect
                              disabled={validationLoading}
                              selectedValue={
                                getValues("duration_between_reservations") ?? 0
                              }
                              items={[
                                {
                                  label: t(
                                    "Rental.Infos.ReservationRules.SetUpFields.none"
                                  ),
                                  value: 0,
                                },
                                {
                                  label: t(
                                    "Rental.Infos.ReservationRules.SetUpFields.nbNightAfterReservation",
                                    { count: 1 }
                                  ),
                                  value: 1,
                                },
                                {
                                  label: t(
                                    "Rental.Infos.ReservationRules.SetUpFields.nbNightAfterReservation",
                                    { count: 2 }
                                  ),
                                  value: 2,
                                },
                              ]}
                              onSelect={onChange}
                            />
                          </div>
                        )}
                      />
                    </>
                  }
                ></SettingElement>
              </div>

              <div className="my-2">
                <Separator />
              </div>

              {/* -- RENTAL RULES -- */}
              {/* <p className="mb-2 font-bold text-low-contrast">
                {t("Rental.Infos.ReservationRules.rentalRules")}
              </p> */}

              <div className="flex flex-col space-y-2">
                {/* SMOKING RENTAL */}
                {/* <SettingElement
                  Icon={SmokingIcon}
                  title={t("Rental.Infos.ReservationRules.smokingRentalTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.smokingRentalDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.smokingRentalTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("smoking_allowed")}
                      onClick={() =>
                        setValue(
                          "smoking_allowed",
                          !getValues("smoking_allowed")
                        )
                      }
                    />
                  </div>
                </SettingElement> */}

                {/* BABIES ALLOWED */}
                {/* <SettingElement
                  Icon={PersonIcon}
                  title={t("Rental.Infos.ReservationRules.babiesAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.babiesAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.babiesAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("infants_allowed")}
                      onClick={() =>
                        setValue(
                          "infants_allowed",
                          !getValues("infants_allowed")
                        )
                      }
                    />
                  </div>
                </SettingElement> */}

                {/* CHILDREN ALLOWED */}
                {/* <SettingElement
                  Icon={MaleIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.childrenAllowedTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.childrenAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.childrenAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("children_allowed")}
                      onClick={() =>
                        setValue(
                          "children_allowed",
                          !getValues("children_allowed")
                        )
                      }
                    />
                  </div>
                </SettingElement> */}

                {/* ANIMALS ALLOWED */}
                {/* <SettingElement
                  Icon={DogIcon}
                  title={t("Rental.Infos.ReservationRules.animalsAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.animalsAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.animalsAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("pets_allowed")}
                      onClick={() =>
                        setValue("pets_allowed", !getValues("pets_allowed"))
                      }
                    />
                  </div>
                </SettingElement> */}

                {/* EVENTS ALLOWED */}
                {/* <SettingElement
                  Icon={CakeIcon}
                  title={t("Rental.Infos.ReservationRules.eventsAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.eventsAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.eventsAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("events_allowed")}
                      onClick={() =>
                        setValue("events_allowed", !getValues("events_allowed"))
                      }
                    />
                  </div>
                </SettingElement> */}

                {/* OTHER CONDITIONS */}
                {/* <SettingElement
                  Icon={TextAlignLeftIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.otherConditionsTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.otherConditionsDescription"
                  )}
                  secondChildren={
                    <>
                      {getValues("additional_rules") !== null ? (
                        <TextInput
                          disabled={validationLoading}
                          placeholder={t(
                            "Rental.Infos.ReservationRules.otherCondtionsPlaceholder"
                          )}
                          register={register("additional_rules", {
                            required: {
                              value: false,
                              message: t("Global.Errors.requiredField", {
                                fieldName: t(
                                  "Rental.Infos.ReservationRules.otherConditionsTitle"
                                ),
                              }),
                            },
                          })}
                          value={getValues("additional_rules") ?? ""}
                          error={errors.additional_rules?.message}
                        />
                      ) : null}
                    </>
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={getValues("additional_rules") !== null}
                      onClick={() => {
                        if (getValues("additional_rules") !== null) {
                          setValue("additional_rules", null);
                        } else {
                          setValue(
                            "additional_rules",
                            rentalReservationRules?.additional_rules ?? ""
                          );
                        }
                      }}
                    />
                  </div>
                </SettingElement> */}
              </div>
            </>
          ) : (
            <>
              {/* -- RESERVATION RULES -- */}
              <p className="mb-2 font-bold text-low-contrast">
                {t("Rental.Infos.ReservationRules.reservationRules")}
              </p>

              <div className="flex flex-col space-y-2">
                {/* ADVANCE RESERVATION NOTICE */}
                <SettingElement
                  Icon={CalendarTimerIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.advanceReservationNoticeTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.advanceReservationNoticeDescription"
                  )}
                >
                  <div className="flex items-center space-x-10">
                    <div className="flex space-x-2">
                      {getValues("reservation_daytime_type") === "day" ? (
                        <p className="font-bold">
                          {t("Rental.Infos.ReservationRules.dayBeforeLabel", {
                            count:
                              rentalReservationRules?.min_days_prior_reservations ??
                              0,
                          })}
                        </p>
                      ) : (
                        <p className="font-bold">
                          {t("Rental.Infos.ReservationRules.dayBeforeLabel_0", {
                            time:
                              rentalReservationRules?.reservation_daytime_limit ??
                              0,
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                </SettingElement>

                {/* WINDOW RESERVATION */}
                <SettingElement
                  Icon={FastForwardIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.windowReservationTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.windowReservationDescription"
                  )}
                >
                  <div className="flex items-center space-x-10">
                    <div className="flex space-x-2">
                      <p className="font-bold">{getWindowReservationLabel()}</p>
                    </div>
                  </div>
                </SettingElement>

                {/* SET UP */}
                <SettingElement
                  Icon={EggTimerIcon}
                  title={t("Rental.Infos.ReservationRules.setUpTimeTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.setUpTimeDescription"
                  )}
                >
                  <div className="flex items-center space-x-10">
                    <div className="flex space-x-2">
                      <p className="font-bold">{getSetUpLabel()}</p>
                    </div>
                  </div>
                </SettingElement>
              </div>

              {/* <div className="my-2">
                <Separator />
              </div> */}

              {/* -- RENTAL RULES -- */}
              {/* <p className="mb-2 font-bold text-low-contrast">
                {t("Rental.Infos.ReservationRules.rentalRules")}
              </p> */}

              <div className="flex flex-col space-y-2">
                {/* SMOKING RENTAL */}
                {/* <SettingElement
                  Icon={SmokingIcon}
                  title={t("Rental.Infos.ReservationRules.smokingRentalTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.smokingRentalDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.smokingRentalTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={Boolean(rentalReservationRules?.smoking_allowed)}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}

                {/* BABIES ALLOWED */}
                {/* <SettingElement
                  Icon={PersonIcon}
                  title={t("Rental.Infos.ReservationRules.babiesAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.babiesAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.babiesAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={Boolean(rentalReservationRules?.infants_allowed)}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}

                {/* CHILDREN ALLOWED */}
                {/* <SettingElement
                  Icon={MaleIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.childrenAllowedTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.childrenAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.childrenAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={Boolean(rentalReservationRules?.children_allowed)}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}

                {/* ANIMALS ALLOWED */}
                {/* <SettingElement
                  Icon={DogIcon}
                  title={t("Rental.Infos.ReservationRules.animalsAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.animalsAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.animalsAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={Boolean(rentalReservationRules?.pets_allowed)}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}

                {/* EVENTS ALLOWED */}
                {/* <SettingElement
                  Icon={CakeIcon}
                  title={t("Rental.Infos.ReservationRules.eventsAllowedTitle")}
                  description={t(
                    "Rental.Infos.ReservationRules.eventsAllowedDescription"
                  )}
                  labelTooltip={
                    <Tooltip
                      value={t(
                        "Rental.Infos.ReservationRules.eventsAllowedTooltip"
                      )}
                    />
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={Boolean(rentalReservationRules?.events_allowed)}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}

                {/* OTHER CONDITIONS */}
                {/* <SettingElement
                  Icon={TextAlignLeftIcon}
                  title={t(
                    "Rental.Infos.ReservationRules.otherConditionsTitle"
                  )}
                  description={t(
                    "Rental.Infos.ReservationRules.otherConditionsDescription"
                  )}
                  secondChildren={
                    <>
                      {getValues("additional_rules") ? (
                        <TextInput
                          disabled={true}
                          placeholder={t(
                            "Rental.Infos.ReservationRules.otherCondtionsPlaceholder"
                          )}
                          value={getValues("additional_rules") ?? ""}
                        />
                      ) : null}
                    </>
                  }
                >
                  <div className="flex items-center space-x-10">
                    <Switch
                      value={rentalReservationRules?.additional_rules !== null}
                      disabled={true}
                    />
                  </div>
                </SettingElement> */}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
