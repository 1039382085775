import { ChecklistUpsertForm } from "../../components/Checklist/Checklist.type";
import paths from "../../constants/paths";
import { del, post } from "../../helpers/APIHelper";
import { isImageFile, isVideoFile } from "../../helpers/fileHelper";
import { ValueType } from "../../types/commonTypes";
import { RentalChecklistsResponse } from "../../types/GETTypes";

const formatRemunerationType = (values: ChecklistUpsertForm) => {
  return values.type === "cleaning" ? values.remuneration_type : "fixed";
};

const formatAmountByBed = (values: ChecklistUpsertForm) => {
  return values.type === "cleaning" && values.remuneration_type === "by_bed"
    ? values.amount_by_bed
    : 0;
};

/**
 * Custom hook to manage rental checklists.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {ValueType} params.rentalId - The ID of the rental.
 * @param {Function} params.onSuccess - Callback function to be called on successful operation.
 * @param {Function} params.onError - Callback function to be called on error.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of an operation.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of an operation.
 * @returns {Object} An object containing methods to create, update, remove, and duplicate checklists.
 */
export const useChecklist = ({
  rentalId,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType;
  onSuccess: (checklist?: RentalChecklistsResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Creates a new checklist.
   *
   * @param {ChecklistUpsertForm} values - The form values for the checklist.
   * @param {Blob[]} files - Array of files to be uploaded with the checklist.
   */
  const create = async (values: ChecklistUpsertForm, files: Blob[]) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("useChecklist - create() : rentalId not defined !");

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("type", values.type);
      formData.append("remuneration_type", formatRemunerationType(values));
      formData.append("amount_fixed", values.amount_fixed.toString() ?? "0");
      formData.append("amount_by_bed", formatAmountByBed(values).toFixed(2));
      formData.append("sections", JSON.stringify(values.sections));

      const photos = files.filter((file) => isImageFile(file.type));
      const videos = files.filter((file) => isVideoFile(file.type));

      photos.forEach((photo, i) => {
        formData.append(`photos[${i}]`, photo);
      });

      videos.forEach((video, i) => {
        formData.append(`videos[${i}]`, video);
      });

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.RENTAL_CHECKLIST
      }${rentalId}/create`;
      const res = await post(URL, formData);

      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Updates an existing checklist.
   *
   * @param {ValueType} checklistId - The ID of the checklist to be updated.
   * @param {ChecklistUpsertForm} values - The form values for the checklist.
   * @param {Blob[]} files - Array of files to be uploaded with the checklist.
   * @param {ValueType[]} photosRemovedIds - Array of photo IDs to be removed.
   * @param {ValueType[]} videosRemoveIds - Array of video IDs to be removed.
   */
  const update = async (
    checklistId: ValueType,
    values: ChecklistUpsertForm,
    files: Blob[],
    photosRemovedIds: ValueType[],
    videosRemoveIds: ValueType[]
  ) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("useChecklist - update() : rentalId not defined !");

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("type", values.type);
      formData.append("remuneration_type", formatRemunerationType(values));
      formData.append("amount_fixed", values.amount_fixed.toString() ?? "0");
      formData.append("amount_by_bed", formatAmountByBed(values).toFixed(2));
      formData.append("sections", JSON.stringify(values.sections));

      const photos = files.filter((file) => isImageFile(file.type));
      const videos = files.filter((file) => isVideoFile(file.type));

      photos.forEach((photo, i) => {
        formData.append(`photos[${i}]`, photo);
      });

      videos.forEach((video, i) => {
        formData.append(`videos[${i}]`, video);
      });

      photosRemovedIds.forEach((id, i) => {
        formData.append(`del_photo_ids[${i}]`, id.toString());
      });

      videosRemoveIds.forEach((id, i) => {
        formData.append(`del_videos_ids[${i}]`, id.toString());
      });

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.RENTAL_CHECKLIST
      }${rentalId}/${checklistId}/update`;
      const res = await post(URL, formData);

      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Removes a checklist by its ID.
   *
   * @param {ValueType} checklistId - The ID of the checklist to be removed.
   */
  const remove = async (checklistId: ValueType) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("useChecklist : rentalId not defined !");

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.RENTAL_CHECKLIST
      }${rentalId}/${checklistId}/delete`;
      const res = await del(URL, {
        method: "DELETE",
      });
      if (res?.data?.success) {
        onSuccess();
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Duplicates a given checklist.
   *
   * @param {RentalChecklistsResponse} checklist - The checklist to be duplicated.
   */
  const duplicate = async (checklist: RentalChecklistsResponse) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("useChecklist - duplicate() : rentalId not defined !");

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.RENTAL_CHECKLIST
      }${rentalId}/create`;

      const res = await post(URL, {
        ...checklist,
        sections: JSON.stringify(checklist.sections),
        photos: undefined,
        photos_url: checklist.photos?.map((p) => p.original_url),
      });
      if (res?.data?.success) {
        onSuccess(res?.data?.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  return { create, update, remove, duplicate };
};
