import React from "react";
import { NoBookingBookingListProps } from "./NoBookingBookingList.type";
import UsersIcon from "../../../assets/icons/users.svg?react";
import PlusCircleIcon from "../../../assets/icons/plus-circle.svg?react";
import PlugIcon from "../../../assets/icons/plug-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";

export const NoBookingBookingList: React.FC<NoBookingBookingListProps> = ({
  onAdd = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
      style={{ height: "calc(100vh - 200px)" }}
    >
      <div className="flex flex-col items-center">
        <UsersIcon width={56} height={56} />

        <p className="my-4 font-semibold text-xxl text-high-contrast">
          {t("BookingList.noBookingTitle")}
        </p>

        <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
          {t("BookingList.noBookingSubTitle")}
        </p>

        <div className="flex mt-4 gap-4">
          <div className="w-72">
            <Button type="secondary" RightIcon={PlusCircleIcon} onClick={onAdd}>
              {t("BookingList.addBooking")}
            </Button>
          </div>

          <div className="w-72">
            <Button
              RightIcon={PlugIcon}
              disabled={true}
              onClick={() => console.log("Connect platform")}
            >
              {t("BookingList.connectPlatform")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
