import React, { useEffect, useState } from "react";
import { AddRentalRoomType } from "../AddRental.type";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "../../Common/TextInput/TextInput";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { Switch } from "../../Common/Switch/Switch";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { Separator } from "../../Common/Separator/Separator";
import { Button } from "../../Common/Button/Button";
import CheckWHiteIcon from "../../../assets/icons/check-white.svg?react";
import TrashWhiteIcon from "../../../assets/icons/trash-white.svg?react";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { ValueType } from "../../../types/commonTypes";

export const UpsertRoomModal: React.FC<{
  isFirstRoom: boolean;
  room?: AddRentalRoomType;
  isVisible: boolean;
  onAdd?: (room: AddRentalRoomType) => void;
  onEdit?: (room: AddRentalRoomType) => void;
  onClose?: () => void;
  onRemove?: () => void;
  nbRooms?: number;
}> = ({
  isFirstRoom,
  room,
  isVisible,
  onAdd = () => {},
  onEdit = () => {},
  onClose = () => {},
  onRemove = () => {},
  nbRooms
}) => {
  const { t } = useTranslation();

  const [errorBeds, setErrorBeds] = useState<string | null>(null);

  const form = useForm<AddRentalRoomType>({
    defaultValues: {
      name: "",
      nbAdults: 2,
      nbChildren: 2,
      nbChildrenBeds: 0,
      nbDoubleBeds: 0,
      nbInfantsBeds: 0,
      nbKingBeds: 0,
      nbMezzanineBeds: 0,
      nbQueenBeds: 0,
      nbSingleBeds: 0,
      nbSofaBeds: 0,
      nbWallBeds: 0,
      privateBathroom: 0,
      privateRoom: true,
      roomType: "BED_ROOM",
    },
  });

  useEffect(() => {
    if (room) {
      form.setValue("name", room.name);
      form.setValue("nbAdults", room.nbAdults);
      form.setValue("nbChildren", room.nbChildren);
      form.setValue("nbChildrenBeds", room.nbChildrenBeds);
      form.setValue("nbDoubleBeds", room.nbDoubleBeds);
      form.setValue("nbInfantsBeds", room.nbInfantsBeds);
      form.setValue("nbKingBeds", room.nbKingBeds);
      form.setValue("nbMezzanineBeds", room.nbMezzanineBeds);
      form.setValue("nbQueenBeds", room.nbQueenBeds);
      form.setValue("nbSingleBeds", room.nbSingleBeds);
      form.setValue("nbSofaBeds", room.nbSofaBeds);
      form.setValue("nbWallBeds", room.nbWallBeds);
      form.setValue("privateBathroom", room.privateBathroom);
      form.setValue("privateRoom", room.privateRoom);
      form.setValue("roomType", room.roomType);
    } else {
      form.setValue("name", t("AddRental.Rooms.namePlaceholder")+ " "+ nbRooms);
      form.setValue("nbAdults", 2);
      form.setValue("nbChildren", 0);
      form.setValue("nbChildrenBeds", 0);
      form.setValue("nbDoubleBeds", 1);
      form.setValue("nbInfantsBeds", 0);
      form.setValue("nbKingBeds", 0);
      form.setValue("nbMezzanineBeds", 0);
      form.setValue("nbQueenBeds", 0);
      form.setValue("nbSingleBeds", 0);
      form.setValue("nbSofaBeds", 0);
      form.setValue("nbWallBeds", 0);
      form.setValue("privateBathroom", 0);
      form.setValue("privateRoom", true);
      form.setValue("roomType", "BED_ROOM");
    }
  }, [room, isVisible]);

  const getTitle = () => {
    if (room) return t("AddRental.Rooms.editTitle");
    else return t("AddRental.Rooms.addTitle");
  };

  const handleSave = (values: AddRentalRoomType) => {
    setErrorBeds(null);

    let isError = false;
    if (isFirstRoom && values.nbAdults < 1) {
      form.setError("nbAdults", {
        message: t("AddRental.Rooms.nbAdultsMinAdultError").toString(),
      });
      isError = true;
    } else if (values.nbAdults + values.nbChildren === 0) {
      form.setError("nbAdults", {
        message: t("AddRental.Rooms.nbAdultsMinError").toString(),
      });
      isError = true;
    }

    if (
      values.nbChildrenBeds +
        values.nbDoubleBeds +
        values.nbInfantsBeds +
        values.nbKingBeds +
        values.nbMezzanineBeds +
        values.nbQueenBeds +
        values.nbSingleBeds +
        values.nbSofaBeds +
        values.nbWallBeds ===
      0
    ) {
      setErrorBeds(t("AddRental.Rooms.bedsMinError"));
      isError = true;
    }

    if (isError) return;

    if (room) onEdit(values);
    else onAdd(values);
  };

  return (
    <RightModal isVisible={isVisible} onClose={onClose} title={getTitle()}>
      <form onSubmit={form.handleSubmit(handleSave)} className="w-full">
        <div className="flex flex-col w-full h-full">
          {/* Fields */}
          <div className="flex flex-1 flex-col gap-3 w-full overflow-y-scroll">
            <p className="font-semibold text-low-contrast">
              {t("AddRental.Rooms.informations")}
            </p>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("AddRental.Rooms.nameError"),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={t("AddRental.Rooms.name")}
                    placeholder={t("AddRental.Rooms.namePlaceholder")+ " "+ nbRooms}
                    value={value}
                    onChangeText={onChange}
                    error={form.formState.errors.name?.message}
                  />
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="roomType"
                render={({ field: { value, onChange } }) => {
                  const handleChange = (value: ValueType) => {
                    onChange(value);

                    if (value === "LIVING_ROOM")
                      form.setValue("privateRoom", false);
                    else form.setValue("privateRoom", true);
                  };

                  return (
                    <div className="flex-1">
                      <InputSelect
                        label={t("AddRental.Rooms.roomType")}
                        items={[
                          { value: "BED_ROOM", label: "Chambre" },
                          { value: "LIVING_ROOM", label: "Salon" },
                        ]}
                        selectedValue={value}
                        onSelect={handleChange}
                        error={form.formState.errors.roomType?.message}
                      />
                    </div>
                  );
                }}
              />

              <Controller
                control={form.control}
                name="privateBathroom"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      label={t("AddRental.Rooms.privateBathroom")}
                      items={[
                        { value: 1, label: t("Global.yes") },
                        { value: 0, label: t("Global.no") },
                      ]}
                      selectedValue={value}
                      onSelect={onChange}
                      error={form.formState.errors.privateBathroom?.message}
                    />
                  </div>
                )}
              />
            </div>

            {form.watch("roomType") !== "LIVING_ROOM" && (
              <Controller
                control={form.control}
                name="privateRoom"
                render={({ field: { value, onChange } }) => (
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => onChange(!value)}
                  >
                    <div className="flex flex-col">
                      <p className="font-semibold text-high-contrast">
                        {t("AddRental.Rooms.privateRoom")}
                      </p>
                      <p className="font-light text-low-contrast">
                        {t("AddRental.Rooms.privateRoomDesc")}
                      </p>
                    </div>
                    <Switch value={!value} />
                  </div>
                )}
              />
            )}

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbAdults"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbAdults")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbAdults?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="nbChildren"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbChildren")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbChildren?.message}
                    />
                  </div>
                )}
              />
            </div>

            <Separator accent="dark" />

            <p className="font-semibold text-low-contrast">
              {t("AddRental.Rooms.bedsInRoom")}
            </p>

            <ErrorMessage errorType="FORM">{errorBeds}</ErrorMessage>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbDoubleBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbDoubleBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbDoubleBeds?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="nbSingleBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbSingleBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbSingleBeds?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbQueenBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbQueenBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbQueenBeds?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="nbKingBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbKingBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbKingBeds?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbInfantsBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbInfantsBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbInfantsBeds?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="nbChildrenBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbChildrenBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbChildrenBeds?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbMezzanineBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbMezzanineBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbMezzanineBeds?.message}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="nbSofaBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbSofaBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbSofaBeds?.message}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex gap-3">
              <Controller
                control={form.control}
                name="nbWallBeds"
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <NumberInput
                      label={t("AddRental.Rooms.nbWallBeds")}
                      value={value}
                      onChangeText={onChange}
                      min={0}
                      error={form.formState.errors.nbWallBeds?.message}
                    />
                  </div>
                )}
              />

              <div className="flex-1" />
            </div>
          </div>

          {/* Buttons on add mode*/}
          {!room && (
            <div className="flex gap-3 border-t-1 border-element-border pt-4 mt-4">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>

              <Button RightIcon={CheckWHiteIcon}>{t("Global.add")}</Button>
            </div>
          )}

          {/* Buttons on edit mode*/}
          {room && (
            <div className="flex gap-3 border-t-1 border-element-border pt-4 mt-4">
              <Button type="secondary" onClick={onClose}>
                {t("Global.cancel")}
              </Button>

              <Button
                RightIcon={TrashWhiteIcon}
                type="destructive"
                onClick={onRemove}
              >
                {t("Global.remove")}
              </Button>

              <Button RightIcon={CheckWHiteIcon}>{t("Global.edit")}</Button>
            </div>
          )}
        </div>
      </form>
    </RightModal>
  );
};
