import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import { ProgressionBar } from "../ProgressionBar/ProgressionBar";
import { Separator } from "../Separator/Separator";
import { SidebarProps } from "./Sidebar.type";
import { SidebarItem } from "./SidebarItem";
import { SidebarSubItem } from "./SidebarSubItem";

import DashboardActiveIcon from "../../../assets/icons/dashboard-active.svg?react";
import DashboardIcon from "../../../assets/icons/dashboard.svg?react";

import CalendarActiveIcon from "../../../assets/icons/calendar-active.svg?react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import SuperhoteIcon from "../../../assets/icons/superhote.svg?react";

import UserCheckActiveIcon from "../../../assets/icons/user-check-active.svg?react";
import UserCheckIcon from "../../../assets/icons/user-check.svg?react";

import HomeActiveIcon from "../../../assets/icons/home-active.svg?react";
import HomeIcon from "../../../assets/icons/home.svg?react";

import MoneyActiveIcon from "../../../assets/icons/money-active.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";

import MailActiveIcon from "../../../assets/icons/mail-active.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";

import ClipboardActiveIcon from "../../../assets/icons/clipboard-active.svg?react";
import ClipboardIcon from "../../../assets/icons/clipboard.svg?react";

import PieChartActiveIcon from "../../../assets/icons/pie-chart-active.svg?react";
import PieChartIcon from "../../../assets/icons/pie-chart.svg?react";

import PlugActiveIcon from "../../../assets/icons/plug-active.svg?react";
import PlugIcon from "../../../assets/icons/plug.svg?react";

import UsersActiveIcon from "../../../assets/icons/users-active.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";

import SettingsActiveIcon from "../../../assets/icons/settings-active.svg?react";
import SettingsIcon from "../../../assets/icons/settings.svg?react";

import HelpActiveIcon from "../../../assets/icons/help-active.svg?react";
import HelpIcon from "../../../assets/icons/help.svg?react";

import ProgressIconActive from "../../../assets/icons/star-active.svg?react";
import ProgressIcon from "../../../assets/icons/star.svg?react";

import LogOutActiveIcon from "../../../assets/icons/log-out-active.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import RobotActive from "../../../assets/icons/robot-active.svg?react";
import Robot from "../../../assets/icons/robot.svg?react";

import SidebarCloseIcon from "../../../assets/icons/sidebar-close.svg?react";

import SidebarOpenIcon from "../../../assets/icons/sidebar-open.svg?react";

export const Sidebar: React.FC<SidebarProps> = ({
  activeItem = "dashboard",
  activeSubItem,
  isClosed = false,
  user,
  onToggle,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToggle = () => {
    if (typeof onToggle === "function") {
      onToggle(!isClosed);
    }
  };

  const getProgression = () => {
    if (typeof user?.progression === "number") {
      return `(${user.progression}%) ${user.progression === 100 ? "👍🏻": ""}`;
    } else return "";
  };

  const getBorderProgression = () => {
    return activeItem === "progression"
      ? "border-2  border-[#e54d2e]  bg-[#ffe6e2]"
      : "";
  };

  return (
    <>
      <div
        className={`${
          isClosed ? "w-[64px] px-2" : "w-[256px] p-6"
        } h-full overflow-y-scroll bg-subtle border-r-1 border-border-subtle`}
      >
        {isClosed ? (
          <div className="flex flex-col items-center justify-center space-y-3 ">
            <div
              className="flex items-center justify-center w-12 p-2 mt-6 bg-element-background rounded-4px hover:cursor-pointer"
              onClick={handleToggle}
            >
              <SidebarOpenIcon className="w-5 h-5 shrink-0" />
            </div>
            {user ? (
              <div
                className="rounded-full cursor-pointer w-7 h-7 border-1 border-element-border bg-slate-200"
                onClick={() => navigate("/settings")}
              >
                <img src={user?.photo!} className="rounded-full w-7 h-7 " />

              </div>
            ) : (
              <div className="rounded-full w-7 h-7 bg-slate-200"></div>
            )}

            <a
              href={paths.PROGRESSION}
              className="flex flex-col p-2 border-2 rounded-lg hover:bg-element-background border-element-border"
            >
              <p className="flex flex-wrap flex-1 pl-1 text-sm font-light text-low-contrast">
                {" "}
                {user?.progression} % {user?.progression === 100 && "👍🏻"}
              </p>
            </a>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-between pb-4">
              <SuperhoteIcon className="w-[55%]" />
              <div
                className="flex items-center justify-center w-12 p-2 bg-element-background rounded-4px hover:cursor-pointer"
                onClick={handleToggle}
              >
                <SidebarCloseIcon className="w-5 h-5 shrink-0" />
              </div>
            </div>

            <div
              className={`flex flex-row space-x-2 items-center ${
                !user && "animate-pulse"
              } cursor-pointer`}
              onClick={() => navigate("/settings")}
            >
              {user ? (
                <div className="w-8 h-8 rounded-full border-1 border-element-border bg-slate-200">
                  <img src={user?.photo!} className="w-8 h-8 rounded-full" />
                </div>
              ) : (
                <div className="w-8 h-8 rounded-full bg-slate-200"></div>
              )}

              <div className="flex-1">
                {user ? (
                  <p className="text-sm font-semibold text-high-contrast">
                    {user?.first_name} {user?.last_name}
                  </p>
                ) : (
                  <p className="w-full h-2 mt-1 text-sm font-semibold rounded text-high-contrast bg-slate-300"></p>
                )}
              </div>
            </div>

            <a
              className={`${getBorderProgression()} flex flex-col gap-1 mt-4 hover:bg-element-background active:bg-element-background-active cursor-pointer rounded-lg`}
              href={paths.PROGRESSION}
            >
              <div className="m-2 mt-3">
                <ProgressionBar progression={user?.progression ?? null} />
                <SidebarItem
                  Icon={ProgressIcon}
                  IconActive={ProgressIconActive}
                  label={`${t("Menu.progression")} ${getProgression()}`}
                  url={paths.PROGRESSION}
                  isActive={activeItem === "progression"}
                  isClosed={isClosed}
                  displayIcon={false}
                />
              </div>
            </a>
          </div>
        )}

        <div className="mt-1 ">
          <Separator />

          <SidebarItem
            Icon={DashboardIcon}
            IconActive={DashboardActiveIcon}
            label={t("Menu.dashboard")}
            url={paths.DASHBOARD}
            isActive={activeItem === "dashboard"}
            isClosed={isClosed}
          />
          <SidebarItem
            Icon={CalendarIcon}
            IconActive={CalendarActiveIcon}
            label={t("Menu.calendars")}
            url={paths.CALENDAR}
            isActive={activeItem === "calendar"}
            isClosed={isClosed}
          />
          <SidebarItem
            Icon={UserCheckIcon}
            IconActive={UserCheckActiveIcon}
            label={t("Menu.bookings")}
            url={paths.RESERVATIONS}
            isActive={activeItem === "bookings"}
            isClosed={isClosed}
          />
          <SidebarItem
            Icon={HomeIcon}
            IconActive={HomeActiveIcon}
            label={t("Menu.rentals")}
            url={paths.RENTALS}
            isActive={activeItem === "rentals"}
            isClosed={isClosed}
          />

          <SidebarItem
            Icon={MoneyIcon}
            IconActive={MoneyActiveIcon}
            label={t("Menu.payments")}
            url={paths.PAYMENTS}
            isActive={activeItem === "payments"}
            isClosed={isClosed}
          />

          {activeItem === "payments" && !isClosed && (
            <div className="pb-4">
              <SidebarSubItem
                label={t("Menu.paymentsReservations")}
                isActive={activeSubItem === "reservations"}
                url={paths.PAYMENTS_RESERVATIONS}
              />

              <SidebarSubItem
                label={t("Menu.paymentsDeposits")}
                isActive={activeSubItem === "deposits"}
                url={paths.PAYMENTS_DEPOSITS}
              />

              <SidebarSubItem
                label={t("Menu.paymentExtras")}
                isActive={activeSubItem === "extras"}
                url={paths.PAYMENTS_EXTRAS}
              />

              <SidebarSubItem
                label={t("Menu.paymentAccounts")}
                isActive={activeSubItem === "accounts"}
                url={paths.PAYMENTS_ACCOUNTS}
              />

              <SidebarSubItem
                label={t("Menu.paymentInvoices")}
                isActive={activeSubItem === "invoices"}
                url={paths.PAYMENTS_INVOICES}
              />

              <SidebarSubItem
                label={t("Menu.paymentActivities")}
                isActive={activeSubItem === "activities"}
                url={paths.PAYMENTS_ACTIVITIES}
              />
            </div>
          )}

          <Separator />

          <SidebarItem
            Icon={MailIcon}
            IconActive={MailActiveIcon}
            label={t("Menu.messages")}
            url={paths.MESSAGES}
            isActive={activeItem === "messages"}
            isClosed={isClosed}
          />
          <SidebarItem
            Icon={Robot}
            IconActive={RobotActive}
            label={t("Menu.autoMessages")}
            url={paths.AUTO_MESSAGES}
            isActive={activeItem === "auto_messages"}
            isClosed={isClosed}
          />

          {activeItem === "auto_messages" && !isClosed && (
            <div className="pb-4">
              <SidebarSubItem
                label={t("Menu.autoMessages")}
                isActive={activeSubItem === "auto_messages"}
                url={paths.AUTO_MESSAGES}
              />

              <SidebarSubItem
                label={t("Menu.shortcode")}
                isActive={activeSubItem === "shortcodes"}
                url={paths.SHORTCODES}
              />
            </div>
          )}

          <SidebarItem
            Icon={ClipboardIcon}
            IconActive={ClipboardActiveIcon}
            label={t("Menu.management")}
            url={paths.MANAGEMENT}
            isActive={activeItem === "management"}
            isClosed={isClosed}
          />

          {activeItem === "management" && !isClosed && (
            <div className="pb-2">
              <SidebarSubItem
                label={t("Menu.tasks")}
                isActive={activeSubItem === "tasks"}
                url={paths.MANAGEMENT_TASKS}
              />

              <SidebarSubItem
                label={t("Menu.teams")}
                isActive={activeSubItem === "teams"}
                url={paths.MANAGEMENT_TEAMS}
              />

              <SidebarSubItem
                label={t("Menu.automations")}
                isActive={activeSubItem === "automations"}
                url={paths.MANAGEMENT_AUTOMATIONS}
              />

              <SidebarSubItem
                label={t("Menu.payments")}
                isActive={activeSubItem === "payments"}
                url={paths.MANAGEMENT_PAYMENTS}
              />

              <SidebarSubItem
                label={t("Menu.activity")}
                isActive={activeSubItem === "activity"}
                url={paths.MANAGEMENT_ACTIVITY}
              />
            </div>
          )}

          <Separator />

          <SidebarItem
            Icon={PieChartIcon}
            IconActive={PieChartActiveIcon}
            label={t("Menu.analytics")}
            url={paths.ANALYTICS}
            isActive={activeItem === "analytics"}
            isClosed={isClosed}
          />

          {/* activeItem === "analytics" && !isClosed && (
            <div className="pb-4">
              <SidebarSubItem
                label={t("Menu.analyticsGeneral")}
                isActive={activeSubItem === "general"}
                url={paths.ANALYTICS}
              />
              <SidebarSubItem
                label={t("Menu.analyticsEvaluations")}
                isActive={activeSubItem === "evaluations"}
                url={paths.ANALYTICS_EVALUATIONS}
              />
              <SidebarSubItem
                label={t("Menu.analyticsSummary")}
                isActive={activeSubItem === "summary"}
                url={paths.ANALYTICS_SUMMARY}
              />
              <SidebarSubItem
                label={t("Menu.analyticsEnv")}
                isActive={false}
                url={paths.ANALYTICS}
              />
              <SidebarSubItem
                label={t("Menu.analyticsMarket")}
                isActive={activeSubItem === "market"}
                url={paths.ANALYTICS}
              />
            </div>
          )*/}

          <SidebarItem
            Icon={PlugIcon}
            IconActive={PlugActiveIcon}
            label={t("Menu.platforms")}
            url={paths.PLATFORMS}
            isActive={activeItem === "platforms"}
            isClosed={isClosed}
          />
          <SidebarItem
            Icon={UsersIcon}
            IconActive={UsersActiveIcon}
            label={t("Menu.guests")}
            url={paths.GUESTS}
            isActive={activeItem === "guests"}
            isClosed={isClosed}
          />

          <Separator />

          <SidebarItem
            Icon={SettingsIcon}
            IconActive={SettingsActiveIcon}
            label={t("Menu.settings")}
            url={paths.SETTINGS}
            isActive={activeItem === "settings"}
            isClosed={isClosed}
          />
          {activeItem === "settings" && !isClosed ? (
            <div className="pb-4">
              <SidebarSubItem
                label={t("Menu.settingsProfil")}
                isActive={activeSubItem === "profil"}
                url={paths.SETTINGS_PROFIL}
              />

              <SidebarSubItem
                label={t("Menu.subscription")}
                isActive={activeSubItem === "subscription"}
                url={paths.YOUR_SUBSCRIPTION}
              />

              <SidebarSubItem
                label={t("Menu.notifications")}
                isActive={activeSubItem === "notifications"}
                url={paths.SETTINGS_NOTIFICATIONS}
              />

              <SidebarSubItem
                label={t("Menu.activities")}
                isActive={activeSubItem === "activities"}
                url={paths.SETTINGS_ACTIVITIES}
              />
            </div>
          ) : null}
          <SidebarItem
            Icon={HelpIcon}
            IconActive={HelpActiveIcon}
            label={t("Menu.help")}
            url={paths.HELP}
            isActive={activeItem === "help"}
            isClosed={isClosed}
          />

          <Separator />

          <SidebarItem
            Icon={LogOutIcon}
            IconActive={LogOutActiveIcon}
            label={t("Menu.logOut")}
            url={paths.LOGOUT}
            isActive={false}
            isClosed={isClosed}
          />
        </div>
      </div>
    </>
  );
};
