import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import PlusWhiteIcon from "../../../assets/icons/plus-white.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";

export type AccountListTableFiltersProps = {
  onAddStripeAccount: () => void;
  onAddIBANAccount: () => void;
  onSearchText: (value: string) => void;
};

export const AccountListTableFilters: React.FC<
  AccountListTableFiltersProps
> = ({ onAddStripeAccount, onAddIBANAccount, onSearchText }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-3">
      <div className="flex-1">
        <TextInput
          size="large"
          Icon={SearchIcon}
          placeholder={t("Global.search")}
          onChangeText={onSearchText}
        />
      </div>

      <div>
        <Button onClick={onAddStripeAccount} RightIcon={PlusWhiteIcon}>
          {t("Payments.addStripe")}
        </Button>
      </div>
    </div>
  );
};
