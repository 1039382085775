import moment from "moment";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { CalendarDay } from "../../pages/CalendarList/CalendarListPage.type";
import { CalendarRentalResponse } from "../../types/GETTypes";

/**
 * Hook to fetch reservation calendar
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useCalendar = async (
  onSuccess: (data: CalendarRentalResponse[] | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.CALENDAR}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result?.rentals);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};


export const useGetRestriction = async (
    data: any,
    onSuccess: (data: CalendarRentalResponse[] | undefined) => void,
    onError: (message: string | undefined) => void,
    onStart?: () => void,
    onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.RESTRICTION}/${data.rental_id}/${data.date}`
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 *
 * @param from
 * @param to
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useFetchDays = async (
  from: Date | null,
  to: Date | null,
  onSuccess: (days: CalendarDay | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (from && to) {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.CALENDAR_LIST}`,
        {
          params: {
            from: from === null ? null : moment(from).format("YYYY-MM-DD"),
            to: to === null ? null : moment(to).format("YYYY-MM-DD"),
          },
        }
      );

      if (response?.data?.success) {
        onSuccess(response?.data?.result?.days);
      } else {
        onError(response?.response?.data?.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};
