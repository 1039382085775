import React, { useCallback, useEffect, useState } from "react";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { useTranslation } from "react-i18next";
import { Carousel } from "../../components/Common/Carousel/Carousel";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";
import { get } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { useNavigate, useParams } from "react-router-dom";
import { RegisterMemberAccessDenied } from "../../components/RegisterMember/RegisterMemberAccessDenied";
import { Button } from "../../components/Common/Button/Button";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";

export const RegisterMemberAcceptedPage: React.FC<{}> = () => {
  const { invitId } = useParams();

  const { t } = useTranslation();

  const [loadingInvit, setLoadingInvit] = useState<boolean>(true);

  const [accepted, setAccepted] = useState<number | undefined>(undefined);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const fetchInvit = useCallback(async () => {
    setLoadingInvit(true);

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.INVIT
      }/${invitId}`
    );

    if (res.data?.success) {
      const {
        first_name,
        last_name,
        accepted: newAccepted,
      } = res.data.result?.invitation;

      setAccepted(newAccepted);
      setFirstName(first_name);
      setLastName(last_name);
    } else {
      setAccepted(0);
    }

    setLoadingInvit(false);
  }, []);

  useEffect(() => {
    fetchInvit();
  }, []);

  if (!loadingInvit && (accepted === undefined || accepted === 1))
    return <RegisterMemberAccessDenied />;

  return (
    <>
      <div className="hidden md:block w-full h-full">
        <FullScreenLayout isCheckAuthEnabled={false}>
          {/* Left */}
          <div className="w-2/5 max-w-md p-2">
            <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
              <div className="flex-1 pb-4">
                <SuperhoteIcon />
              </div>

              <div>
                <Carousel
                  items={[
                    {
                      Image: Carousel1Image,
                      title: t("Register.carousel1Title"),
                      subTitle: t("Register.carousel1SubTitle"),
                    },
                    {
                      Image: Carousel2Image,
                      title: t("Register.carousel2Title"),
                      subTitle: t("Register.carousel2SubTitle"),
                    },
                    {
                      Image: Carousel3Image,
                      title: t("Register.carousel3Title"),
                      subTitle: t("Register.carousel3SubTitle"),
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {/* Right */}
          <Content firstName={firstName} lastName={lastName} />
        </FullScreenLayout>
      </div>

      {/* Mobile view */}
      <div className="flex flex-col md:hidden w-full h-full">
        <div className="px-12 pt-8">
          <SuperhoteIcon />

          <Content firstName={firstName} lastName={lastName} />
        </div>
      </div>
    </>
  );
};

const Content: React.FC<{
  firstName: string | undefined;
  lastName: string | undefined;
}> = ({ firstName, lastName }) => {
  const { invitId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleAccept = async () => {
    setError(null);
    setLoading(true);

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.ACCEPT_INVIT
      }/${invitId}`
    );

    if (res.data?.success) {
      navigate(paths.LOGIN);
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <div className="flex px-12 py-8 justify-center items-center flex-col space-y-5">
      <h1 className="block m-0 py-2 font-bold text-xxl text-center">
        {t("RegisterMember.Accepted.title", { firstName, lastName })}
      </h1>

      <h1 className="block m-0 pb-2 font-extralight text-secondary text-base text-center">
        {t("RegisterMember.Accepted.content", { firstName, lastName })}
      </h1>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex mt-4 gap-4">
        <div className="w-72">
          <Button type="secondary" disabled={loading} onClick={() => {}}>
            {t("RegisterMember.Accepted.refuse")}
          </Button>
        </div>

        <div className="w-72">
          <Button
            onClick={handleAccept}
            loading={loading}
            RightIcon={ArrowRightWhiteIcon}
          >
            {t("RegisterMember.Accepted.accept")}
          </Button>
        </div>
      </div>
    </div>
  );
};
