import React from "react";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../../assets/icons/home.svg?react";
import PlusIcon from "../../../assets/icons/plus-white.svg?react";
import { Button } from "../../Common/Button/Button";

export type NoRentalGroupRentalListProps = {
  onAdd?: () => void;
};

export const NoRentalGroupRentalList: React.FC<
  NoRentalGroupRentalListProps
> = ({ onAdd = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center w-full py-12">
      <div className="flex flex-col items-center">
        <HomeIcon width={56} height={56} />

        <p className="my-4 text-xl font-semibold text-high-contrast">
          {t("RentalList.emptyViewRentalGroupTitle")}
        </p>

        <p className="w-full text-base font-light text-center text-low-contrast">
          {t("RentalList.emptyViewRentalGroupDescription1")}
        </p>

        <p className="mb-4 text-base font-light text-center text-low-contrast w-96">
          {t("RentalList.emptyViewRentalGroupDescription2")}
        </p>

        <div className="flex gap-4 mt-4">
          <Button type="primary" RightIcon={PlusIcon} onClick={onAdd}>
            {t("RentalList.addRentalGroup")}
          </Button>
        </div>
      </div>
    </div>
  );
};
