import React from "react";
import { useTranslation } from "react-i18next";
import { ShortCodeListLineProps } from "./ShortCodeList.type";
import { useState } from "react";

import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import { Button } from "../../Common/Button/Button";

export const ShortCodeGroupListLine: React.FC<ShortCodeListLineProps> = ({
  id,
  name,
  codes,
  onInsert = () => {},
}) => {
  const { t } = useTranslation();

  const [showCode, setShowCode] = useState<boolean>(true);

  return (
    <>
      <tr
        className="bg-element-background/70 cursor-pointer"
        key={`group-${id}`}
        onClick={() => setShowCode(!showCode)}
      >
        <td
          className="text-center font-semibold text-low-contrast py-2 border-b-1 border-element-border/30"
          colSpan={3}
        >
          <p className="flex flex-row flex-1 justify-between items-center px-3 text-[12px]">
            {name} {showCode ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </p>
        </td>
      </tr>

      {showCode &&
        codes?.map((code) => (
          <tr
            key={`code-${code.id}`}
            className="hover:bg-element-background/40 cursor-pointer"
          >
            <td className="p-2 pr-0 text-high-contrast font-semibold w-40">
              {code.trigger}
            </td>
            <td className="p-2 pr-0 text-low-contrast font-light">
              {code.description}
            </td>
            <td className="p-2 w-20">
              <Button
                type="secondary"
                onClick={() => onInsert(code.trigger)}
                size="small"
              >
                {t("AutoMessageUpsert.insert")}
              </Button>
            </td>
          </tr>
        ))}
    </>
  );
};
