import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import {
  capitalizeFirstLetter,
  displayPriceWith2DigitsIfNeeded,
} from "../../helpers/stringHelper";
import { Button } from "../Common/Button/Button";
import { Rating } from "../Common/Rating/Rating";
import { RightModal } from "../Common/RightModal/RightModal";
import { Separator } from "../Common/Separator/Separator";
import { WorkInProgress } from "../WorkInProgress/WorkInProgress";

import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import CheckinIcon from "../../assets/icons/log-in.svg?react";
import MailIcon from "../../assets/icons/mail.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import PhoneIcon from "../../assets/icons/phone.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import { get } from "../../helpers/APIHelper";
import { useEditPrivateNote } from "../../hooks/useEditPrivateNotes";
import { useFetchPaymentSchedule } from "../../pages/PaymentReservationList/PaymentReservationList.hooks";
import { TextAreaInput } from "../Common/TextAreaInput/TextAreaInput";
import {
  CalendarRestrictionResponse,
  PaymentReservationResponse,
  PaymentReservationScheduleListItemResponse,
  ReservationResponse,
} from "../../types/GETTypes";
import { useModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import {
  PaymentReservationDepositStatusEnum,
  ReservationStatusEnum,
} from "../../enums/GETenums";
import { ResaCancelModal } from "./ResaCancelModal";
import {
  fetchOneReservation,
  getDepositStatusBadge,
  getPaymentStatusBadge,
} from "../../helpers/reservationHelper";
import { PaymentDepositDetailsModal } from "../Payment/PaymentDepositList/PaymentDepositDetailsModal";
import { CalendarReservationModalProps } from "./Calendar.type";
import { PaymentReservationModalUseMode } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal.type";
import { PaymentReservationListModal } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal";

export const CalendarReservationModal: React.FC<
  CalendarReservationModalProps
> = ({ isVisible, reservation, onChangeStatusResa, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentReservation, setCurrentReservation] = useState<
    ReservationResponse | undefined
  >();

  const editPrivateNote = useEditPrivateNote(currentReservation);
  const cancelResaModal = useModal<ValueType>();
  const depositModal = useModal();

  const handleNavigateDetails = () => {
    navigate(`${paths.RESERVATIONS}/${currentReservation?.id}`);
  };

  const handleNavigateMessage = () => {
    navigate(`${paths.RESERVATIONS}/${currentReservation?.id}/messages`);
  };

  useEffect(() => {
    if (isVisible && reservation) {
      fetchOneReservation(
        reservation.id.toString(),
        (nextReservation) => {
          setCurrentReservation(nextReservation);
        },
        () => {}
      );
    }
  }, [isVisible, reservation]);

  // Payment modal

  const [loading, setLoading] = useState<boolean>(false);
  const [
    isPaymentReservationModalVisible,
    setIsPaymentReservationModalVisible,
  ] = useState<boolean>(false);
  const [currentPaymentReservation, setCurrentPaymentReservation] = useState<
    PaymentReservationResponse | undefined
  >();
  const [
    currentPaymentReservationDetailId,
    setCurrentPaymentReservationDetailId,
  ] = useState<number | undefined>();
  const [currentPaymentReservationDetail, setCurrentPaymentReservationDetail] =
    useState<PaymentReservationScheduleListItemResponse | undefined>();
  const [paymentReservationModalUseMode, setPaymentReservationModalUseMode] =
    useState<PaymentReservationModalUseMode>("paymentReservation");

  const fetchReservationPayment = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}/${
        reservation?.id
      }`
    );

    if (res.data?.success) {
      setCurrentPaymentReservation(res.data.result);
    }
  };

  useEffect(() => {
    if (reservation) {
      fetchReservationPayment();
    }
  }, [reservation]);

  const handleClickTransactionDetail = (
    paymentReservationId: number,
    paymentReservationDetailId: number
  ) => {
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      setCurrentPaymentReservationDetailId(paymentReservationDetailId);
      setPaymentReservationModalUseMode("paymentReservationDetail");
    }
  };

  const handleSuccessCancelResa = (
    nextStatus: ReservationStatusEnum,
    nextRestrictions: CalendarRestrictionResponse[]
  ) => {
    cancelResaModal.close();
    fetchReservationPayment();

    onChangeStatusResa({
      reservationId: currentReservation?.id,
      rentalId: currentReservation?.rental?.id,
      status: nextStatus,
      restrictions: nextRestrictions,
    });
  };

  const handleClose = () => {
    onClose();
    setCurrentReservation(undefined);
    setCurrentPaymentReservation(undefined);
    setCurrentPaymentReservationDetail(undefined);
    setCurrentPaymentReservationDetailId(undefined);
  };

  const isNoDeposit = () => {
    return (
      currentReservation?.status_deposit ===
        PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM ||
      currentReservation?.status_deposit ===
        PaymentReservationDepositStatusEnum.NOT_REQUIRED
    );
  };

  const formatReservationTypeLabel = `${t(
    "Global.reservation"
  )} ${currentReservation?.platform?.name?.toLowerCase()}`;

  return (
    <>
      <ResaCancelModal
        isVisible={cancelResaModal.isVisible}
        onClose={cancelResaModal.close}
        reservationId={cancelResaModal.data}
        onSuccess={handleSuccessCancelResa}
      />

      <RightModal
        isVisible={isVisible}
        onClose={handleClose}
        title={t("Calendar.bookingOverview")}
      >
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col w-full overflow-auto">
            {/* Global infos */}
            <div className="flex gap-4 justify-between items-center mb-8">
              <div>
                <p className="text-lg font-semibold text-high-contrast">
                  {formatReservationTypeLabel}
                </p>

                <p className="font-light text-low-contrast text-xs">
                  {t("Global.from")}{" "}
                  {moment(currentReservation?.checkin).format("DD MMM YYYY")}{" "}
                  {t("Global.to").toLowerCase()}{" "}
                  {moment(currentReservation?.checkout).format("DD MMM YYYY")}
                </p>

                {currentReservation?.platform.reservation_link && (
                  <p className="font-light text-blue-700 text-xs underline">
                    <a
                      href={currentReservation.platform.reservation_link}
                      target="_blank"
                    >
                      {t("Booking.link")}
                    </a>
                  </p>
                )}
              </div>

              <div>
                <p className="text-xl text-high-contrast font-extrabold text-right">
                  {displayPriceWith2DigitsIfNeeded(
                    currentReservation?.total_guest_price ?? 0
                  )}
                </p>

                <p className="font-light text-low-contrast text-xs">
                  {t("Booking.reservationPrice")}
                </p>
              </div>
            </div>

            {/* Guest and other infos */}
            <div className="flex gap-4">
              <div className="w-12 h-12 shrink-0">
                <img
                  src={currentReservation?.guest?.photo!}
                  className="w-12 h-12 rounded-full shrink-0 bg-slate-200"
                />
              </div>

              <div className="flex justify-between w-full">
                <div className="flex flex-col">
                  <div className="flex gap-2">
                    <p className="text-high-contrast font-semibold">
                      {currentReservation?.guest?.first_name}{" "}
                      {currentReservation?.guest?.last_name}
                    </p>

                    {currentReservation?.guest?.rate && (
                      <>
                        <p className="text-low-contrast font-light">-</p>

                        <div>
                          <Rating
                            loading={false}
                            maxStars={5}
                            value={currentReservation?.guest?.rate}
                            classNames={{ label: "text-low-contrast" }}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <p className="items-center text-low-contrast font-light">
                    <a
                      href={`mailto:${currentReservation?.guest?.email}`}
                      className="flex gap-1"
                    >
                      <MailIcon className="w-4 h-4" />{" "}
                      {currentReservation?.guest?.email}
                    </a>
                  </p>

                  <p className="items-center text-low-contrast font-light">
                    <a
                      href={`tel:${currentReservation?.guest?.phone}`}
                      className="flex gap-1"
                    >
                      <PhoneIcon className="w-4 h-4" />{" "}
                      {currentReservation?.guest?.phone}
                    </a>
                  </p>
                </div>

                <div className="flex flex-col items-end text-right text-low-contrast font-light">
                  <div className="flex gap-2 items-center font-semibold text-high-contrast">
                    {t("Global.night", {
                      count: moment(currentReservation?.checkout).diff(
                        moment(currentReservation?.checkin),
                        "days"
                      ),
                    })}
                    <MoonIcon className="w-5 h-5" />
                  </div>

                  <div className="flex gap-2 items-center">
                    {t("Global.adults", {
                      count: currentReservation?.adults_count,
                    })}
                    <UsersIcon className="w-5 h-5" />
                  </div>

                  {currentReservation?.children_count! > 0 && (
                    <div className="flex gap-2 items-center">
                      {t("Global.children", {
                        count: currentReservation?.children_count,
                      })}
                      <EmojiHappyIcon className="w-5 h-5" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-2 my-4">
              <Button type="secondary" onClick={handleNavigateMessage}>
                {t("Calendar.sendMessage")}
              </Button>

              <Button type="primary" onClick={handleNavigateDetails}>
                {t("Calendar.viewBookingDetails")}
              </Button>
            </div>

            <Separator />

            <div>
              <p className="font-semibold text-low-contrast mb-2">
                {t("Booking.informations")}
              </p>
              <div className="flex gap-2 mt-2 mb-1 items-center">
                <img
                  className="w-12 h-12 rounded-6px bg-slate-200 border-1 border-element-border"
                  src={currentReservation?.rental?.details?.photos?.cover}
                />
                <div>
                  <p className="font-semibold text-high-contrast">
                    {currentReservation?.rental?.details?.informations?.name}
                  </p>
                  <p className="font-light text-low-contrast">
                    {currentReservation?.rental?.details?.informations?.address}{" "}
                    {
                      currentReservation?.rental?.details?.informations
                        ?.postal_code
                    }{" "}
                    {currentReservation?.rental?.details?.informations?.city}{" "}
                    {
                      currentReservation?.rental?.details?.informations?.country
                        .name
                    }
                  </p>
                </div>
              </div>
            </div>

            {/* Checkin checkout */}
            <div className="grid grid-cols-2 mt-2 mb-2">
              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {t("Booking.checkinDate")}
                </p>

                <p className="font-semibold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(currentReservation?.checkin).format(
                      "dddd DD MMM YYYY"
                    )
                  )}
                </p>
              </div>

              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {t("Booking.timeIn")}
                </p>

                <p className="font-semibold text-high-contrast">
                  {currentReservation?.time_in ?? t("Global.NA")}
                </p>
              </div>

              <div className="flex flex-col mt-3">
                <p className="font-light text-low-contrast">
                  {t("Booking.checkoutDate")}
                </p>

                <p className="font-semibold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(currentReservation?.checkout).format(
                      "dddd DD MMM YYYY"
                    )
                  )}
                </p>
              </div>

              <div className="flex flex-col mt-3">
                <p className="font-light text-low-contrast">
                  {t("Booking.timeOut")}
                </p>

                <p className="font-semibold text-high-contrast">
                  {currentReservation?.time_out ?? t("Global.NA")}
                </p>
              </div>
            </div>

            <Separator />

            {/* Payments */}
            <div className="bg-element-background rounded-6px w-full p-2">
              <div className="flex items-center">
                <MoneyIcon className="w-6 h-6" />

                <p className="text-low-contrast font-light">
                  {t("Booking.payments")}
                </p>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="font-light text-low-contrast mt-2">
                    {t("Booking.journey")}
                  </p>

                  <div
                    className="flex gap-2 items-center mt-2 cursor-pointer"
                    onClick={() => setIsPaymentReservationModalVisible(true)}
                  >
                    <div>
                      {getPaymentStatusBadge(
                        currentReservation?.status_payment
                      )}
                    </div>

                    {currentReservation && (
                      <div className="w-12">
                        <Button
                          type="secondary"
                          onClick={() =>
                            setIsPaymentReservationModalVisible(true)
                          }
                          RightIcon={ArrowRightIcon}
                        ></Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <p className="font-light text-low-contrast mt-2">
                    {currentReservation?.status_deposit !== null &&
                      t("Booking.deposit")}
                  </p>

                  <div
                    className={`flex gap-2 items-center mt-2 ${
                      isNoDeposit() ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={isNoDeposit() ? () => {} : depositModal.open}
                  >
                    <div>
                      {getDepositStatusBadge(
                        currentReservation?.status_deposit
                      )}
                    </div>

                    {!isNoDeposit() &&
                      currentReservation?.status_deposit !== null &&
                      currentReservation && (
                        <div className="w-12">
                          <Button
                            type="secondary"
                            RightIcon={ArrowRightIcon}
                          ></Button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* Operational Management */}
            <div className="bg-element-background rounded-6px w-full p-2 mt-4">
              <div className="flex items-center">
                <CheckinIcon className="w-5 h-5 mr-1" />

                <p className="text-low-contrast font-light">
                  {t("Booking.checkinCheckout")}
                </p>
              </div>

              <WorkInProgress />
            </div>

            <Separator />

            {/* Private note */}
            <div className="mt-2 pb-4">
              <p className="font-semibold text-low-contrast mb-2">
                {t("Booking.Note.title")}
              </p>

              <TextAreaInput
                rows={4}
                value={editPrivateNote.privateNotes}
                onTextChange={editPrivateNote.handleChange}
                onSave={editPrivateNote.handleSave}
                onCancel={editPrivateNote.handleCancel}
                error={editPrivateNote.error}
                disabled={editPrivateNote.loading}
              />
            </div>
          </div>

          {(currentReservation?.status_reservation ===
            ReservationStatusEnum.CONFIRMED_COMING ||
            currentReservation?.status_reservation ===
              ReservationStatusEnum.CONFIRMED_PROGRESS) &&
            currentReservation?.platform?.name.toLowerCase() === "direct" && (
              <div className="pt-4 border-t-1 border-element-border">
                <Button
                  type="destructive"
                  onClick={() => cancelResaModal.open(currentReservation?.id)}
                >
                  {t("Calendar.cancelResa")}
                </Button>
              </div>
            )}
        </div>
      </RightModal>

      <PaymentReservationListModal
        loading={false}
        useMode={paymentReservationModalUseMode!}
        isVisible={isPaymentReservationModalVisible}
        reservation={currentReservation}
        paymentReservation={currentPaymentReservation}
        onBack={() => setPaymentReservationModalUseMode("paymentReservation")}
        onClose={() => setIsPaymentReservationModalVisible(false)}
        onTransactionDetailClick={handleClickTransactionDetail}
      />

      <PaymentDepositDetailsModal
        isVisible={depositModal.isVisible}
        paymentReservation={currentPaymentReservation}
        onBack={depositModal.close}
        onClose={() => {}}
      />
    </>
  );
};
