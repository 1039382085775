import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {MultiSelectRentalsModalProps} from "./MultiSelectRentals.type";
import {RightModal} from "../RightModal/RightModal";
import {TextInput} from "../TextInput/TextInput";
import {MultiSelectRentalItem} from "./MultiSelectRentalItem";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {Button} from "../Button/Button";

import SearchIcon from "../../../assets/icons/search.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {ValueType} from "../../../types/commonTypes";
import {MultiSelectRentalItemSkeleton} from "./MultiSelectRentalItemSkeleton";
import {debounce} from "lodash";

export const MultiSelectRentalsModal: React.FC<
  MultiSelectRentalsModalProps
> = ({
  isVisible,
  items,
  allRentals,
  selectedRentals = [],
  loading,
  loadingSearch,
  error,
  onApply,
  onSelect = () => {},
  onClose,
  onSearch,
}) => {
  const {t} = useTranslation();

  const [filter, setFilter] = useState<string>("");

  const handleSelectOne = (value: ValueType) => {
    if (selectedRentals.findIndex((r) => r === value) > -1)
      onSelect(selectedRentals.filter((r) => r !== value));
    else onSelect([...selectedRentals, value]);
  };

  const countRentals = () => {
    return allRentals?.length ?? 0;
  };

  const getFullSelectMode = () => {
    if (selectedRentals?.length === countRentals()) return "full";
    else if (selectedRentals?.length === 0) return "none";
    else return "semi";
  };

  const handleSelectAll = () => {
    if (selectedRentals?.length === countRentals()) onSelect([]);
    else onSelect(allRentals);
  };

  const debouncedSearchRentals = React.useRef(
    debounce(async (text: string) => {
      onSearch(text);
    }, 300)
  ).current;

  const handleChangeText = (text: string) => {
    setFilter(text);
    debouncedSearchRentals(text)
  };

  const isLoading = loading || loadingSearch

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Components.MultiFilterRentals.title")}
    >
      <div className="flex flex-col text-high-contrast font-normal w-full">
        <div className="w-full">
          <TextInput
            label={t("Global.rental")}
            placeholder={`${t("Global.search")} ...`}
            Icon={SearchIcon}
            value={filter}
            disabled={loading}
            onChangeText={(text) => handleChangeText(text)}
          />
        </div>

        {isLoading && (
            <div className="w-full flex-1 overflow-y-scroll mt-4">
              {/* ALL HEADER */}
              <div className="rounded-t-6px bg-element-background border-1 border-element-border">
                <MultiSelectRentalItem
                  key={`group-all`}
                  label={t("Global.allRentalsSelected")}
                  selectMode={"none"}
                  isBold={true}
                  onClick={() => handleSelectAll()}
                />
              </div>

              <div className="border-1 border-element-border border-t-0 rounded-b-6px mb-4">
                {Array.from({length: 5}).map((v, index) => (
                  <MultiSelectRentalItemSkeleton key={index} />
                ))}
              </div>
            </div>
          )}

        {!isLoading && (
            <div className="w-full flex-1 overflow-y-scroll mt-4">
              {/* ALL HEADER */}
              <div className="rounded-t-6px bg-element-background border-1 border-element-border">
                <MultiSelectRentalItem
                  key={`group-all`}
                  label={t("Global.allRentalsSelected")}
                  selectMode={getFullSelectMode()}
                  isBold={true}
                  onClick={() => handleSelectAll()}
                />
              </div>

              <div className="border-1 border-element-border border-t-0 rounded-b-6px mb-4">
                {items?.map((rental) => {
                  const isSelected =
                    selectedRentals?.findIndex((v) => v === rental.id) > -1;

                  return (
                    <MultiSelectRentalItem
                      key={`rental-filter-${rental.id}`}
                      rental={rental}
                      selectMode={isSelected ? "full" : "none"}
                      onClick={() => handleSelectOne(rental.id)}
                    />
                  );
                })}
              </div>
            </div>
          )}

        <ErrorMessage>{error}</ErrorMessage>

        {/* Buttons */}
        <div className="flex gap-3 pt-4 border-t-1 border-element-border">
          <Button
            type="secondary"
            onClick={onClose}
            disabled={loading || loadingSearch}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="secondary"
            onClick={() => onSelect(allRentals)}
            disabled={loading || loadingSearch}
          >
            {t("Global.reinit")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            onClick={onApply}
            disabled={loading || loadingSearch}
          >
            {t("Global.filter")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
