import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingPageProps, Journal } from "./BookingPage.type";

import { Card } from "../../components/Common/Card/Card";
import { HistoricalStep } from "../../components/Common/HistoricalStep/HistoricalStep";
import { TextInput } from "../../components/Common/TextInput/TextInput";

import { useParams } from "react-router-dom";
import BookingIcon from "../../assets/icons/booking.svg?react";
import CheckCircleIcon from "../../assets/icons/check-circle.svg?react";
import DollarIcon from "../../assets/icons/dollar.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import HomeIcon from "../../assets/icons/home.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import RentalThumbnail from "../../assets/images/rental1.webp";
import { CalendarInput } from "../../components/Common/CalendarInput/CalendarInput";
import { InputSelect } from "../../components/Common/InputSelect/InputSelect";
import { TimeInput } from "../../components/Common/TimeInput/TimeInput";
import {
  EditCardProps,
  ViewCardProps,
} from "../../components/Common/ViewAndEditCard/ViewAndEditCard.type";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { ReservationCancelCard } from "../../components/Reservation/Cancel/ReservationCancelCard";
import ReservationExtrasCard from "../../components/Reservation/ExtrasOrder/ReservationExtrasCard";
import { ReservationGuestCard } from "../../components/Reservation/Guest/GuestCard";
import { ReservationLockCard } from "../../components/Reservation/Lock/ReservationLockCard";
import { ReservationNoteCard } from "../../components/Reservation/Note/NoteCard";
import { ReservationNoteForm } from "../../components/Reservation/Note/NoteCard.type";
import { ReservationPlatformNoteCard } from "../../components/Reservation/PlatformNote/PlatformNoteCard";
import { ReservationPlatformNoteForm } from "../../components/Reservation/PlatformNote/PlatformNoteCard.type";
import { ReservationCard } from "../../components/Reservation/ReservationCard";
import { ReservationCardForm } from "../../components/Reservation/ReservationCard.type";
import { WorkInProgress } from "../../components/WorkInProgress/WorkInProgress";
import { ReservationStatusEnum } from "../../enums/GETenums";
import {
  fetchOneReservation,
  fetchReservationJournal,
} from "../../helpers/reservationHelper";
import { GuestPageExtraOrder, ReservationResponse } from "../../types/GETTypes";

export const BookingPage: React.FC<BookingPageProps> = () => {
  const { reservationId } = useParams();

  const [reservation, setReservation] = useState<
    ReservationResponse | undefined
  >(undefined);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [journals, setJournals] = useState<Journal[]>([]);
  const [loadingJournal, setLoadingJournal] = useState<boolean>(true);
  const [errorJournal, setErrorJournal] = useState<string | null>(null);

  const handleReservationSuccess = (value: ReservationResponse) => {
    console.log("handleReservationSuccess", value);
    setReservation(value);
    setLoading(false);
  };

  const handleReservationError = (value: string) => {
    setError(value);
    setLoading(false);
  };

  const handleJournalSuccess = (value: any[]) => {
    console.log("handleJournalSuccess", value);
    let nextJournals: any[] = [];

    if(value){
      Object.values(value).forEach((d) => {
        nextJournals = [...nextJournals, ...d];
      });
  
      setJournals(nextJournals);
      setLoadingJournal(false);
    }

  };

  const handleJournalError = (value: string) => {
    setLoadingJournal(false);
    setErrorJournal(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(
        reservationId,
        handleReservationSuccess,
        handleReservationError
      );

      fetchReservationJournal(
        reservationId,
        handleJournalSuccess,
        handleJournalError
      );
    }
  }, [reservationId]);

  const handleUpdateReservation = (newData: ReservationCardForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdateExtras = (newData: GuestPageExtraOrder[]) => {
    if (reservation) {
      setLoading(true);
      fetchOneReservation(
        String(reservation.id),
        handleReservationSuccess,
        handleReservationError
      );

      fetchReservationJournal(
        String(reservation.id),
        handleJournalSuccess,
        handleJournalError
      );
    }
  };

  const handleUpdateNote = (newData: ReservationNoteForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdatePlatformNote = (newData: ReservationPlatformNoteForm) => {
    if (reservation) {
      setReservation({
        ...reservation,
        ...newData,
      });
    }
  };

  const handleUpdateStatus = (nextStatus: ReservationStatusEnum) => {
    if (reservation) {
      setReservation({ ...reservation, status_reservation: nextStatus });
    }
  };

  return (
    <>
      <BookingLayout
        navbarActiveItem="details"
        reservation={reservation}
        error={error}
      >
        <div className="flex flex-row gap-3">
          <div className="flex flex-col flex-1 gap-3">
            <ReservationCard
              loading={loading}
              setLoading={setLoading}
              reservation={reservation}
              onUpdate={handleUpdateReservation}
            />

            <ReservationCancelCard
              loading={loading}
              reservation={reservation}
              onUpdate={handleUpdateStatus}
            />
          </div>
          <div className="flex flex-col flex-1 gap-3">
            <ReservationGuestCard loading={loading} reservation={reservation} />
            {/* <ReservationGuestPageCard /> */}
            <ReservationPlatformNoteCard
              loading={loading}
              reservation={reservation}
              onUpdate={handleUpdatePlatformNote}
            />
            <ReservationNoteCard
              loading={loading}
              reservation={reservation}
              onUpdate={handleUpdateNote}
            />
            <ReservationLockCard reservation={reservation} />
            <ReservationExtrasCard
              loading={loading}
              reservation={reservation}
              onUpdate={handleUpdateExtras}
            />
          </div>
        </div>
      </BookingLayout>
    </>
  );
};

const GuestViewerCard: React.FC<ViewCardProps> = ({
  data,
  onEdit = () => {},
}) => {
  const { t } = useTranslation();
  const reservation: ReservationResponse = data;

  return (
    <Card
      Icon={UsersIcon}
      label={t("Booking.guest")}
      button={{ Icon: EditIcon, label: t("Booking.edit"), onClick: onEdit }}
    >
      <div className="flex flex-row items-center pb-2 space-x-2 border-b-1 border-element-border">
        <img
          src={reservation?.guest?.photo!}
          className="object-cover w-10 h-10 rounded-full"
        />

        <div>
          <p className="font-semibold text-high-contrast">
            {reservation?.guest?.first_name} {reservation?.guest?.last_name}
          </p>
          <p className="font-light text-low-contrast">
            {reservation?.guest?.address}, <br />
            {reservation?.guest?.postal_code} {reservation?.guest?.city} -{" "}
            {reservation?.guest?.country}
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="font-light text-low-contrast">{t("Booking.email")}</p>
          <p className="font-semibold text-high-contrast">
            {reservation?.guest?.email}
          </p>
        </div>

        <div className="flex-1">
          <p className="font-light text-low-contrast">{t("Booking.phone")}</p>
          <p className="font-semibold text-high-contrast">
            {reservation?.guest?.phone}
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center px-2 pt-2 space-x-2">
        <div className="flex-1">
          <p className="font-light text-low-contrast">
            {t("Booking.language")}
          </p>
          <p className="font-semibold text-high-contrast">
            {/* TODO: Check that the language is displayed correctly */}
            {reservation?.guest?.locale?.toUpperCase()}
          </p>
        </div>

        <div className="flex-1">
          <p className="font-light text-low-contrast">{t("Booking.rate")}</p>
          <p className="font-semibold text-high-contrast">???/5</p>
        </div>
      </div>
    </Card>
  );
};

/////////////////

const BookingEditorCard: React.FC<EditCardProps> = ({ onSave = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={HomeIcon}
      label={t("Booking.booking")}
      button={{
        Icon: CheckCircleIcon,
        label: t("Booking.save"),
        onClick: onSave,
      }}
    >
      <div className="flex flex-row items-center pb-2 space-x-3 border-b-1 border-element-border">
        <div className="relative">
          <BookingIcon
            className="absolute z-10 border-2 border-white rounded-full"
            width={24}
            height={24}
            style={{ top: 8, left: 26 }}
          />
          <img
            src={RentalThumbnail}
            alt="Rental thumbnail"
            className="w-10 h-10 rounded-4px border-1 border-element-border/50"
          />
        </div>

        <div>
          <p className="font-semibold text-high-contrast">
            Appartement Lafayette
          </p>
          <p className="font-light text-low-contrast">
            51 Boulevard Garibaldi 75015 Paris
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.nbAdults")}
          </p>
          <TextInput value="2" RightIcon={UsersIcon} />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.nbChildren")}
          </p>
          <TextInput value="1" RightIcon={EmojiHappyIcon} />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.status")}
          </p>
          <InputSelect
            items={[{ value: 0, label: "Confirmé" }]}
            selectedValue={0}
          />
        </div>

        <div className="flex-1"></div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.checkinDate")}
          </p>
          <CalendarInput
            value={new Date()}
            dateType="default"
            onChangeDate={() => {}}
          />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.checkinTime")}
          </p>
          <TimeInput value="16:00" />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.checkoutDate")}
          </p>
          <CalendarInput
            value={new Date()}
            dateType="default"
            onChangeDate={() => {}}
          />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.checkoutTime")}
          </p>
          <TimeInput value="10:00" />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.priceByNight")}
          </p>
          <TextInput value="176" />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.cleaning")}
          </p>
          <TextInput value="40" />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.tax")}
          </p>
          <TextInput value="0" />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.commission")}
          </p>
          <TextInput value="0" />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.serviceFees")}
          </p>
          <TextInput value="0" />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.otherFees")}
          </p>
          <TextInput value="0" />
        </div>
      </div>

      <div className="pt-3 pb-1">
        <div className="flex flex-row items-center space-x-3">
          <div className="flex-1">
            <p className="mb-1 font-light text-low-contrast">
              {t("Booking.notes")}
            </p>
            <TextInput
              placeholder={t("Booking.labelAddNote")}
              value="Lit bébé à ajouter"
            />
          </div>
        </div>

        <p className="mt-1 font-light text-low-contrast">
          {t("Booking.privateNote")}
        </p>
      </div>
    </Card>
  );
};

const BookingViewerCardSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={HomeIcon}
      label={t("Booking.booking")}
      badge={{
        label: "",
        status: "pending",
        skeleton: true,
      }}
      button={{
        Icon: EditIcon,
        label: t("Booking.edit"),
        onClick: () => {},
        skeleton: true,
      }}
    >
      <div className="animate-pulse">
        <div className="flex flex-row items-center pb-2 space-x-3 border-b-1 border-element-border">
          <div className="relative">
            <div
              className="absolute z-10 border-2 border-white rounded-full bg-slate-300"
              style={{ top: 8, left: 26, width: 24, height: 24 }}
            />
            <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-300" />
          </div>

          <div>
            <p className="h-2 font-semibold rounded text-high-contrast w-72 bg-slate-300"></p>
            <p className="h-2 mt-1 font-light rounded text-low-contrast w-72 bg-slate-200"></p>
          </div>
        </div>

        <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
          <div className="flex-1">
            <p className="font-light text-low-contrast">
              {t("Booking.reference")}
            </p>
            <p className="w-32 h-2 mt-1 font-semibold rounded text-high-contrast bg-slate-300" />
          </div>

          <div className="flex-1">
            <p className="font-light text-low-contrast">
              {t("Booking.nbGuests")}
            </p>
            <div className="flex flex-row items-center space-x-1 font-semibold text-high-contrast">
              <UsersIcon width={12} height={12} />
              <p>
                <p className="w-8 h-2 font-semibold rounded text-high-contrast bg-slate-300" />
              </p>
              <EmojiHappyIcon width={12} height={12} />
              <p className="w-8 h-2 font-semibold rounded text-high-contrast bg-slate-300" />
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
          <div className="flex-1">
            <p className="font-light text-low-contrast">
              {t("Booking.checkin")}
            </p>
            <p className="h-2 mt-1 font-semibold rounded text-high-contrast w-44 bg-slate-300" />
          </div>

          <div className="flex-1">
            <p className="font-light text-low-contrast">
              {t("Booking.checkout")}
            </p>
            <p className="h-2 mt-1 font-semibold rounded text-high-contrast w-44 bg-slate-300" />
          </div>
        </div>

        <div className="flex flex-row items-center p-2 space-x-2 border-b-1 border-element-border">
          <div className="flex-1">
            <p className="font-light text-low-contrast">
              {t("Booking.nbNights")}
            </p>
            <p className="font-semibold text-high-contrast">
              <p className="w-16 h-2 mt-1 font-semibold rounded text-high-contrast bg-slate-300" />
            </p>
          </div>

          <div className="flex-1">
            <p className="font-light text-low-contrast">{t("Booking.total")}</p>
            <p className="font-semibold text-high-contrast">
              <p className="h-2 mt-1 font-semibold rounded text-high-contrast w-52 bg-slate-300" />
            </p>
          </div>
        </div>

        <div className="pt-3 pb-1">
          <div className="flex flex-row items-center space-x-3">
            <div className="flex-1">
              <p className="mb-1 font-light text-low-contrast">
                {t("Booking.notes")}
              </p>
              <p className="h-2 mt-1 font-semibold rounded text-high-contrast w-96 bg-slate-300" />
              <p className="h-2 mt-1 font-semibold rounded text-high-contrast w-72 bg-slate-300" />
            </div>
          </div>

          <p className="mt-1 font-light text-low-contrast">
            {t("Booking.privateNote")}
          </p>
        </div>
      </div>
    </Card>
  );
};

const JournalCard: React.FC<{
  reservation: ReservationResponse | null;
  journals?: Journal[];
  error: string | null;
}> = ({ reservation, journals, error }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card Icon={UsersIcon} label={t("Booking.activity")}>
        <WorkInProgress />
        {/* <div className="flex flex-row items-center pb-3 space-x-2 border-b-1 border-element-border">
          <div className="flex-1">
            <p className="mb-1 font-light text-low-contrast">
              {t("Booking.deposit")}
            </p>
            <Badge
              label={getDepositStatusBadge(reservation?.status_deposit).label}
              status={getDepositStatusBadge(reservation?.status_deposit).status}
            />
          </div>

          <div className="flex-1">
            <p className="mb-1 font-light text-low-contrast">
              {t("Booking.payment")}
            </p>
            <Badge
              label={getDepositStatusBadge(reservation?.status_payment).label}
              status={getDepositStatusBadge(reservation?.status_payment).status}
            />
          </div>

          <div className="flex-1">
            <p className="mb-1 font-light text-low-contrast">
              {t("Booking.contract")}
            </p>
            <Badge
              label={getDepositStatusBadge(reservation?.status_contract).label}
              status={
                getDepositStatusBadge(reservation?.status_contract).status
              }
            />
          </div>
        </div> */}

        {/* Journal */}
        {/* <div className="mt-3 mb-2">
          <p className="font-semibold text-low-contrast">
            {t("Booking.historical")}
          </p>

          <div className="mt-3">
            <ErrorMessage>{error}</ErrorMessage>

            {!error && (!journals || journals?.length === 0) && (
              <div className="p-4 font-semibold bg-element-background rounded-6px text-high-contrast">
                {t("Calendar.emptyJournal")}
              </div>
            )}

            {journals?.map((journal, index) => {
              const getIcon = () => {
                if (journal.type === "reservation_new") return FilePlusIcon;
                else if (journal.type === "reservation_generation_lock_code")
                  return LockIcon;
                else if (journal.type === "reservation_message_sent")
                  return MailIcon;
                else return EditIcon;
              };

              return (
                <HistoricalStep
                  title={journal.title}
                  description={journal.description}
                  Icon={getIcon()}
                  isLast={index === journals?.length - 1}
                />
              );
            })}
          </div>
        </div> */}
      </Card>
    </>
  );
};

const HistoricalCardSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card Icon={UsersIcon} label={t("Booking.activity")}>
      <div className="flex flex-row items-center pb-3 space-x-2 border-b-1 border-element-border">
        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.deposit")}
          </p>
          <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.payment")}
          </p>
          <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
        </div>

        <div className="flex-1">
          <p className="mb-1 font-light text-low-contrast">
            {t("Booking.contract")}
          </p>
          <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
        </div>
      </div>

      {/* Historical */}
      <div className="mt-3 mb-2">
        <p className="font-semibold text-low-contrast">
          {t("Booking.historical")}
        </p>

        <div className="mt-3">
          <HistoricalStep
            title=""
            description=""
            Icon={DollarIcon}
            loading={true}
          />

          <HistoricalStep
            title=""
            description=""
            Icon={DollarIcon}
            loading={true}
          />

          <HistoricalStep
            title=""
            description=""
            Icon={DollarIcon}
            loading={true}
            isLast={true}
          />
        </div>
      </div>
    </Card>
  );
};
