import React, { ReactNode, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import HomeIcon from "../../../../assets/icons/home.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import LogInIcon from "../../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../../assets/icons/log-out.svg?react";
import TargetErrorIcon from "../../../../assets/icons/target-error.svg?react";
import TargetSuccessIcon from "../../../../assets/icons/target-success.svg?react";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import countries from "../../../../constants/countries";
import { cn } from "../../../../helpers/classHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import rentalCategories from "../../../../res/rental_categories";
import rentalTypes from "../../../../res/rental_types";
import { RequiredFields, ValueType } from "../../../../types/commonTypes";
import { Badge } from "../../../Common/Badge/Badge";
import { Button } from "../../../Common/Button/Button";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { Separator } from "../../../Common/Separator/Separator";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import {
  RentalInformationsCardForm,
  RentalInformationsCardProps,
} from "./InformationsCard.type";
import { RentalInformationsCardSkeleton } from "./InformationsCardSkeleton";

export const RentalInformationsCard: React.FC<RentalInformationsCardProps> = ({
  rentals,
  rentalInformations,
}) => {
  const {
    loadingState: { loading, setLoading },
    isRentalActive,
    informations: {
      validationLoading,
      error,
      editModeState: { editMode, setEditMode },
      form,
      onUpdate,
      onChangeIsRentalActive,
    },
  } = useRentalPageContext();

  // const maxNbChildRentalItems = 4;
  // const {
  //   fields: childRentalFields,
  //   append: childAppend,
  //   remove: removeChild,
  //   update: updateChild,
  // } = useFieldArray({
  //   control,
  //   name: "childRentalItems",
  //   rules: { minLength: 1, maxLength: maxNbChildRentalItems },
  // });
  const [rentalItemsSelected, setRentalItemsSelected] = useState<
    Set<ValueType>
  >(new Set());

  const { t } = useTranslation();

  const requiredFields: RequiredFields<RentalInformationsCardForm> = {
    name: true,
    rentalCategory: true,
    rentalType: true,
    rentingType: true,
    managementType: true,
    maxAdults: true,
    maxChildren: true,
    maxGuest: true,
    maxBathrooms: true,
    minCheckinHour: true,
    maxCheckinHour: true,
    minCheckoutHour: true,
    maxCheckoutHour: true,
    minNights: true,
    maxNights: true,
    // parentRental: false,
    // childRentalItems: false,
  };

  useEffect(() => {
    if (rentalInformations && editMode === true) {
      form.setValue("name", rentalInformations?.name);
      // TODO Improve address matching between front with Google and Back fileds
      form.setValue("rentalCategory", rentalInformations?.rental_category_id);
      form.setValue("rentalType", rentalInformations?.rental_type_id);
      form.setValue("rentingType", rentalInformations?.renting_type);
      form.setValue("managementType", rentalInformations?.management_type);
      form.setValue("maxAdults", rentalInformations?.adults_max_capacity);
      form.setValue("maxChildren", rentalInformations?.children_max_capacity);
      form.setValue("maxGuest", rentalInformations?.guests_max_capacity ?? 1);
      form.setValue("maxBathrooms", rentalInformations?.bathrooms_count);
      form.setValue(
        "minCheckinHour",
        rentalInformations?.checkin_min_time ?? "14:00"
      );
      form.setValue(
        "maxCheckinHour",
        rentalInformations?.checkin_max_time ?? "22:00"
      );
      form.setValue(
        "minCheckoutHour",
        rentalInformations?.checkout_min_time ?? "08:00"
      );
      form.setValue(
        "maxCheckoutHour",
        rentalInformations?.checkout_max_time ?? "11:00"
      );
      form.setValue("minNights", rentalInformations?.min_nights_default ?? 1);
      form.setValue("maxNights", rentalInformations?.max_nights_default ?? 1);
      // form.setValue("parentRental", rentalInformations?.parent_dependency?.id ?? 0);

      // if (
      //   rentalInformations?.children_dependencies &&
      //   rentalInformations?.children_dependencies.length > 0
      // ) {
      //   rentalInformations?.children_dependencies.forEach((item, index) => {
      //     childAppend({
      //       value: item.id,
      //     });
      //   });
      // } else {
      //   if (childRentalFields.length === 0) {
      //     childAppend({
      //       value: 0,
      //     });
      //   }
      // }
    }
  }, [rentalInformations, editMode]);

  // TODO: [RentalInformationsCard] To implement properly
  // const handleChangeDependency = (value: ValueType) =>
  //   setRentalItemsSelected((prevItems) => new Set([...prevItems, value]));

  // useEffect(() => {
  //   if (form.getValues("parentRental")) {
  //     handleChangeDependency(form.getValues("parentRental"));
  //     console.log("parent rental : ", form.getValues("parentRental"));
  //   }
  // }, [form.getValues("parentRental")]);

  // useEffect(() => {
  //   if (childRentalFields.length > 0) {
  //     childRentalFields.map((i) => {
  //       if (i.value !== 0) {
  //         handleChangeDependency(i.value);
  //       }
  //     });
  //     console.log("childRentalFields : ", childRentalFields);
  //   }
  // }, [childRentalFields]);

  // useEffect(() => {
  //   console.log("rentalItemsSelected : ", rentalItemsSelected);
  // }, [rentalItemsSelected]);

  const getRentingType = (): ReactNode => {
    let value: string = "ENTIRE_HOUSE";

    if (rentalInformations?.renting_type === "ENTIRE_HOUSE") {
      value = t("Rental.Infos.Infos.RentingTypeItems.entireHouse");
    } else if (rentalInformations?.renting_type === "SINGLE_ROOM") {
      value = t("Rental.Infos.Infos.RentingTypeItems.singleRoom");
    } else if (rentalInformations?.renting_type === "SHARED_ROOM") {
      value = t("Rental.Infos.Infos.RentingTypeItems.sharedRoom");
    }

    return (
      <div className="flex flex-row items-center space-x-2 p-1.5 mt-2 rounded-md bg-element-background w-max">
        <HomeIcon className="font-extrabold" width={20} height={20} />
        <span className="font-extrabold text-low-contrast">{value}</span>
      </div>
    );
  };

  const getManagementType = (): ReactNode => {
    let value: string = "OWN_PROPERTY";

    if (rentalInformations?.management_type === "OWN_PROPERTY") {
      value = t("Rental.Infos.Infos.ManagementTypeItems.ownProperty");
    } else if (rentalInformations?.management_type === "CONCIERGE") {
      value = t("Rental.Infos.Infos.ManagementTypeItems.concierge");
    } else if (rentalInformations?.management_type === "SUBLETTING") {
      value = t("Rental.Infos.Infos.ManagementTypeItems.subletting");
    }

    return (
      <div className="flex flex-row items-center space-x-2 p-1.5 mt-2 rounded-md bg-element-background w-max">
        <span className="font-extrabold text-low-contrast">{value}</span>
      </div>
    );
  };

  const getRentalItems = () => {
    return rentals && rentals.length > 0
      ? [
          {
            label: t("Rental.Infos.Infos.selectRentalLabel"),
            value: 0,
            isVisible: false,
          },
          ...rentals?.map((r) => {
            return {
              label: r.name,
              value: r.id,
            };
          }),
        ]
      : [];
  };

  const getChildRentalItems = () => {
    return rentals && rentals.length > 0
      ? [
          {
            label: t("Rental.Infos.Infos.selectRentalLabel"),
            value: 0,
            isVisible: false,
          },
          ...rentals
            .filter((r) => !rentalItemsSelected.has(r.id))
            .map((r) => {
              return {
                label: r.name,
                value: r.id,
              };
            }),
        ]
      : [];
  };

  form.watch();

  if (loading) return <RentalInformationsCardSkeleton />;

  return (
    <Card
      loading={validationLoading}
      Icon={InformationIcon}
      label={t("Rental.Infos.Infos.title")}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(() => onUpdate({}))
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            {/* ACTIVE RENTAL */}
            <div
              className={cn(
                `flex flex-row justify-between p-2 space-x-3 rounded border-1 border-element-border items-center`,
                {
                  "bg-element-background-success": isRentalActive,
                  "bg-element-background-error": !isRentalActive,
                }
              )}
            >
              <div className="flex items-center space-x-3">
                {isRentalActive ? <TargetSuccessIcon /> : <TargetErrorIcon />}
                <div>
                  <p
                    className={cn(`font-extrabold`, {
                      "text-success": isRentalActive,
                      "text-error": !isRentalActive,
                    })}
                  >
                    {isRentalActive
                      ? t("Rental.Infos.Infos.activedRental")
                      : t("Rental.Infos.Infos.inactivedRental")}
                  </p>
                  <p
                    className={cn({
                      "text-success": isRentalActive,
                      "text-error": !isRentalActive,
                    })}
                  >
                    {isRentalActive
                      ? t("Rental.Infos.Infos.activedRentalSubtitle")
                      : t("Rental.Infos.Infos.inactivedRentalSubtitle")}
                  </p>
                </div>
              </div>
              <div>
                <Button
                  type={isRentalActive ? "secondary" : "primary"}
                  onClick={onChangeIsRentalActive}
                >
                  {isRentalActive
                    ? t("Global.deactivate")
                    : t("Global.activate")}
                </Button>
              </div>
            </div>

            <TextInput
              disabled={validationLoading}
              label={t("Rental.Infos.Infos.nameFieldLabel")}
              required={requiredFields.name}
              register={form.register("name", {
                required: {
                  value: requiredFields.name,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                  }),
                },
              })}
              error={form.formState.errors.name?.message}
            />

            {/* Category type / Rental type */}
            <div className="flex justify-between space-x-3">
              <Controller
                control={form.control}
                name="rentalCategory"
                rules={{
                  required: {
                    value: requiredFields.rentalCategory,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.categoryFieldLabel"),
                    }),
                  },
                  onChange: (event) => {
                    form.setValue("rentalCategory", event.target.value);
                    form.setValue("rentalType", null);
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      required={requiredFields.rentalCategory}
                      disabled={validationLoading}
                      label={t("Rental.Infos.Infos.categoryFieldLabel")}
                      selectedValue={value}
                      items={rentalCategories.map((c) => {
                        return { value: c?.id, label: c.fr };
                      })}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="rentalType"
                rules={{
                  required: {
                    value: requiredFields.rentalType,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.typeFieldLabel"),
                    }),
                  },
                  onChange: (event) => {
                    form.setValue("rentalType", event.target.value);
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="flex-1">
                    <InputSelect
                      required={requiredFields.rentalType}
                      disabled={validationLoading}
                      label={t("Rental.Infos.Infos.typeFieldLabel")}
                      selectedValue={value ?? ""}
                      items={rentalTypes
                        .filter(
                          (t) =>
                            t.rental_category_id ===
                            form.watch("rentalCategory")
                        )
                        .map((t) => {
                          return { value: t?.id, label: t.fr };
                        })}
                      onSelect={onChange}
                      error={form.formState.errors.rentalType?.message}
                    />
                  </div>
                )}
              />
            </div>

            {/* Renting type / Management type */}
            <div className="flex justify-between space-x-3">
              <Controller
                control={form.control}
                name="rentingType"
                rules={{
                  required: {
                    value: requiredFields.rentingType,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.rentingTypeFieldLabel"),
                    }),
                  },
                  onChange: (event) => {
                    form.setValue("rentingType", event.target.value);
                  },
                }}
                render={({ field: { onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      required={requiredFields.rentalType}
                      disabled={validationLoading}
                      label={t("Rental.Infos.Infos.rentingTypeFieldLabel")}
                      selectedValue={form.getValues("rentingType")}
                      items={[
                        {
                          label: t(
                            "Rental.Infos.Infos.RentingTypeItems.entireHouse"
                          ),
                          value: "ENTIRE_HOUSE",
                        },
                        {
                          label: t(
                            "Rental.Infos.Infos.RentingTypeItems.singleRoom"
                          ),
                          value: "SINGLE_ROOM",
                        },
                        {
                          label: t(
                            "Rental.Infos.Infos.RentingTypeItems.sharedRoom"
                          ),
                          value: "SHARED_ROOM",
                        },
                      ]}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />

              <Controller
                control={form.control}
                name="managementType"
                rules={{
                  required: {
                    value: requiredFields.managementType,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t(
                        "Rental.Infos.Infos.managementTypeFieldLabel"
                      ),
                    }),
                  },
                  onChange: (event) => {
                    form.setValue("managementType", event.target.value);
                  },
                }}
                render={({ field: { onChange } }) => (
                  <div className="flex-1">
                    <InputSelect
                      required={requiredFields.managementType}
                      disabled={validationLoading}
                      label={t("Rental.Infos.Infos.managementTypeFieldLabel")}
                      selectedValue={form.getValues("managementType")}
                      items={[
                        {
                          label: t(
                            "Rental.Infos.Infos.ManagementTypeItems.ownProperty"
                          ),
                          value: "OWN_PROPERTY",
                        },
                        {
                          label: t(
                            "Rental.Infos.Infos.ManagementTypeItems.concierge"
                          ),
                          value: "CONCIERGE",
                        },
                        {
                          label: t(
                            "Rental.Infos.Infos.ManagementTypeItems.subletting"
                          ),
                          value: "SUBLETTING",
                        },
                      ]}
                      onSelect={onChange}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex flex-row gap-3">
              <Controller
                control={form.control}
                name="maxGuest"
                render={({ field: { value, onChange } }) => {
                  const handleChange = (value: string | number) => {
                    onChange(value);

                    if (form.watch("maxAdults") > Number(value)) {
                      form.setValue("maxAdults", Number(value));
                    }

                    if (form.watch("maxChildren") + 1 > Number(value)) {
                      form.setValue("maxChildren", Number(value) - 1);
                    }
                  };

                  return (
                    <NumberInput
                      label={t("Rental.Infos.Infos.maxTravellersFieldLabel")}
                      required={requiredFields.maxGuest}
                      value={value}
                      onChangeText={handleChange}
                      error={form.formState.errors.maxGuest?.message}
                      min={1}
                      disabled={validationLoading}
                    />
                  );
                }}
              />

              <NumberInput
                required={requiredFields.maxBathrooms}
                disabled={validationLoading}
                label={t("Rental.Infos.Infos.maxBathroomsFieldLabel")}
                acceptDecimal={false}
                register={form.register("maxBathrooms", {
                  required: {
                    value: requiredFields.maxBathrooms,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.maxBathroomsFieldLabel"),
                    }),
                  },
                })}
                value={form.getValues("maxBathrooms")}
                error={form.formState.errors.maxBathrooms?.message}
              />
            </div>

            <div className="flex flex-row gap-3">
              <NumberInput
                required={requiredFields.maxAdults}
                disabled={validationLoading}
                label={t("Rental.Infos.Infos.maxAdultsFieldLabel")}
                acceptDecimal={false}
                register={form.register("maxAdults", {
                  required: {
                    value: requiredFields.maxAdults,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.maxAdultsFieldLabel"),
                    }),
                  },
                })}
                error={form.formState.errors.maxAdults?.message}
                value={form.getValues("maxAdults")}
                min={1}
                max={form.watch("maxGuest")}
              />
              <NumberInput
                required={requiredFields.maxChildren}
                disabled={validationLoading}
                label={t("Rental.Infos.Infos.maxChildrenFieldLabel")}
                acceptDecimal={false}
                register={form.register("maxChildren", {
                  required: {
                    value: requiredFields.maxChildren,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.maxChildrenFieldLabel"),
                    }),
                  },
                })}
                value={form.getValues("maxChildren")}
                error={form.formState.errors.maxChildren?.message}
                min={0}
                max={form.watch("maxGuest") - 1}
              />
            </div>

            <Separator />

            {/* CHECKIN */}
            <div className="flex flex-row items-center space-x-1 font-extrabold text-low-contrast">
              <LogInIcon width={15} height={15} />
              <span>{t("Global.checkin", { count: 1 })}</span>
            </div>

            <div className="flex flex-row gap-3">
              <div className="flex-1">
                <InputSelect
                  register={form.register("minCheckinHour", {
                    required: {
                      value: requiredFields.minCheckinHour,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Infos.Infos.minCheckinHourFieldLabel"
                        ),
                      }),
                    },
                    onChange: (e) =>
                      form.setValue("minCheckinHour", e.target.value),
                  })}
                  required={requiredFields.minCheckinHour}
                  infoTooltip={t("Rental.Infos.Infos.tooltipMinCheckin")}
                  label={t("Rental.Infos.Infos.minCheckinHourFieldLabel")}
                  items={[
                    {
                      label: "Flexible",
                      value: "Flexible",
                    },
                    {
                      label: "08:00",
                      value: "08:00",
                    },
                    {
                      label: "09:00",
                      value: "09:00",
                    },
                    {
                      label: "10:00",
                      value: "10:00",
                    },
                    {
                      label: "11:00",
                      value: "11:00",
                    },
                    {
                      label: "12:00",
                      value: "12:00",
                    },
                    {
                      label: "13:00",
                      value: "13:00",
                    },
                    {
                      label: "14:00",
                      value: "14:00",
                    },
                    {
                      label: "15:00",
                      value: "15:00",
                    },
                    {
                      label: "16:00",
                      value: "16:00",
                    },
                    {
                      label: "17:00",
                      value: "17:00",
                    },
                    {
                      label: "18:00",
                      value: "18:00",
                    },
                    {
                      label: "19:00",
                      value: "19:00",
                    },
                    {
                      label: "20:00",
                      value: "20:00",
                    },
                    {
                      label: "21:00",
                      value: "21:00",
                    },
                    {
                      label: "22:00",
                      value: "22:00",
                    },
                    {
                      label: "23:00",
                      value: "23:00",
                    },
                  ]}
                  selectedValue={form.getValues("minCheckinHour")}
                  error={form.formState.errors.minCheckinHour?.message}
                  disabled={validationLoading}
                />
              </div>

              <div className="flex-1">
                <InputSelect
                  register={form.register("maxCheckinHour", {
                    required: {
                      value: requiredFields.maxCheckinHour,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Infos.Infos.maxCheckinHourFieldLabel"
                        ),
                      }),
                    },
                  })}
                  required={requiredFields.maxCheckinHour}
                  infoTooltip={t("Rental.Infos.Infos.tooltipMaxCheckin")}
                  label={t("Rental.Infos.Infos.maxCheckinHourFieldLabel")}
                  items={[
                    {
                      label: "Flexible",
                      value: "Flexible",
                    },
                    {
                      label: "17:00",
                      value: "17:00",
                    },
                    {
                      label: "18:00",
                      value: "18:00",
                    },
                    {
                      label: "19:00",
                      value: "19:00",
                    },
                    {
                      label: "20:00",
                      value: "20:00",
                    },
                    {
                      label: "21:00",
                      value: "21:00",
                    },
                    {
                      label: "22:00",
                      value: "22:00",
                    },
                    {
                      label: "23:00",
                      value: "23:00",
                    },
                    {
                      label: "00:00",
                      value: "00:00",
                    },
                    {
                      label: "01:00 (jour suivant)",
                      value: "01:00 (jour suivant)",
                    },
                    {
                      label: "02:00 (jour suivant)",
                      value: "02:00 (jour suivant)",
                    },
                  ]}
                  selectedValue={form.getValues("maxCheckinHour")}
                  error={form.formState.errors.maxCheckinHour?.message}
                  disabled={validationLoading}
                />
              </div>
            </div>

            {/* CHECKOUT */}
            <div className="flex flex-row items-center space-x-1 font-extrabold text-low-contrast">
              <LogOutIcon width={15} height={15} />
              <span>{t("Global.checkout", { count: 1 })}</span>
            </div>

            <div className="flex flex-row gap-3">
              <div className="flex-1">
                <InputSelect
                  register={form.register("minCheckoutHour", {
                    required: {
                      value: requiredFields.minCheckoutHour,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Infos.Infos.minCheckoutHourFieldLabel"
                        ),
                      }),
                    },
                  })}
                  required={requiredFields.minCheckoutHour}
                  infoTooltip={t("Rental.Infos.Infos.tooltipMinCheckout")}
                  label={t("Rental.Infos.Infos.minCheckoutHourFieldLabel")}
                  items={[
                    {
                      label: "00:00",
                      value: "00:00",
                    },
                    {
                      label: "01:00",
                      value: "01:00",
                    },
                    {
                      label: "02:00",
                      value: "02:00",
                    },
                    {
                      label: "03:00",
                      value: "03:00",
                    },
                    {
                      label: "04:00",
                      value: "04:00",
                    },
                    {
                      label: "05:00",
                      value: "05:00",
                    },
                    {
                      label: "06:00",
                      value: "06:00",
                    },
                    {
                      label: "07:00",
                      value: "07:00",
                    },
                    {
                      label: "08:00",
                      value: "08:00",
                    },
                    {
                      label: "09:00",
                      value: "09:00",
                    },
                    {
                      label: "10:00",
                      value: "10:00",
                    },
                    {
                      label: "11:00",
                      value: "11:00",
                    },
                    {
                      label: "12:00",
                      value: "12:00",
                    },
                    {
                      label: "13:00",
                      value: "13:00",
                    },
                    {
                      label: "14:00",
                      value: "14:00",
                    },
                    {
                      label: "15:00",
                      value: "15:00",
                    },
                    {
                      label: "16:00",
                      value: "16:00",
                    },
                    {
                      label: "17:00",
                      value: "17:00",
                    },
                    {
                      label: "18:00",
                      value: "18:00",
                    },
                    {
                      label: "19:00",
                      value: "19:00",
                    },
                    {
                      label: "20:00",
                      value: "20:00",
                    },
                    {
                      label: "21:00",
                      value: "21:00",
                    },
                    {
                      label: "22:00",
                      value: "22:00",
                    },
                    {
                      label: "23:00",
                      value: "23:00",
                    },
                  ]}
                  selectedValue={form.getValues("minCheckoutHour")}
                  error={form.formState.errors.minCheckoutHour?.message}
                  disabled={validationLoading}
                />
              </div>
              <div className="flex-1">
                <InputSelect
                  register={form.register("maxCheckoutHour", {
                    required: {
                      value: requiredFields.maxCheckoutHour,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Infos.Infos.maxCheckoutHourFieldLabel"
                        ),
                      }),
                    },
                  })}
                  required={requiredFields.maxCheckoutHour}
                  infoTooltip={t("Rental.Infos.Infos.tooltipMaxCheckout")}
                  label={t("Rental.Infos.Infos.maxCheckoutHourFieldLabel")}
                  items={[
                    {
                      label: "00:00",
                      value: "00:00",
                    },
                    {
                      label: "01:00",
                      value: "01:00",
                    },
                    {
                      label: "02:00",
                      value: "02:00",
                    },
                    {
                      label: "03:00",
                      value: "03:00",
                    },
                    {
                      label: "04:00",
                      value: "04:00",
                    },
                    {
                      label: "05:00",
                      value: "05:00",
                    },
                    {
                      label: "06:00",
                      value: "06:00",
                    },
                    {
                      label: "07:00",
                      value: "07:00",
                    },
                    {
                      label: "08:00",
                      value: "08:00",
                    },
                    {
                      label: "09:00",
                      value: "09:00",
                    },
                    {
                      label: "10:00",
                      value: "10:00",
                    },
                    {
                      label: "11:00",
                      value: "11:00",
                    },
                    {
                      label: "12:00",
                      value: "12:00",
                    },
                    {
                      label: "13:00",
                      value: "13:00",
                    },
                    {
                      label: "14:00",
                      value: "14:00",
                    },
                    {
                      label: "15:00",
                      value: "15:00",
                    },
                    {
                      label: "16:00",
                      value: "16:00",
                    },
                    {
                      label: "17:00",
                      value: "17:00",
                    },
                    {
                      label: "18:00",
                      value: "18:00",
                    },
                    {
                      label: "19:00",
                      value: "19:00",
                    },
                    {
                      label: "20:00",
                      value: "20:00",
                    },
                    {
                      label: "21:00",
                      value: "21:00",
                    },
                    {
                      label: "22:00",
                      value: "22:00",
                    },
                    {
                      label: "23:00",
                      value: "23:00",
                    },
                  ]}
                  selectedValue={form.getValues("maxCheckoutHour")}
                  error={form.formState.errors.maxCheckoutHour?.message}
                  disabled={validationLoading}
                />
              </div>
            </div>

            <div className="flex flex-row gap-3">
              <NumberInput
                required={requiredFields.minNights}
                disabled={validationLoading}
                label={t("Rental.Infos.Infos.minNightsFieldLabel")}
                acceptDecimal={false}
                register={form.register("minNights", {
                  required: {
                    value: requiredFields.minNights,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.minNightsFieldLabel"),
                    }),
                  },
                })}
                error={form.formState.errors.minNights?.message}
                value={form.getValues("minNights")}
              />
              <NumberInput
                required={requiredFields.maxNights}
                disabled={validationLoading}
                label={t("Rental.Infos.Infos.maxNightsFieldLabel")}
                acceptDecimal={false}
                register={form.register("maxNights", {
                  required: {
                    value: requiredFields.maxNights,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.maxNightsFieldLabel"),
                    }),
                  },
                })}
                error={form.formState.errors.maxNights?.message}
                value={form.getValues("maxNights")}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between gap-3">
              <div className="flex flex-col mt-2">
                <p className="text-lg font-extrabold">
                  {rentalInformations?.name}
                </p>
                <div className="flex flex-row flex-wrap w-full mt-1 gap-x-2 text-low-contrast">
                  <span>
                    {t("Rental.Infos.Infos.bedroom", {
                      count: rentalInformations?.bedrooms_count ?? 0,
                    })}
                  </span>
                  <span>
                    {" "}
                    {t("Rental.Infos.Infos.bed", {
                      count: rentalInformations?.beds_count ?? 0,
                    })}
                  </span>
                  <span>
                    {" "}
                    {t("Rental.Infos.Infos.bathroom", {
                      count: rentalInformations?.bathrooms_count ?? 0,
                    })}
                  </span>
                </div>

                <div className="flex flex-row items-center mt-2 space-x-1 font-light text-low-contrast">
                  <UsersIcon width={18} height={18} />
                  <span>
                    {`${t("Global.adults", {
                      count: rentalInformations?.adults_max_capacity,
                    })} - ${t("Global.children", {
                      count: rentalInformations?.children_max_capacity,
                    })}`}
                  </span>
                </div>

                {/* TODO: [RentalInformationsCard] Set real data */}
                <div className="flex items-center gap-x-1">
                  {getRentingType()}
                  {getManagementType()}
                </div>
              </div>

              <div>
                {!isRentalActive ? (
                  <Badge
                    label={
                      isRentalActive
                        ? t("Global.active", { context: "male" })
                        : t("Global.inactive", { context: "male" })
                    }
                    status={isRentalActive ? "success" : "fail"}
                    size="large"
                  />
                ) : (
                  <Tooltip
                    Icon={
                      <Badge
                        label={
                          isRentalActive
                            ? t("Global.active", { context: "male" })
                            : t("Global.inactive", { context: "male" })
                        }
                        status={isRentalActive ? "success" : "fail"}
                        size="large"
                      />
                    }
                    value={
                      isRentalActive
                        ? t("Rental.Infos.Infos.activeStatusTooltip")
                        : t("Rental.Infos.Infos.inactiveStatusTooltip")
                    }
                    classNames={{
                      container: "top-full mt-1 right-0",
                    }}
                  />
                )}
              </div>
            </div>

            <Separator />

            <div className="flex flex-row gap-x-6">
              <div className="flex flex-col gap-y-3">
                <div>
                  <div className="flex items-center space-x-2">
                    <p className="text-low-contrast text-md">
                      {t("Rental.Infos.Infos.startTimeArrival")}
                    </p>
                    <Tooltip
                      value={t("Rental.Infos.Infos.tooltipMinCheckin")}
                      size={"large"}
                    />
                  </div>
                  <p className="text-lg font-extrabold">
                    {rentalInformations?.checkin_min_time}
                  </p>
                </div>

                <div>
                  <p className="text-low-contrast text-md">
                    {t("Rental.Infos.Infos.minNight")}
                  </p>
                  <p className="text-lg font-extrabold">
                    {rentalInformations?.min_nights_default}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-y-3">
                <div>
                  <div className="flex items-center space-x-2">
                    <p className="text-low-contrast text-md">
                      {t("Rental.Infos.Infos.endTimeArrival")}
                    </p>
                    <Tooltip
                      value={t("Rental.Infos.Infos.tooltipMaxCheckin")}
                      size={"large"}
                    />
                  </div>
                  <p className="text-lg font-extrabold">
                    {rentalInformations?.checkin_max_time}
                  </p>
                </div>
                <div>
                  <p className="text-low-contrast text-md">
                    {t("Rental.Infos.Infos.maxNight")}
                  </p>
                  <p className="text-lg font-extrabold">
                    {rentalInformations?.max_nights_default}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-y-3">
                <div>
                  <div className="flex items-center space-x-2">
                    <p className="text-low-contrast text-md">
                      {t("Rental.Infos.Infos.startTime")}
                    </p>
                    <Tooltip
                      value={t("Rental.Infos.Infos.tooltipStartTime")}
                      size={"large"}
                    />
                  </div>
                  <p className="text-lg font-extrabold">
                    {rentalInformations?.checkout_max_time}
                  </p>
                </div>
              </div>
            </div>

            {/* {rentalInformations?.parent_dependency ||
            rentalInformations?.children_dependencies?.length! > 0 ? (
              <Separator />
            ) : null} */}

            {rentalInformations?.parent_dependency ? (
              <div className="flex flex-col gap-y-8">
                {/* PARENT DEPENDENCY */}
                <div className="flex flex-col gap-y-3">
                  <div className="flex items-center space-x-2">
                    <p className="text-low-contrast ">
                      {t("Rental.Infos.Infos.labelParentRental")}
                    </p>
                    <Tooltip
                      value={t("Rental.Infos.Infos.tooltipParentRental")}
                      size={"large"}
                    />
                  </div>

                  <div className="flex flex-row items-center gap-2">
                    <div className="relative">
                      <div className="object-fill overflow-hidden w-11 h-11 rounded-4px border-1 border-element-border/50">
                        <img
                          src={rentalInformations?.parent_dependency.cover}
                          className="object-fill w-full h-full"
                        />
                      </div>
                    </div>

                    <div>
                      <p className="mb-1.5 font-extrabold text-md">
                        {rentalInformations?.parent_dependency.name}
                      </p>
                      <span className="text-low-contrast">
                        {`${t("Global.guest", { count: 0 })} - ${t(
                          "Global.bedroom",
                          {
                            count:
                              rentalInformations?.parent_dependency
                                .bedrooms_count ?? 0,
                          }
                        )} - ${t("Global.bed", {
                          count:
                            rentalInformations?.parent_dependency.beds_count ??
                            0,
                        })} - ${t("Global.bathroom", {
                          count:
                            rentalInformations?.parent_dependency
                              .bathrooms_count ?? 0,
                        })}`}
                      </span>
                    </div>
                  </div>
                </div>

                {/* CHILD DEPENDENCIES */}
                {rentalInformations?.children_dependencies &&
                rentalInformations.children_dependencies.length > 0 ? (
                  <div className="flex flex-col gap-y-3">
                    <div className="flex items-center space-x-2">
                      <p className="text-low-contrast ">
                        {t("Rental.Infos.Infos.childRentalFieldLabel", {
                          count:
                            rentalInformations.children_dependencies?.length,
                        })}
                      </p>
                      <Tooltip
                        value={t("Rental.Infos.Infos.tooltipChildRental")}
                        size={"large"}
                      />
                    </div>
                    {rentalInformations.children_dependencies.map((item) => (
                      <div
                        className="flex flex-row items-center gap-2"
                        key={item.id}
                      >
                        <div className="relative">
                          <div className="object-fill overflow-hidden w-11 h-11 rounded-4px border-1 border-element-border/50">
                            <img
                              src={item.cover}
                              className="object-fill w-full h-full"
                            />
                          </div>
                        </div>

                        <div>
                          <p className="mb-1.5 font-extrabold text-md">
                            {item.name}
                          </p>
                          <span className="text-low-contrast">
                            {`${t("Global.guest", { count: 0 })} - ${t(
                              "Global.bedroom",
                              {
                                count: item.bedrooms_count ?? 0,
                              }
                            )} - ${t("Global.bed", {
                              count: item.beds_count ?? 0,
                            })} - ${t("Global.bathroom", {
                              count: item.bathrooms_count ?? 0,
                            })}`}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
    </Card>
  );
};
