import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "../../../Common/Badge/Badge";
import { TextInput } from "../../../Common/TextInput/TextInput";

import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import paths from "../../../../constants/paths";
import { useTablePage } from "../../../../hooks/useTablePage";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PagingButtons } from "../../../Common/PagingButtons/PagingButtons";

type Listing = {
  id: string;
  name: string;
  image: string;
  status: string;
};

export const AirbnbConnectStepImport: React.FC<{
  airbnb_account_id: string | null;
  apiLoading: boolean;
  apiError: string | null;
  formError: string | null;
  onSelectionChange: (ids: string[]) => void;
  onNext: () => void;
}> = ({
  airbnb_account_id,
  apiLoading,
  apiError,
  formError,
  onSelectionChange = () => {},
  onNext = () => {},
}) => {
  const { t } = useTranslation();
  const tableListings = useTablePage(
    `${import.meta.env.VITE_API_URL}${
      paths.API.AIRBNB.GET_LISTINGS
    }?airbnb_account_id=${airbnb_account_id}`,
    "listings"
  );

  const [selectedListingIds, setSelectedListingIds] = useState<string[]>([]);

  useEffect(() => {
    console.log("airbnb_account_id", airbnb_account_id);
    tableListings.fetch({});
  }, []);

  const handleSelect = (id: string) => {
    console.log("select", id);

    let nextSelected = selectedListingIds;
    const index = selectedListingIds.findIndex((x) => x === id);

    if (index === -1) {
      nextSelected.push(id);
    } else {
      nextSelected = selectedListingIds.filter((x) => x !== id);
    }

    setSelectedListingIds([...nextSelected]);

    console.log(nextSelected);

    onSelectionChange(nextSelected);
  };

  const handleToggleAll = () => {
    let nextSelected: string[] = [];

    if (selectedListingIds?.length === tableListings.data?.length) {
      nextSelected = [];
    } else {
      nextSelected = tableListings.data?.map((l: any) => l.id);
    }

    setSelectedListingIds(nextSelected);
    onSelectionChange(nextSelected);
  };

  return (
    <>
      <ErrorMessage>{apiError}</ErrorMessage>

      <div className="flex flex-row items-end space-x-3">
        <TextInput
          label={t("Progression.searchRental")}
          placeholder={t("Progression.placeholderSearchRental")}
          Icon={SearchIcon}
          onChangeText={tableListings.handleChangeSearch}
          value={tableListings?.currentSearch}
        />
      </div>

      <div className="mt-3">
        <table className="w-full overflow-hidden border-separate table-auto rounded-4px border-1 border-element-border border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className="p-2 pr-0 text-sm" onClick={handleToggleAll}>
              <SimpleBox
                value={
                  selectedListingIds?.length === tableListings.data?.length
                }
                disabled={tableListings?.loading}
              />
            </th>
            <th className="px-1 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>
                  {t("Progression.activeRentalsAirbnb", {
                    count: tableListings.data?.length,
                  })}
                </p>
              </div>
            </th>
          </tr>

          {tableListings?.loading &&
            Array.from({ length: 5 }).map(() => <ListingLineSkeleton />)}

          {!tableListings?.loading &&
            tableListings.data?.map((listing: any) => (
              <ListingLine
                key={listing.id}
                isSelected={
                  selectedListingIds.findIndex((x) => x == listing.id) > -1
                }
                listing={listing}
                disabled={apiLoading}
                onSelect={() => handleSelect(listing.id)}
              />
            ))}
        </table>
      </div>

      {/* Bottom with paging */}
      <PagingButtons
        i18nElement={"Global.rentals"}
        loading={tableListings?.loading}
        nbPerPage={tableListings?.currentNbPerPage}
        page={tableListings?.currentPage}
        lastPage={tableListings?.lastPage}
        total={tableListings?.total}
        onChangeNbPerPage={tableListings?.handleChangeNbPerPage}
        onPaginationBack={tableListings?.handlePaginationBack}
        onPaginationNext={tableListings?.handlePaginationNext}
      />

      {/* Errors */}
      <ErrorMessage errorType="FORM">{formError}</ErrorMessage>
      <ErrorMessage>{tableListings?.error}</ErrorMessage>

      <div className="mt-6">
        <Button
          RightIcon={CheckIcon}
          disabled={selectedListingIds.length === 0}
          onClick={onNext}
        >
          {t("Progression.Airbnb.Import.submit", {
            count: selectedListingIds.length,
          })}
        </Button>
      </div>
    </>
  );
};

const ListingLine: React.FC<{
  isSelected: boolean;
  listing: Listing;
  disabled: boolean;
  onSelect: () => void;
}> = ({ listing, isSelected, disabled, onSelect }) => {
  const handleClick = () => {
    if (disabled) return;

    onSelect();
  };

  return (
    <tr
      key={`listing-${listing.id}`}
      className={`hover:bg-element-background/40 items-center ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleClick}
    >
      <td className="p-2 pr-0">
        <SimpleBox value={isSelected} disabled={disabled} />
      </td>
      <td className="flex flex-row items-center gap-2 py-2">
        <div>
          <Badge status={listing.status === "ACTIVE" ? "success" : "pending"} />
        </div>
        <div>
          <img
            src={listing.image}
            alt="Rental thumbnail"
            className="w-10 h-10 rounded-4px border-1 border-element-border/50"
          />
        </div>
        <div className="flex-1">
          <p className="font-semibold text-high-contrast">{listing.name}</p>
        </div>
      </td>
    </tr>
  );
};

const ListingLineSkeleton: React.FC<{}> = () => {
  return (
    <tr className="items-center cursor-pointer hover:bg-element-background/40 animate-pulse">
      <td className="p-2 pr-0">
        <SimpleBox value={false} disabled={true} />
      </td>
      <td className="flex flex-row items-center gap-2 py-2">
        <div>
          <div className="w-10 h-10 rounded-4px bg-slate-200" />
        </div>
        <div>
          <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
        </div>
        <div className="flex-1">
          <p className="h-2 rounded w-36 bg-slate-300" />
        </div>
      </td>
    </tr>
  );
};

// TODO : MANAGE CHECKBOX BETTER
export const SimpleBox: React.FC<{ value: boolean; disabled?: boolean }> = ({
  value,
  disabled = false,
}) => {
  const getBorder = () => {
    if (!value) {
      return `
      border-1
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
    }

    return "";
  };

  const getBackground = () => {
    if (value) {
      return "bg-button-primary-default-top";
    }

    return "bg-white";
  };

  const opacity = disabled ? "opacity-64" : "";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  return (
    <div
      className={`
    flex w-4 h-4  
    items-center justify-center 
    rounded-4px
    ${getBorder()}
    ${getBackground()}
    ${opacity}
    ${cursor}
    `}
    >
      {value && <CheckIcon className="block w-3 h-3 text-white " />}
    </div>
  );
};
