import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../constants/paths";
import { get } from "../helpers/APIHelper";
import { UserResponse } from "../types/GETTypes";

export const useCheckAuth = (isEnabled: boolean = true) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserResponse>();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const responseUser = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
      );

      if (responseUser.response?.status === 401) navigate(paths.LOGIN);
      else if (
        (responseUser.response?.status === 400 &&
          responseUser.response?.data?.message ===
            "Please finalize your subscription to use SuperHote.") ||
        responseUser.response?.data?.message ===
          "Veuillez finaliser votre abonnement pour utiliser SuperHote."
      )
        navigate(paths.SUBSCRIPTION);
      else if (responseUser?.data?.success) {
        setUser(responseUser?.data?.result);
      }
    };

    if (isEnabled) fetchCurrentUser();

    console.log("CHECK USER LOGIN", isEnabled);
  }, []);

  return { user, setUser };
};
