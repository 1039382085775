import React from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CalendarInput } from "../CalendarInput/CalendarInput";
import {
  useChangeDateCalendar,
  useChangeEndDateInputCalendar,
  useChangeStartDateInputCalendar,
} from "../CalendarInput/CalendarInput.hooks";
import { CalendarInputValue } from "../CalendarInput/CalendarInput.type";
import { InputSelect } from "../InputSelect/InputSelect";
import {
  useChangeDateRangePreset,
  useInitDateFilter,
  useInitDateFilterStates,
} from "./DateFilter.hooks";
import { DateFilterProps } from "./DateFilter.type";

export const DateFilter: React.FC<DateFilterProps> = ({
  initParams,
  datesState,
  dateRangePresetState,
}) => {
  const { t } = useTranslation();

  const initDateFilterStates = useInitDateFilterStates();

  const [, setIsDateRangePreset] = initDateFilterStates.isDateRangePresetStates;

  const [dates, setDates] = datesState;
  const [dateRangePreset, setDateRangePreset] = dateRangePresetState;

  useInitDateFilter(initParams);

  return (
    <div className="flex items-center w-full">
      <InputSelect
        classNames={{
          button: "rounded-e-none",
        }}
        maxItemWidth={true}
        items={[
          {
            label: t("Global.today"),
            value: "today",
          },
          {
            label: t("Components.DateFilter.nextSevenDays"),
            value: "next_seven_days",
          },
          {
            label: t("Components.DateFilter.lastFourWeeks"),
            value: "last_four_weeks",
          },
          {
            label: t("Components.DateFilter.lastTreeMonths"),
            value: "last_three_months",
          },
          {
            label: t("Components.DateFilter.custom"),
            value: "custom",
            isVisible: false,
          },
        ]}
        selectedValue={dateRangePreset}
        onSelect={(value: ValueType) =>
          useChangeDateRangePreset(
            value,
            setDateRangePreset,
            setIsDateRangePreset
          )
        }
      />
      <CalendarInput
        classNames={{
          button: "border-s-0 rounded-md rounded-s-none bg-white",
        }}
        height="full"
        dateType={"range"}
        value={dates}
        onChangeStartDateInput={(date: string) =>
          useChangeStartDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset
          )
        }
        onChangeEndDateInput={(date: string) =>
          useChangeEndDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset
          )
        }
        onChangeDate={(value: CalendarInputValue) =>
          useChangeDateCalendar(value, setDates, setIsDateRangePreset)
        }
      />
    </div>
  );
};
