import { debounce } from "lodash";
import React, { useState } from "react";
import { get } from "../helpers/APIHelper";
import { ValueType } from "../types/commonTypes";

export type UseTablePageReturn = {
  error: string | null;
  loading: boolean;
  total: number | undefined | null;
  messagesReadCount: number | undefined | null;
  lastPage: number | undefined | null;
  currentPage: number;
  currentNbPerPage: number;
  currentSearch: string;
  currentRentalsId: ValueType[] | null;
  currentSortField: string;
  currentSortDirection: "asc" | "desc";
  currentFilterCustomField: string;
  currentFilterCustomValue: ValueType;
  data: any[] | any;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  fetch: (param: {
    page?: number;
    limit?: number;
    search?: string;
    exclude_ids?: ValueType[] | null;
    include_ids?: ValueType[] | null;
    sort_field?: string;
    sort_direction?: "asc" | "desc";
    from?: string;
    to?: string;
  }) => void;
  handlePaginationBack: () => void;
  handlePaginationNext: () => void;
  handleChangeNbPerPage: (nbPerPage: ValueType) => void;
  handleChangeSearch: (text: string) => void;
  handleFilterRentalsIds: (ids: ValueType[]) => void;
  handleChangeSort: (field: string) => void;
  handleFilterStatus: (
    value: string | number | null,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => void;
  setCurrentRentalsId: React.Dispatch<React.SetStateAction<ValueType[] | null>>;
  currentOtherMetas: { key: string; value: string }[];
  handleFilterReadStatus: (value: number | null) => void;
  handleFilterMessage: (value: any | null) => void;
  handleFilterCustom: (field: string, value: ValueType) => void;
  hasFilter: () => boolean;
    setIsFiltered: React.Dispatch<React.SetStateAction<boolean>>;
    isFiltered: boolean;
};

export function useTablePage(
  URL: string,
  resultDataPropertyName: string,
  options?: {
    reverseData?: boolean;
    combine?: boolean;
    otherMetas?: string[];
    transform?: (data: any) => any;
  }
): UseTablePageReturn {
  const [error, setError] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number | undefined | null>();
  const [messagesReadCount, setMessagesReadCount] = useState<number | undefined | null>();
  const [lastPage, setLastPage] = useState<number | undefined | null>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentNbPerPage, setCurrentNbPerPage] = useState<number>(25);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<string | number | null>(
    null
  );
  const [currentReadStatus, setCurrentReadStatus] = useState<number | null>(
    null
  );
  const [currentRentalsId, setCurrentRentalsId] = useState<ValueType[] | null>(
    null
  );
  const [currentSortField, setCurrentSortField] = useState<string>("id");
  const [currentSortDirection, setCurrentSortDirection] = useState<
    "asc" | "desc"
  >("asc");

  const [currentFilterCustomField, setCurrentFilterCustomField] =
    useState<string>("");
  const [currentFilterCustomValue, setCurrentFilterCustomValue] =
    useState<ValueType>("all");

  const [currentFrom, setCurrentFrom] = useState<string>("");
  const [currentTo, setCurrentTo] = useState<string>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(
      false);

  const [currentOtherMetas, setCurrentOtherMetas] = useState<
    { key: string; value: string }[]
  >([]);
  const [data, setData] = useState<any[]>([]);

  const getCleanIncludeOrExcludeIds = (values: ValueType[] | null) => {
    if (values === null || values === undefined) return null;
    else if (values.length === 0) return -1;
    else return values.toString();
  };

  const fetch = async ({
    page = 1,
    limit = 50,
    search = "",
    include_ids = null,
    exclude_ids = null,
    sort_field = "id",
    sort_direction = "asc",
    status = null,
    custom_filter_field = "",
    custom_filter_value = "all",
    from,
    to,
    read_status_filter,
    conversation_status_filter,
    platform_filter,
    start_date,
    end_date,
    rentals
  }: {
    page?: number;
    limit?: number;
    search?: string;
    include_ids?: ValueType[] | null;
    exclude_ids?: ValueType[] | null;
    sort_field?: string;
    sort_direction?: "asc" | "desc";
    from?: string;
    to?: string;
    status?: string | number | null;
    custom_filter_field?: string;
    custom_filter_value?: ValueType;
    read_status_filter?: number | null;
    transform?: (data: any) => any;
    conversation_status_filter?: any;
    platform_filter?: any;
    start_date?: string;
    end_date?: string;
    rentals?: any;
  }) => {
    setError(null);
    setLoading(true);

    if (sort_field) setCurrentSortField(sort_field);
    if (sort_direction) setCurrentSortDirection(sort_direction);
    if (limit) setCurrentNbPerPage(limit);
    if (from) setCurrentFrom(from);
    if (to) setCurrentTo(to);
    if (status) setCurrentStatus(status);
    if (read_status_filter) setCurrentReadStatus(read_status_filter);
    if (custom_filter_field) setCurrentFilterCustomField(custom_filter_field);
    if (custom_filter_value) setCurrentFilterCustomValue(custom_filter_value);
    if (conversation_status_filter)
      setCurrentFilterCustomValue(conversation_status_filter);
    if (platform_filter) setCurrentFilterCustomValue(platform_filter);
    if (start_date) setCurrentFilterCustomValue(start_date);
    if (end_date) setCurrentFilterCustomValue(end_date);
    if(rentals) setCurrentRentalsId(rentals);

    let params: any = {
      page,
      limit,
      search: search && search !== "" ? search : undefined,
      include_ids: getCleanIncludeOrExcludeIds(include_ids),
      exclude_ids: getCleanIncludeOrExcludeIds(exclude_ids),
      sort_field,
      sort_direction,
      from,
      to,
      status,
      read_status_filter: read_status_filter,
      status_filter_conversation: conversation_status_filter,
      platform_filter: platform_filter,
      start_date: start_date,
      end_date: end_date,
      rentals: rentals
    };

    if (custom_filter_field && custom_filter_value !== "all")
      params[custom_filter_field] = custom_filter_value;

    const response = await get(URL, { params });

    let nextData = [];

    if (response?.data?.success) {
      if (options?.combine) {
        if (options?.reverseData) {
          const reversed =
            response.data.result[resultDataPropertyName].reverse();

          if (page === 1) nextData = [...reversed];
          else nextData = [...reversed, ...data];
        } else {
          if (page === 1)
            nextData = [...response.data.result[resultDataPropertyName]];
          else
            nextData = [
              ...data,
              ...response.data.result[resultDataPropertyName],
            ];
        }
      } else {
        nextData = response.data.result[resultDataPropertyName];
      }

      setTotal(response.data.result.total);
      setMessagesReadCount(response.data.result.messages_read_count);
      setLastPage(response.data.result.last_page);

      const nextOtherMetas: { key: string; value: string }[] = [];
      options?.otherMetas?.forEach((meta) => {
        nextOtherMetas.push({
          key: meta,
          value: response.data.result[meta],
        });
      });
      setCurrentOtherMetas(nextOtherMetas);
    } else {
      //setError(response.response?.data?.message ?? response.message);
      setError("An error occurred while fetching data.");


      if (!options?.combine) {
        nextData = [];
      }

      setTotal(0);
      setLastPage(1);
    }

    if (typeof options?.transform === "function")
      nextData = options?.transform(nextData);

    setData(nextData);

    setLoading(false);
  };

  const handlePaginationBack = () => {
    let next = currentPage - 1;
    if (next < 1) next = 1;

    fetch({
      page: next,
      limit: currentNbPerPage,
      search: currentSearch,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      read_status_filter: currentReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });

    setCurrentPage(next);
  };

  const handlePaginationNext = () => {
    let next = currentPage + 1;
    if (lastPage && next > lastPage) next = lastPage;

    fetch({
      page: next,
      limit: currentNbPerPage,
      search: currentSearch,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      read_status_filter: currentReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
    setCurrentPage(next);
  };

  const handleChangeNbPerPage = (value: ValueType) => {
    const nextNbPerPage = Number(value);
    const nextPage = 1;

    setCurrentNbPerPage(nextNbPerPage);
    setCurrentPage(nextPage);

    fetch({
      page: nextPage,
      limit: nextNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      read_status_filter: currentReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
  };

  const debouncedSearch = React.useRef(
    debounce(
      async (
        text,
        nbPerPage,
        rentalsId,
        sortField,
        sortDirection,
        status,
        read_status_filter,
        filterCustomField,
        filterCustomValue
      ) => {
        const nextPage = 1;

        setCurrentPage(nextPage);
        fetch({
          page: nextPage,
          limit: nbPerPage,
          search: text,
          include_ids: rentalsId,
          sort_field: sortField,
          sort_direction: sortDirection,
          status,
          read_status_filter,
          custom_filter_field: filterCustomField,
          custom_filter_value: filterCustomValue,
        });
      },
      300
    )
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChangeSearch = (value: string) => {
    setCurrentSearch(value);
    
    debouncedSearch(
      value,
      currentNbPerPage,
      currentRentalsId,
      currentSortField,
      currentSortDirection,
      currentStatus,
      currentReadStatus,
      currentFilterCustomField,
      currentFilterCustomValue
    );
  };

  const handleFilterRentalsIds = (values: ValueType[]) => {
    setCurrentPage(1);
    setCurrentRentalsId(values);

    fetch({
      page: 1,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: values,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      read_status_filter: currentReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
  };

  const handleFilterStatus = (
    value: string | number | null,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    const nextPage = 1;
    const nextStatus = value;
    const nextSortField = sortField;
    const nextSortDirection = sortDirection;

    setCurrentPage(nextPage);
    if (nextSortField !== undefined) {
      setCurrentSortField(nextSortField!);
    }
    if (nextSortDirection !== undefined) {
      setCurrentSortDirection(nextSortDirection!);
    }
    setCurrentStatus(nextStatus);

    fetch({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: nextSortField,
      sort_direction: nextSortDirection,
      status: nextStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
  };

  const handleFilterReadStatus = (value: number | null) => {
    const nextPage = 1;
    const nextReadStatus = value;

    setCurrentPage(nextPage);
    setCurrentReadStatus(nextReadStatus);

    fetch({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      read_status_filter: nextReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
  };

  const handleFilterMessage = (value: any | null) => {
    const nextPage = 1;

    fetch({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
      conversation_status_filter: value.status,
      platform_filter: value.plaformIds,
      start_date: value.startDateTime,
      end_date: value.endDateTime,
      rentals: value.rentals,
    });
  };

  const handleFilterCustom = (field: string, value: ValueType) => {
    const nextPage = 1;
    const nextFilterCustomField = field;
    const nextFilterCustomValue = value;

    setCurrentFilterCustomField(nextFilterCustomField);
    setCurrentFilterCustomValue(nextFilterCustomValue);
    console.log("field", nextFilterCustomValue);

    fetch({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: currentSortField,
      sort_direction: currentSortDirection,
      status: currentStatus,
      custom_filter_field: nextFilterCustomField,
      custom_filter_value: nextFilterCustomValue,
    });
  };

  const handleChangeSort = (column: string) => {
    const nextDirection =
      column === currentSortField && currentSortDirection === "asc"
        ? "desc"
        : "asc";

    setCurrentPage(1);
    setCurrentSortField(column);
    setCurrentSortDirection(nextDirection);

    fetch({
      page: 1,
      limit: currentNbPerPage,
      search: currentSearch,
      include_ids: currentRentalsId,
      sort_field: column,
      sort_direction: nextDirection,
      status: currentStatus,
      read_status_filter: currentReadStatus,
      custom_filter_field: currentFilterCustomField,
      custom_filter_value: currentFilterCustomValue,
    });
  };

  // TODO: Manage currentRentalsId
  const hasFilter = () => {
    return (
      currentReadStatus !== null ||
      currentSearch !== "" ||
      currentStatus !== null
    );
  };

  return {
    error,
    loading,
    total,
    messagesReadCount,
    lastPage,
    currentPage,
    currentNbPerPage,
    currentSearch,
    currentRentalsId,
    currentSortField,
    currentSortDirection,
    currentFilterCustomField,
    currentFilterCustomValue,
    data,
    setData,
    fetch,
    handlePaginationBack,
    handlePaginationNext,
    handleChangeNbPerPage,
    handleChangeSearch,
    handleFilterRentalsIds,
    handleChangeSort,
    handleFilterStatus,
    setCurrentRentalsId,
    currentOtherMetas,
    handleFilterReadStatus,
    handleFilterMessage,
    hasFilter,
    handleFilterCustom,
    setIsFiltered,
    isFiltered
  };
}
