import React from "react";
import { useTranslation } from "react-i18next";
import MoneyIcon from "../../assets/icons/money.svg?react";
import PlusIconWhite from "../../assets/icons/plus-white.svg?react";
import { Button } from "../../components/Common/Button/Button";

export const PaymentAccountListNoData: React.FC<{
  onAddStripeAccount: () => void;
}> = ({ onAddStripeAccount }) => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
         style={{ height: "calc(100vh - 200px)" }}>
      <div className="flex flex-col items-center gap-y-3">
        <MoneyIcon className="w-20 h-20" />
        <p className="mt-2 mb-1 font-semibold text-xxl text-high-contrast">
          {t("Payments.NoData.title")}
        </p>
        <p className="text-base font-light leading-relaxed tracking-wide text-center text-low-contrast w-96">
          {t("Payments.NoData.description")}
        </p>
        <div className="flex gap-4 mt-4">
          <div>
            <Button RightIcon={PlusIconWhite} onClick={onAddStripeAccount}>
              {t("Payments.NoData.addStripeAccount")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
