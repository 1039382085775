import React, { memo, useState } from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Separator } from "../../Common/Separator/Separator";
import { Button } from "../../Common/Button/Button";
import EditWhiteIcon from "../../../assets/icons/edit-white.svg?react";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { ManagementTeamRoleBadge } from "../ManagementTeamList/ManagementTeamRoleBadge";
import { ViewMemberReSend } from "./ViewMemberReSend";

export const ViewMemberModal: React.FC<{
  isVisible: boolean;
  data:
    | {
        team: OperationalManagementTeamListItemResponse;
        member: OperationalManagementTeamMemberResponse;
      }
    | null
    | undefined;
  onRemove: (params: {
    team: OperationalManagementTeamListItemResponse;
    member: OperationalManagementTeamMemberResponse;
  }) => void;
  onEdit: (
    params:
      | {
          team: OperationalManagementTeamListItemResponse;
          member: OperationalManagementTeamMemberResponse;
        }
      | null
      | undefined
  ) => void;
  onClose: () => void;
}> = ({ isVisible, data, onRemove, onEdit, onClose }) => {
  const { t } = useTranslation();

  const isMemberAccepted = () => {
    return data?.member?.first_name !== "" && data?.member?.last_name !== "";
  };

  const getTitle = () => {
    if (isMemberAccepted())
      return `${data?.member?.first_name} ${data?.member?.last_name}`;
    else return data?.member?.email;
  };

  const handleRemove = () => {
    if (data) onRemove(data);
  };

  return (
    <RightModal isVisible={isVisible} onClose={onClose} title={getTitle()}>
      <div className="flex flex-col w-full">
        <div className="flex flex-col gap-1 flex-1 w-full overflow-y-scroll">
          {/* Informations for accepted member */}
          {isMemberAccepted() && (
            <div className="flex flex-col gap-3">
              <p className="text-sm font-semibold text-low-contrast">
                {t("Management.ViewMember.informations")}
              </p>

              <div className="flex gap-2">
                {data?.member?.photo !== undefined ? (
                  <img
                    src={data?.member?.photo}
                    className="size-24 rounded-6px border-1 border-element-border"
                  />
                ) : (
                  <div className="size-24 rounded-6px border-1 border-element-border bg-element-background" />
                )}

                <div className="flex flex-col">
                  <p className="font-semibold text-high-contrast">
                    {`${data?.member?.first_name} ${data?.member?.last_name}`}{" "}
                    <span className="text-active">
                      ({t("Management.ViewMember.active")})
                    </span>
                  </p>

                  <p className="font-light text-low-contrast">
                    {data?.member?.email} - {data?.member?.phone}
                  </p>
                  {data?.member?.address !== null &&
                    data?.member?.address !== "" && (
                      <p className="font-light text-low-contrast">
                        {`${data?.member?.address}, ${data?.member?.postal_code} ${data?.member?.city}, ${data?.member?.country?.name}`}
                      </p>
                    )}
                  <p className="font-light text-low-contrast">🇫🇷 Français</p>
                  <p className="font-light text-low-contrast">EUR (€)</p>
                </div>
              </div>
            </div>
          )}

          {/* Informations for invited member */}
          {!isMemberAccepted() && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <p className="text-sm font-semibold text-low-contrast">
                  {t("Management.ViewMember.informations")}
                </p>

                <div className="flex gap-2">
                  <p className="font-semibold text-high-contrast">
                    {`${data?.member?.email}`}{" "}
                    <span className="text-active">
                      ({t("Management.TeamList.invitSent")})
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <ViewMemberReSend
                  email={data?.member.email}
                  teamId={data?.team?.id}
                />
              </div>
            </div>
          )}

          <Separator />

          {/* Postes */}
          <div className="flex flex-col gap-3">
            <p className="text-sm font-semibold text-low-contrast">
              {t("Management.ViewMember.roles")}
            </p>

            {data?.member?.postes !== undefined &&
            data?.member?.postes?.length > 0 ? (
              <div className="flex flex-row gap-2">
                {data?.member?.postes?.map((role) => (
                  <ManagementTeamRoleBadge
                    key={role.id}
                    role={role as OperationalManagementRoleListItemResponse}
                  />
                ))}
              </div>
            ) : (
              <p className="text-high-contrast font-semibold">
                {t("Management.ViewMember.noRole")}
              </p>
            )}
          </div>

          <Separator />

          {/* Teams */}
          <div className="flex flex-col gap-3">
            {data?.member?.accepted === 1 ? (
              <p className="text-sm font-semibold text-low-contrast">
                {t("Management.ViewMember.teamsAccess", {
                  firstName: data?.member?.first_name,
                })}
              </p>
            ) : (
              <p className="text-sm font-semibold text-low-contrast">
                {t("Management.ViewMember.teams")}
              </p>
            )}

            <div className="flex flex-col gap-1">
              {data?.member?.teams?.map((team) => (
                <p className="font-semibold text-high-contrast">{team.name}</p>
              ))}
            </div>
          </div>

          <Separator />

          {/* Rentals */}
          <div className="flex flex-col gap-3">
            {data?.member?.accepted === 1 ? (
              <p className="text-sm font-semibold text-low-contrast">
                {t("Management.ViewMember.rentalsAccess", {
                  firstName: data?.member?.first_name,
                })}
              </p>
            ) : (
              <p className="text-sm font-semibold text-low-contrast">
                {t("Management.ViewMember.rentals")}
              </p>
            )}

            <div className="flex flex-col gap-2">
              {data?.member?.rentals_access !== undefined &&
              data?.member?.rentals_access?.length > 0 ? (
                data?.member?.rentals_access?.map((rental) => (
                  <div className="flex flex-row gap-2">
                    <img
                      src={rental?.cover}
                      className="w-20 h-16 object-cover shrink-0 rounded-6px border-1 bg-element-background border-element-border"
                    />

                    <div className="flex flex-col">
                      <p className="text-high-contrast underline font-semibold">
                        {rental?.name}
                      </p>

                      <p className="text-low-contrast font-light">
                        {rental?.address}
                        <br />
                        {rental?.postal_code} {rental?.city}{" "}
                        {rental?.country?.name}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-high-contrast font-semibold">
                  {t("Management.ViewMember.noRental")}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex gap-3 pt-2 mt-2 border-t-1 border-element-border">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button type="alert" onClick={handleRemove}>
            {t("Global.remove")}
          </Button>
          <Button RightIcon={EditWhiteIcon} onClick={() => onEdit(data)}>
            {t("Global.edit")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
