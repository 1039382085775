import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { Login } from "../../components/Authentication/Login/Login";
import { LoginSubmit } from "../../components/Authentication/Login/Login.type";
import { Loader } from "../../components/Common/Loader/Loader";
import { Title } from "../../components/Common/Title/Title";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import { ProgressionStepEnum } from "../../components/Progression/Progression.type";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { usePost } from "../../hooks/usePost";

export const LoginPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>();
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const { perform, data, error, loading, success } = usePost();

  const handleLogin: (value: LoginSubmit) => void = ({ email, password }) => {
    perform(`${import.meta.env.VITE_API_URL}/auth/login`, {
      email,
      password,
    });

    setEmail(email);
  };

  useEffect(() => {
    const isLoogedIn = async () => {
      const user = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
      );

      if (user.data?.success) {
        navigate(paths.DASHBOARD);
      }

      setLoadingUser(false);
    };

    isLoogedIn();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        if (data?.result?.progression_step !== ProgressionStepEnum.STEP_GIFT) {
          navigate(paths.PROGRESSION);
        } else {
          navigate(paths.CALENDAR);
        }
      }, 500);
    }
  }, [email, success]);

  return (
    <FullScreenLayout isCheckAuthEnabled={false}>
      {/* Left */}
      <div className="w-2/5 max-w-md p-2">
        <div className="flex flex-col min-h-full p-8 bg-subtle border-1 border-element-border/50 rounded-6px">
          <div className="flex-1 pb-4">
            <SuperhoteIcon />
          </div>

          <div>
            <Title>{t("Login.leftTitle")}</Title>
            <p className="mt-1 text-sm font-light text-low-contrast">
              {t("Login.leftSubTitle")}
            </p>
          </div>
        </div>
      </div>

      {/* Right */}
      {loadingUser ? (
        <div className="flex items-center justify-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div className="flex-1 px-12 py-8">
          <Login error={error} loading={loading} onLogin={handleLogin} />
        </div>
      )}
    </FullScreenLayout>
  );
};
