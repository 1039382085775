import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import FileTextIcon from "../../../../assets/icons/file-text.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RentalNoteCardSkeleton } from "./NoteCardSkeleton";

export const RentalNoteCard: React.FC = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<{
    note: string | null;
  }>({
    mode: "all",
  });
  const { rentalId } = useParams();
  const {
    loadingState: { loading },
    rental,
    onUpdateRental,
  } = useRentalPageContext();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<string | null>(null);
  useEffect(() => {
    if (rental?.details.private_notes) {
      setValue("note", rental?.details.private_notes);
    }
  }, [rental?.details.private_notes]);

  const handleNoteEdit = async () => {
    setError(null);
    setValidationLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL.UPDATE_PRIVATE_NOTE
      }`,
      {
        private_notes: getValues("note"),
      }
    );

    if (res.data.success) {
      if (rental) {
        onUpdateRental({
            ...rental,
            details: {
                ...rental.details,
                private_notes: getValues("note") ?? "",
            },
        });
      }
      setData(getValues("note"));
      setEditMode(false);
    } else {
      setError(res.data.message);
    }
    setValidationLoading(false);
  };

  const noteRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...restNoteRegister } = register("note", {
    required: {
      value: false,
      message: t("Global.Errors.requiredField", {
        fieldName: t("Rental.Infos.Note.title"),
      }),
    },
  });

  const handleCancel = () => {
    setEditMode(false);
    setValue("note", data);
  };

  useEffect(() => {
    if (editMode) {
      if (noteRef.current) {
        noteRef.current.style.height = noteRef.current.scrollHeight + "px";
      }
    }
  }, [editMode, noteRef.current]);

  if (loading) return <RentalNoteCardSkeleton />;

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Rental.Infos.Note.title")}
      hasBody={editMode || Boolean(rental?.details.private_notes)}
      loading={validationLoading}
      button={{
        Icon: editMode
          ? CheckIcon
          : Boolean(getValues("note"))
          ? EditIcon
          : PlusIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode
          ? t("Global.record")
          : Boolean(getValues("note"))
          ? t("Global.edit")
          : t("Global.add"),
        onClick: editMode
          ? handleSubmit(handleNoteEdit)
          : () => setEditMode(true),
        disabled: editMode && (!isValid || validationLoading),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
    >
      {editMode ? (
        <div className="flex flex-col gap-2">
          <p className="text-low-contrast">
            {t("Rental.Infos.Note.noteFieldLabel")}
          </p>
          <textarea
            ref={(e) => {
              ref(e);
              noteRef.current = e;
            }}
            className="w-full p-2 rounded resize-y text-hight-contrast border-1 border-element-border focus:outline-none h-max"
            {...restNoteRegister}
          ></textarea>
          <ErrorMessage errorType="FORM">{errors.note?.message}</ErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      ) : (
        <p className="text-low-contrast">{rental?.details.private_notes}</p>
      )}
    </Card>
  );
};
