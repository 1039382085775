import React, { useEffect, useState } from "react";
import { TableListItemProps } from "../Common/TableList/TableList.type";
import { useTranslation } from "react-i18next";
import { NotificationListItemResponse } from "../../types/GETTypes";
import { post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { getPlatformIcon } from "../../helpers/platformHelper";
import { Loader } from "../Common/Loader/Loader";
import { Switch } from "../Common/Switch/Switch";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { getLanguageFlag } from "../../helpers/languageHelper";
import { Button } from "../Common/Button/Button";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import CopyIcon from "../../assets/icons/copy.svg?react";
import TrashIcon from "../../assets/icons/trash-active.svg?react";
import { AutoMessageLanguageEnum } from "../AutoMessage/AutoMessage.type";

export const AutoMessageLine: React.FC<TableListItemProps> = ({
  data,
  onClick,
  onClickAction = () => {},
}) => {
  const { t } = useTranslation();
  const messageAuto: NotificationListItemResponse = data;

  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (messageAuto?.enabled === true) setEnabled(true);
    else setEnabled(false);
  }, [messageAuto?.enabled]);

  const opacity = messageAuto?.enabled ? "" : "opacity-64";

  const disableMessageAuto = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_DISABLE}`,
      { notification_id: messageAuto?.id }
    );

    if (res.data?.success) {
      setEnabled(false);
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const enableMessageAuto = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_ENABLE}`,
      { notification_id: messageAuto?.id }
    );

    if (res.data?.success) {
      setEnabled(true);
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleToggle = () => {
    if (enabled) disableMessageAuto();
    else enableMessageAuto();
  };

  const getRentalsStr = () => {
    if (messageAuto?.rentals === null) return t("AutoMessageList.AllRentals");
    else if (messageAuto?.rentals?.length === 0)
      return t("AutoMessageList.noRentals");
    else if (messageAuto?.rentals?.length === 1)
      return messageAuto?.rentals[0].name;
    else
      return `${messageAuto?.rentals[0].name} + ${t(
        "AutoMessageList.otherRentals",
        { count: messageAuto?.rentals?.length - 1 }
      )}`;
  };

  const getTriggerLabel = () => {
    switch (messageAuto?.trigger) {
      case "new_reservation":
        return t("AutoMessageList.Trigger.newReservation");
      case "cancel_reservation":
        return t("AutoMessageList.Trigger.cancelReservation");
      case "reservation_refunded":
        return t("AutoMessageList.Trigger.reservationRefunded");
      case "checkin":
        return t("AutoMessageList.Trigger.checkin");
      case "first_night_stay":
        return t("AutoMessageList.Trigger.firstNightStay");
      case "guest_page_viewed":
        return t("AutoMessageList.Trigger.guestPageViewed");
      case "guest_form_fulfilled":
        return t("AutoMessageList.Trigger.guestFormViewed");
      case "guest_review_done":
        return t("AutoMessageList.Trigger.guestReviewDone");
      case "temp_high":
        return t("AutoMessageList.Trigger.tempHigh");
      case "temp_low":
        return t("AutoMessageList.Trigger.tempLow");
      case "co2_high":
        return t("AutoMessageList.Trigger.co2High");
      case "co2_low":
        return t("AutoMessageList.Trigger.co2Low");
      case "sound_high":
        return t("AutoMessageList.Trigger.soundHigh");
      case "contract_viewed":
        return t("AutoMessageList.Trigger.contractViewed");
      case "contract_signed":
        return t("AutoMessageList.Trigger.contractSigned");
      case "caution_viewed":
        return t("AutoMessageList.Trigger.cautionViewed");
      case "caution_paid":
        return t("AutoMessageList.Trigger.cautionPaid");
      case "payment_link_viewed":
        return t("AutoMessageList.Trigger.paymentLinkViewed");
      case "payment_link_paid":
        return t("AutoMessageList.Trigger.paymentLinkPaid");
      case "checkout":
        return t("AutoMessageList.Trigger.checkout");
    }
  };

  const getDelayLabel = () => {
    switch (messageAuto?.delay) {
      case "immediately":
        return t("AutoMessageList.Delay.immediately");
      case "minus_5_minutes":
        return t("AutoMessageList.Delay.minus5Minutes");
      case "minus_30_minutes":
        return t("AutoMessageList.Delay.minus30Minutes");
      case "minus_1_hour":
        return t("AutoMessageList.Delay.minus1Hour");
      case "minus_3_hours":
        return t("AutoMessageList.Delay.minus3Hours");
      case "minus_5_hours":
        return t("AutoMessageList.Delay.minus5Hours");
      case "minus_12_hours":
        return t("AutoMessageList.Delay.minus12Hours");

      case "minus_1_day":
        return t("AutoMessageList.Delay.minus1Day");
      case "minus_2_days":
        return t("AutoMessageList.Delay.minus2Days");
      case "minus_3_days":
        return t("AutoMessageList.Delay.minus3Days");
      case "minus_5_days":
        return t("AutoMessageList.Delay.minus5Days");
      case "minus_7_days":
        return t("AutoMessageList.Delay.minus7Days");
      case "minus_10_days":
        return t("AutoMessageList.Delay.minus10Days");
      case "minus_20_days":
        return t("AutoMessageList.Delay.minus20Days");
      case "minus_30_days":
        return t("AutoMessageList.Delay.minus30Days");
      case "minus_60_days":
        return t("AutoMessageList.Delay.minus60Days");

      case "plus_5_minutes":
        return t("AutoMessageList.Delay.plus5Minutes");
      case "plus_30_minutes":
        return t("AutoMessageList.Delay.plus30Minutes");
      case "plus_1_hour":
        return t("AutoMessageList.Delay.plus1Hour");
      case "plus_3_hours":
        return t("AutoMessageList.Delay.plus3Hours");
      case "plus_5_hours":
        return t("AutoMessageList.Delay.plus5Hours");
      case "plus_12_hours":
        return t("AutoMessageList.Delay.plus12Hours");

      case "plus_1_day":
        return t("AutoMessageList.Delay.plus1Day");
      case "plus_2_days":
        return t("AutoMessageList.Delay.plus2Days");
      case "plus_3_days":
        return t("AutoMessageList.Delay.plus3Days");
      case "plus_5_days":
        return t("AutoMessageList.Delay.plus5Days");
      case "plus_7_days":
        return t("AutoMessageList.Delay.plus7Days");
      case "plus_10_days":
        return t("AutoMessageList.Delay.plus10Days");
      case "plus_20_days":
        return t("AutoMessageList.Delay.plus20Days");
      case "plus_30_days":
        return t("AutoMessageList.Delay.plus30Days");
      case "plus_60_days":
        return t("AutoMessageList.Delay.plus60Days");
    }
  };

  const getChannelName = (value: "SMS" | "email" | "platform" | null) => {
    if (value?.toLowerCase() === "email") return t("AutoMessageList.email");
    else if (value?.toLowerCase() === "platform")
      return t("AutoMessageList.platform");
    else if (value?.toLowerCase() === "sms")
      return t("AutoMessageList.platform");
  };

  const getCanalAirbnbNode = () => {
    if (messageAuto?.channels?.airbnb) {
      return (
        <div className="flex gap-1 items-center font-light text-low-contrast">
          {getPlatformIcon({ id: 1, logo: "", name: "Airbnb.com" })}
          <span>{getChannelName(messageAuto?.channels?.airbnb)}</span>
        </div>
      );
    }
  };

  const getCanalBookingNode = () => {
    if (messageAuto?.channels?.booking) {
      return (
        <div className="flex gap-1 items-center font-light text-low-contrast">
          {getPlatformIcon({ id: 1, logo: "", name: "Booking.com" })}
          <span>{getChannelName(messageAuto?.channels?.booking)}</span>
        </div>
      );
    }
  };

  const getCanalDirectNode = () => {
    if (messageAuto?.channels?.direct) {
      return (
        <div className="flex gap-1 items-center font-light text-low-contrast">
          {getPlatformIcon({ id: 1, logo: "", name: "direct" })}
          <span>{getChannelName(messageAuto?.channels?.direct)}</span>
        </div>
      );
    }
  };

  return (
    <tr className="hover:bg-element-background/40">
      <td className="border-t-1 border-element-border text-sm px-2 py-2 cursor-pointer">
        <div className="flex flex-col gap-1">
          {loading ? (
            <Loader />
          ) : (
            <Switch value={enabled} onClick={handleToggle} />
          )}
        </div>
      </td>

      <td
        className="border-t-1 border-element-border text-sm px-2 py-2 cursor-pointer"
        onClick={() => onClick(messageAuto.id)}
      >
        <div className="flex flex-col gap-1">
          <span className={`font-semibold text-high-contrast ${opacity}`}>
            {messageAuto?.name}
          </span>

          <div className="flex gap-1">
            {messageAuto?.contents?.map((content) => {
              return getLanguageFlag(
                content.language_id === AutoMessageLanguageEnum.french
                  ? "FR"
                  : ""
              );
            })}
            {messageAuto?.contents?.map((content) => {
              return getLanguageFlag(
                content.language_id === AutoMessageLanguageEnum.english
                  ? "EN"
                  : ""
              );
            })}
            {messageAuto?.contents?.map((content) => {
              return getLanguageFlag(
                content.language_id === AutoMessageLanguageEnum.german
                  ? "DE"
                  : ""
              );
            })}
            {messageAuto?.contents?.map((content) => {
              return getLanguageFlag(
                content.language_id === AutoMessageLanguageEnum.portugish
                  ? "PT"
                  : ""
              );
            })}
            {messageAuto?.contents?.map((content) => {
              return getLanguageFlag(
                content.language_id === AutoMessageLanguageEnum.spanish
                  ? "ES"
                  : ""
              );
            })}
          </div>

          <ErrorMessage errorType="FORM">{error}</ErrorMessage>
        </div>
      </td>

      <td
        className="border-t-1 border-element-border text-sm px-2 py-2 cursor-pointer"
        onClick={() => onClick(messageAuto.id)}
      >
        <div className="flex flex-col gap-1">
          <span className={`font-light text-low-contrast ${opacity}`}>
            {getDelayLabel()}
          </span>

          <span className={`font-light text-low-contrast ${opacity} text-base`}>
            {getTriggerLabel()}
          </span>
        </div>
      </td>

      <td
        className="border-t-1 border-element-border text-sm px-2 py-2 cursor-pointer"
        onClick={() => onClick(messageAuto.id)}
      >
        <span className={`font-light text-low-contrast ${opacity}`}>
          {getRentalsStr()}
        </span>
      </td>

      <td
        className="border-t-1 border-element-border cursor-pointer"
        onClick={() => onClick(messageAuto.id)}
      >
        <div className="flex flex-col py-1">
          {getCanalAirbnbNode()}
          {getCanalBookingNode()}
          {getCanalDirectNode()}
        </div>
      </td>

      <td className="border-t-1 border-element-border w-32 cursor-pointer">
        <div className="space-x-2 py-1 flex items-center">
          <div>
            <Button
              type="secondary"
              onClick={() => onClick(messageAuto.id)}
              size="small"
            >
              {t("AutoMessageList.edit")}
            </Button>
          </div>

          <div className="pr-2">
            <Button
              type="secondary"
              onClick={(value: any) => onClickAction(messageAuto.id, value)}
              size="small"
              RightIcon={DotsVerticalIcon}
              dropItems={[
                {
                  Icon: CopyIcon,
                  value: "duplicate",
                  label: t("AutoMessageList.duplicate"),
                },
                {
                  Icon: TrashIcon,
                  value: "delete",
                  label: t("AutoMessageList.delete"),
                  isRed: true,
                },
              ]}
            ></Button>
          </div>
        </div>
      </td>
    </tr>
  );
};
