import moment from "moment";
import React, { useState } from "react";
import { CalendarLayout } from "../../components/Layout/CalendarLayout/CalendarLayout";

import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import { CalendarReservationModal } from "../../components/Calendar/CalendarReservationModal";
import { CalendarListItem } from "../../components/Calendar/ListItem";
import { CalendarListItemSkeleton } from "../../components/Calendar/ListItemSkeleton";
import { Button } from "../../components/Common/Button/Button";
import {
  CalendarInputValue,
  CalendarInputValueRange,
} from "../../components/Common/CalendarInput/CalendarInput.type";
import { DateFilter } from "../../components/Common/DateFilter/DateFilter";
import { useHandleChangeDates } from "../../components/Common/DateFilter/DateFilter.hooks";
import { DateRangePreset } from "../../components/Common/DateFilter/DateFilter.type";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import { ReservationStatusEnum } from "../../enums/GETenums";
import { ValueType } from "../../types/commonTypes";
import { CalendarReservationResponse } from "../../types/GETTypes";
import {
  useClickDayGroup,
  useClickReservationCalendarListPage,
  useInitCalendarListPage,
  useNextPeriod,
  usePreviousPeriod,
  useReservationsCalendarListPage,
} from "./CalendarListPage.hooks";
import { CalendarDay } from "./CalendarListPage.type";

export const CalendarListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");
  const [days, setDays] = useState<CalendarDay | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const [reservationVisible, setReservationVisible] = useState<boolean>(false);
  const [currentReservation, setCurrentReservation] = useState<
    CalendarReservationResponse | undefined
  >();

  const [openedDayGroups, setOpenedDayGroups] = useState<{
    [key: string]: boolean;
  }>({});

  useInitCalendarListPage(
    dates as CalendarInputValueRange,
    nbRangeDate,
    setOpenedDayGroups
  );

  const hotUpdateReservationStatus = ({
    reservationId,
    status,
  }: {
    reservationId: ValueType | undefined;
    rentalId: ValueType | undefined;
    status: ReservationStatusEnum;
  }) => {
    // Update reservation in modal
    if (currentReservation) {
      setCurrentReservation({
        ...currentReservation,
        status_reservation: status,
      });
    }

    // Remove reservation in List Calendar
    if (days) {
      const nextDays = JSON.parse(JSON.stringify(days));

      Object.keys(nextDays).forEach((key) => {
        nextDays[key].checkins = (nextDays[key].checkins ?? []).filter(
          (item: any) => item.id !== reservationId
        );

        nextDays[key].checkouts = (nextDays[key].checkouts ?? []).filter(
          (item: any) => item.id !== reservationId
        );
      });

      setDays(nextDays);
    }
  };

  return (
    <>
      <CalendarReservationModal
        isVisible={reservationVisible}
        reservation={currentReservation}
        needFetchReservation={true}
        onChangeStatusResa={hotUpdateReservationStatus}
        onClose={() => setReservationVisible(false)}
      />

      {/* TODO : isCheckinPrice in API */}
      <CalendarLayout
        hasRentals={
          days
            ? Object.values(days).some(
                (entry) =>
                  (entry.checkins && entry.checkins.length > 0) ||
                  (entry.checkouts && entry.checkouts.length > 0)
              )
            : false
        }
        navbarActiveItem="list"
        isCheckinPrice={false}
        displayBy="none"
      >
        <div className="flex flex-col flex-1 gap-3">
          <ErrorMessage>{error}</ErrorMessage>

          <div className="flex items-center justify-between mt-6">
            <div className="flex">
              <DateFilter
                initParams={{
                  dateRangePreset: dateRangePreset,
                  dates: dates as CalendarInputValueRange,
                  onChangeDates: (dates: CalendarInputValueRange) =>
                    setDates(dates),
                  onDatesChanged: () =>
                    useHandleChangeDates(
                      dates as CalendarInputValueRange,
                      (nbRangeDate: number) => setNbRangeDate(nbRangeDate),
                      (days: CalendarDay | undefined) => setDays(days),
                      (message: string | undefined) => setError(message),
                      () => {
                        setLoading(true);
                        setError(undefined);
                      },
                      () => setLoading(false)
                    ),
                }}
                datesState={[dates, setDates]}
                dateRangePresetState={[dateRangePreset, setDateRangePreset]}
              />
            </div>

            {dateRangePreset !== "all" ? (
              <div className="flex items-center gap-3">
                <div>
                  <Button
                    LeftIcon={ArrowLeftIcon}
                    type="secondary"
                    onClick={() =>
                      usePreviousPeriod([dates, setDates], dateRangePreset)
                    }
                  >
                    {t("Calendar.previousPeriod")}
                  </Button>
                </div>
                <div>
                  <Button
                    RightIcon={ArrowRightIcon}
                    type="secondary"
                    onClick={() =>
                      useNextPeriod([dates, setDates], dateRangePreset)
                    }
                  >
                    {t("Calendar.nextPeriod")}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>

          {/* TODO Améliorer si vraiment pas de données du tout */}

          {!loading && days && Object.values(days).length === 0 ? (
            <div className="flex-1 mt-4">
              <NoRentalRentalList />
            </div>
          ) : null}

          {loading || (days && Object.values(days).length > 0) ? (
            <>
              {loading
                ? Array.from({ length: nbRangeDate }).map((v, index) => (
                    <CalendarListItemSkeleton key={index} />
                  ))
                : null}

              {!loading
                ? Array.from({ length: nbRangeDate }).map((value, index) => {
                    let date: Date | undefined = undefined;
                    if (Array.isArray(dates) && dates.length > 0) {
                      date = moment(dates[0]).add(index, "days").toDate();
                      return (
                        <div key={index}>
                          <CalendarListItem
                            date={date}
                            checkins={
                              useReservationsCalendarListPage(date, days)
                                ?.checkins ?? []
                            }
                            checkouts={
                              useReservationsCalendarListPage(date, days)
                                ?.checkouts ?? []
                            }
                            onClick={(
                              reservation: CalendarReservationResponse
                            ) =>
                              useClickReservationCalendarListPage(
                                reservation,
                                setCurrentReservation,
                                setReservationVisible
                              )
                            }
                            onClickGroup={(which: string, value: boolean) =>
                              useClickDayGroup(which, value, [
                                openedDayGroups,
                                setOpenedDayGroups,
                              ])
                            }
                          />
                        </div>
                      );
                    } else return null;
                  })
                : null}
            </>
          ) : null}
        </div>
      </CalendarLayout>
    </>
  );
};
