import React from "react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import { useTranslation } from "react-i18next";
import {NoPaymentReservationProps} from "./PaymentReservationList.type";

export const NoPaymentReservation: React.FC<NoPaymentReservationProps> = ({title, description}) => {
    const { t } = useTranslation();

    return (
        <div
            className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
            style={{ height: "calc(100vh - 200px)" }}
        >
            <div className="flex flex-col items-center">
                <MoneyIcon  width={56} height={56} />

                <p className="my-4 font-semibold text-xl text-high-contrast">
                  {title}
                </p>

                <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
                    {description}
                </p>
            </div>
        </div>
    );
};
