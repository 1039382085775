import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page from "./components/Layout/PageLayout/Page";
import paths from "./constants/paths";
import "./i18n";
import "./index.css";
import { AddRentalPage } from "./pages/AddRental/AddRentalPage";
import { AddReservationPage } from "./pages/AddReservation/AddReservationPage";
import { AnalyticsPage } from "./pages/Analytics/AnalyticsPage";
import { AnalyticsEvaluationsPage } from "./pages/AnalyticsEvalutations/AnalyticsEvaluationsPage";
import { AnalyticsSummaryPage } from "./pages/AnalyticsSummary/AnalyticsSummaryPage";
import { AutoMessageListPage } from "./pages/AutoMessageList/AutoMessageListPage";
import { BookingPage } from "./pages/Booking/BookingPage";
import { BookingContractPage } from "./pages/BookingContract/BookingContractPage";
import { BookingInvoicePage } from "./pages/BookingInvoice/BookingInvoicePage";
import { BookingMessagePage } from "./pages/BookingMessage/BookingMessagePage";
import { BookingResourceListPage } from "./pages/BookingResourceList/BookingResourceListPage";
import { BookingTaskListPage } from "./pages/BookingTaskList/BookingTaskListPage";
import { CalendarPage } from "./pages/Calendar/CalendarPage";
import { CalendarListPage } from "./pages/CalendarList/CalendarListPage";
import { CalendarMonthPage } from "./pages/CalendarMonth/CalendarMonthPage";
import { CalendarYearPage } from "./pages/CalendarYear/CalendarYearPage";
import { DashboardPage } from "./pages/Dashboard/DashboardPage";
import { ErrorPage } from "./pages/Error/ErrorPage";
import { ForgotPasswordPage } from "./pages/ForgotPassword/ForgotPasswordPage";
import GuestPageUser from "./pages/GuestPageUser/GuestPageUser";
import { GuestsPage } from "./pages/Guests/GuestsPage";
import { HelpPage } from "./pages/Help/HelpPage";
import { LoginPage } from "./pages/Login/LoginPage";
import { LogoutPage } from "./pages/Logout/LogoutPage";
import { ManagementActivityListPage } from "./pages/ManagementActivityList/ManagementActivityListPage";
import { ManagementAutomationListPage } from "./pages/ManagementAutomationList/ManagementAutomationListPage";
import { ManagementOwnerListPage } from "./pages/ManagementOwnerList/ManagementOwnerListPage";
import { ManagementPaymentListPage } from "./pages/ManagementPaymentList/ManagementPaymentListPage";
import { ManagementRoleListPage } from "./pages/ManagementRoleList/ManagementRoleListPage";
import { ManagementTaskListPage } from "./pages/ManagementTaskList/ManagementTaskListPage";
import { ManagementTeamListPage } from "./pages/ManagementTeamList/ManagementTeamListPage";
import { MessageListPage } from "./pages/MessageList/MessageListPage";
import { PaymentAccountListPage } from "./pages/PaymentAccountList/PaymentAccountListPage";
import { PaymentActivityListPage } from "./pages/PaymentActivityList/PaymentActivityListPage";
import { PaymentDepositPage } from "./pages/PaymentDeposit/PaymentDepositPage";
import { PaymentDepositListPage } from "./pages/PaymentDepositList/PaymentDepositListPage";
import { PaymentExtrasListPage } from "./pages/PaymentExtraList/PaymentExtrasListPage";
import { PaymentInvoiceListPage } from "./pages/PaymentInvoiceList/PaymentInvoiceListPage";
import { PaymentReservationPage } from "./pages/PaymentReservation/PaymentReservationPage";
import { PaymentReservationListPage } from "./pages/PaymentReservationList/PaymentReservationListPage";
import { PlatFormPage } from "./pages/PlatForms/PlatFormPage";
import { ProgressionPage } from "./pages/Progression/ProgressionPage";
import { ProgressionRentalsRulesPage } from "./pages/ProgressionRentalsRules/ProgressionRentalsRulesPage";
import { RegisterPage } from "./pages/Register/RegisterPage";
import { RegisterMemberPage } from "./pages/RegisterMember/RegisterMemberPage";
import { RegisterMemberAcceptedPage } from "./pages/RegisterMemberAccepted/RegisterMemberAcceptedPage";
import { RentalListPage } from "./pages/RentalList/RentalListPage";
import { RentalPage } from "./pages/RentalPage/RentalPage";
import { RentalPageProvider } from "./pages/RentalPage/RentalPageContext";
import { ReservationListPage } from "./pages/ReservationList/ReservationListPage";
import { ResetPasswordPage } from "./pages/ResetPassword/ResetPasswordPage";
import { SettingSubscriptionPage } from "./pages/SettingSubscription/SettingSubscriptionPage";
import { SettingDetailsFacturationPage } from "./pages/SettingSubscriptionFactureDetails/SettingDetailsFacturationPage";
import { SettingsPage } from "./pages/Settings/SettingsPage";
import { SettingsActivitiesPage } from "./pages/SettingsActivities/SettingsActivitiesPage";
import { SettingsNotificationsPage } from "./pages/SettingsNotifications/SettingsNotificationsPage";
import { ShortcodeListPage } from "./pages/ShortcodeList/ShortcodeListPage";
import { SubscriptionPage } from "./pages/Subscription/SubscriptionPage";
import reportWebVitals from "./reportWebVitals";
import frLocale from "./res/locale/fr";
import Root from "./routes/RootRouter";
moment.locale("fr", frLocale);

const router = createBrowserRouter([
  {
    path: paths.ROOT,
    element: <Root />,
    errorElement: (
      <Page title={"Tab.errorTitle"}>
        <ErrorPage />
      </Page>
    ),
  },
  {
    path: paths.NOT_FOUND,
    element: (
      <Page title={"Tab.errorTitle"}>
        <ErrorPage />
      </Page>
    ),
  },
  {
    path: paths.REGISTER,
    element: (
      <Page title={"Tab.registerTitle"}>
        <RegisterPage />
      </Page>
    ),
  },
  {
    path: `${paths.REGISTER_MEMBER}/:invitId`,
    element: (
      <Page title={"Tab.registerMemberTitle"}>
        <RegisterMemberPage />
      </Page>
    ),
  },
  {
    path: `${paths.REGISTER_MEMBER_ACCEPTED}/:invitId`,
    element: (
      <Page title={"Tab.registerMemberTitle"}>
        <RegisterMemberAcceptedPage />
      </Page>
    ),
  },
  {
    path: paths.SUBSCRIPTION,
    element: (
      <Page title={"Tab.subscriptionTitle"}>
        <SubscriptionPage />
      </Page>
    ),
  },
  {
    path: paths.LOGIN,
    element: (
      <Page title={"Tab.loginTitle"}>
        <LoginPage />
      </Page>
    ),
  },
  {
    path: paths.LOGOUT,
    element: <LogoutPage />,
  },
  {
    path: paths.FORGOT_PASSWORD,
    element: (
      <Page title={"Tab.forgotPasswordTitle"}>
        <ForgotPasswordPage />
      </Page>
    ),
  },
  {
    path: paths.RESET_PASSWORD,
    element: (
      <Page title={"Tab.forgotPasswordTitle"}>
        <ResetPasswordPage />
      </Page>
    ),
  },
  {
    path: paths.DASHBOARD,
    element: (
      <Page title={"Tab.dashboardTitle"}>
        <DashboardPage />
      </Page>
    ),
  },
  {
    path: paths.RESERVATIONS,
    element: (
      <Page title={"Tab.reservationTitle"}>
        <ReservationListPage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS_ADD}`,
    element: (
      <Page title={"Tab.addReservationTitle"}>
        <AddReservationPage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId`,
    element: (
      <Page title={"Tab.reservationTitle"}>
        <BookingPage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_MESSAGES}`,
    element: (
      <Page title={"Tab.bookingMessageTitle"}>
        <BookingMessagePage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_PAYMENTS}`,
    element: (
      <Page title={"Tab.bookingInvoiceTitle"}>
        <BookingInvoicePage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_CONTRACTS}`,
    element: (
      <Page title={"Tab.bookingContractTitle"}>
        <BookingContractPage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_CHECKLISTS}`,
    element: (
      <Page title={"Tab.bookingResourceTitle"}>
        <BookingResourceListPage />
      </Page>
    ),
  },
  {
    path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_ACTIVITY}`,
    element: (
      <Page title={"Tab.bookingTaskTitle"}>
        <BookingTaskListPage />
      </Page>
    ),
  },
  // {
  //   path: `${paths.RESERVATIONS}/:reservationId/${paths.RESERVATION_RESOURCES}`,
  //   element: <BookingResourceListPage />,
  // },
  {
    path: paths.MESSAGES,
    element: (
      <Page title={"Tab.messageListTitle"}>
        <MessageListPage />
      </Page>
    ),
  },
  {
    path: paths.AUTO_MESSAGES,
    element: (
      <Page title={"Tab.autoMessageTitle"}>
        <AutoMessageListPage />
      </Page>
    ),
  },
  {
    path: paths.SHORTCODES,
    element: (
      <Page title={"Tab.shotCodeTitle"}>
        <ShortcodeListPage />
      </Page>
    ),
  },
  {
    path: paths.ANALYTICS,
    element: (
      <Page title={"Tab.analyticsTitle"}>
        <AnalyticsPage />
      </Page>
    ),
  },
  {
    path: paths.ANALYTICS_EVALUATIONS,
    element: (
      <Page title={"Tab.analyticsEvaluationsTitle"}>
        <AnalyticsEvaluationsPage />
      </Page>
    ),
  },
  {
    path: paths.ANALYTICS_SUMMARY,
    element: (
      <Page title={"Tab.analyticsSummaryTitle"}>
        <AnalyticsSummaryPage />
      </Page>
    ),
  },
  {
    path: paths.RENTALS_ADD,
    element: (
      <Page title={"Tab.addRentalTitle"}>
        <AddRentalPage />
      </Page>
    ),
  },

  {
    path: paths.RENTALS,
    element: (
      <Page title={"Tab.rentaListTitle"}>
        <RentalListPage />
      </Page>
    ),
    children: [
      {
        index: true,
        element: (
          <Page title={"Tab.rentaListTitle"}>
            <RentalListPage />
          </Page>
        ),
      },
      {
        path: paths.RENTALS_GROUP_VIEW,
        element: (
          <Page title={"Tab.rentaListTitle"}>
            <RentalListPage />
          </Page>
        ),
      },
      {
        path: paths.RENTALS_LIST_VIEW,
        element: (
          <Page title={"Tab.rentaListTitle"}>
            <RentalListPage />
          </Page>
        ),
      },
    ],
  },
  {
    path: `${paths.RENTALS}/:rentalId`,
    element: (
      <Page title={"Tab.rentaTitle"}>
        <RentalPageProvider>
          <RentalPage />
        </RentalPageProvider>
      </Page>
    ),
  },
  {
    path: paths.PAYMENT_RESERVATION,
    element: (
      <Page title={"Tab.paymentReservationTitle"}>
        <PaymentReservationPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS,
    element: (
      <Page title={"Tab.paymentReservationTitle"}>
        <PaymentReservationListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_RESERVATIONS,
    element: (
      <Page title={"Tab.paymentReservationTitle"}>
        <PaymentReservationListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_DEPOSITS,
    element: (
      <Page title={"Tab.paymentDepositTitle"}>
        <PaymentDepositListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_EXTRAS,
    element: (
      <Page title={"Tab.paymentExtraTitle"}>
        <PaymentExtrasListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_ACCOUNTS,
    element: (
      <Page title={"Tab.paymentAccountTitle"}>
        <PaymentAccountListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_INVOICES,
    element: (
      <Page title={"Tab.paymentInvoiceTitle"}>
        <PaymentInvoiceListPage />,
      </Page>
    ),
  },
  {
    path: paths.PAYMENTS_ACTIVITIES,
    element: (
      <Page title={"Tab.paymentActivityTitle"}>
        <PaymentActivityListPage />,
      </Page>
    ),
  },
  {
    path: paths.DEPOSIT_PRE_AUTHORIZATION,
    element: (
      <Page title={"Tab.depositPreAuthorizationTitle"}>
        <PaymentDepositPage />,
      </Page>
    ),
  },
  {
    path: paths.CALENDAR_LIST,
    element: (
      <Page title={"Tab.calendarTitle"}>
        <CalendarListPage />,
      </Page>
    ),
  },
  {
    path: paths.CALENDAR_MONTH,
    element: (
      <Page title={"Tab.calendarMonthTitle"}>
        <CalendarMonthPage />,
      </Page>
    ),
  },
  {
    path: paths.CALENDAR_YEAR,
    element: (
      <Page title={"Tab.calendarYearTitle"}>
        <CalendarYearPage />,
      </Page>
    ),
  },
  {
    path: paths.CALENDAR,
    element: (
      <Page title={"Tab.calendarTitle"}>
        <CalendarPage />,
      </Page>
    ),
  },
  {
    path: paths.PROGRESSION,
    element: (
      <Page title={"Tab.progressionTitle"}>
        <ProgressionPage />,
      </Page>
    ),
  },
  {
    path: paths.PROGRESSION_RENTALS_RULES,
    element: (
      <Page title={"Tab.progressionRentalRuleTitle"}>
        <ProgressionRentalsRulesPage />,
      </Page>
    ),
  },
  {
    path: paths.GUESTS,
    element: (
      <Page title={"Tab.guestTitle"}>
        <GuestsPage />,
      </Page>
    ),
  },
  {
    path: paths.PLATFORMS,
    element: (
      <Page title={"Tab.platformTitle"}>
        <PlatFormPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT,
    element: (
      <Page title={"Tab.managementTitle"}>
        <ManagementTeamListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_TASKS,
    element: (
      <Page title={"Tab.managementTasksTitle"}>
        <ManagementTaskListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_TEAMS,
    element: (
      <Page title={"Tab.managementTitle"}>
        <ManagementTeamListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_ROLES,
    element: (
      <Page title={"Tab.managementTitle"}>
        <ManagementRoleListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_OWNERS,
    element: (
      <Page title={"Tab.managementTitle"}>
        <ManagementOwnerListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_AUTOMATIONS,
    element: (
      <Page title={"Tab.managementAutomationsTitle"}>
        <ManagementAutomationListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_PAYMENTS,
    element: (
      <Page title={"Tab.managementPaymentsTitle"}>
        <ManagementPaymentListPage />,
      </Page>
    ),
  },
  {
    path: paths.MANAGEMENT_ACTIVITY,
    element: (
      <Page title={"Tab.managementActivityTitle"}>
        <ManagementActivityListPage />,
      </Page>
    ),
  },
  {
    path: paths.SETTINGS,
    element: (
      <Page title={"Tab.settingTitle"}>
        <SettingsPage />,
      </Page>
    ),
  },
  {
    path: paths.SETTINGS_PROFIL,
    element: (
      <Page title={"Tab.settingTitle"}>
        <SettingsPage />,
      </Page>
    ),
  },

  {
    path: paths.YOUR_SUBSCRIPTION,
    element: (
      <Page title={"Tab.settingSubscriptionTitle"}>
        <SettingSubscriptionPage />,
      </Page>
    ),
  },
  {
    path: paths.DETAILS_FACTURATION,
    element: (
      <Page title={"Tab.settingFacturationTitle"}>
        <SettingDetailsFacturationPage />,
      </Page>
    ),
  },
  {
    path: paths.SETTINGS_NOTIFICATIONS,
    element: (
      <Page title={"Tab.settingNotificationTitle"}>
        <SettingsNotificationsPage />,
      </Page>
    ),
  },
  {
    path: paths.SETTINGS_ACTIVITIES,
    element: (
      <Page title={"Tab.settingActivitiesTitle"}>
        <SettingsActivitiesPage />,
      </Page>
    ),
  },
  {
    path: paths.SETTINGS_PROFIL,
    element: (
      <Page title={"Tab.settingsProfil"}>
        <SettingsPage />,
      </Page>
    ),
  },
  {
    path: paths.HELP,
    element: (
      <Page title={"Tab.helpTitle"}>
        <HelpPage />,
      </Page>
    ),
  },
  {
    path: paths.GUEST_PAGE,
    element: (
      <Page title={"Tab.guestPageTitle"}>
        <GuestPageUser />
      </Page>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <MantineProvider>
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
