import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import LinkIcon from "../../../../assets/icons/link.svg?react";
import MessageIcon from "../../../../assets/icons/message-white.svg?react";
import paths from "../../../../constants/paths";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import {
  BookingLayoutNavbarActiveItemType,
  BookingLayoutNavbarProps,
} from "./BookingLayoutNavbar.type";

export const BookingLayoutNavbar: React.FC<BookingLayoutNavbarProps> = ({
  reservation,
  activeItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { reservationId } = useParams();

  const handleClick = (value: ValueType) => {
    const casted = value as BookingLayoutNavbarActiveItemType;

    switch (casted) {
      case "details":
        navigate(`${paths.RESERVATIONS}/${reservationId}`);
        return;
      case "messages":
        navigate(
          `${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_MESSAGES}`
        );
        return;
      case "payments":
        navigate(
          `${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_PAYMENTS}`
        );
        return;
      case "contracts":
        navigate(
          `${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_CONTRACTS}`
        );
        return;
      case "checklists":
        navigate(
          `${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_CHECKLISTS}`
        );
        return;
      case "activity":
        navigate(
          `${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_ACTIVITY}`
        );
        return;
    }
  };

  const guestPageLink = reservation
    ? `/reservation/${reservation.id}/guest-page/${reservation.guest_page_link}`
    : "";

  return (
    <div className="flex justify-between">
      <GroupButton
        items={[
          {
            value: "details",
            label: t("Booking.details"),
            isActive: activeItem === "details",
          },
          {
            value: "messages",
            label: t("Booking.messages"),
            ...(reservation?.messages_unread_count > 0
              ? { badge: reservation?.messages_unread_count?.toString() }
              : {}),
            isActive: activeItem === "messages",
          },
          {
            value: "payments",
            label: t("Booking.paymentsAndInvoices"),
            isActive: activeItem === "payments",
          },
          {
            value: "contracts",
            label: t("Booking.contracts"),
            isActive: activeItem === "contracts",
          },
          {
            value: "checklists",
            label: t("Booking.checklists"),
            isActive: activeItem === "checklists",
          },
          {
            value: "activity",
            label: t("Booking.activity"),
            isActive: activeItem === "activity",
          },
        ]}
        onClick={handleClick}
      />
      <div className="flex gap-3">
        <div>
          <Link to={guestPageLink!} target="_blank">
            <Button
              type="secondary"
              RightIcon={LinkIcon}
              disabled={!guestPageLink}
            >
              {t("Booking.guestpageLink")}
            </Button>
          </Link>
          {}
        </div>

        {activeItem !== "messages" && (
          <Link
            to={`${paths.RESERVATIONS}/${reservationId}/${paths.RESERVATION_MESSAGES}`}
          >
            <Button RightIcon={MessageIcon}>{t("Booking.guestContact")}</Button>
          </Link>
        )}
      </div>
    </div>
  );
};
