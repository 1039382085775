import React, { useState } from "react";
import {
  ADD_RENTAL_STEPS,
  AddRentalInfosType,
  AddRentalPhotosType,
  AddRentalRoomType,
  AddRentalTarificationType,
  AddRentalType,
} from "../../components/AddRental/AddRental.type";
import { AddRentalCloseModal } from "../../components/AddRental/CloseModal";
import { AddRentalDone } from "../../components/AddRental/Done/AddRentalDone";
import { AddRentalFacilities } from "../../components/AddRental/Facilities/AddRentalFacilities";
import { AddRentalInfos } from "../../components/AddRental/Infos/AddRentalInfos";
import { AddRentalPhotos } from "../../components/AddRental/Photos/AddRentalPhotos";
import { AddRentalRooms } from "../../components/AddRental/Rooms/AddRentalRooms";
import {
  AddRentalRules,
  AddRentalRulesType,
} from "../../components/AddRental/Rules/AddRentalRules";
import { AddRentalSummary } from "../../components/AddRental/Summary/AddRentalSummary";
import { AddRentalTarification } from "../../components/AddRental/Tarification/AddRentalTarification";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import { ValueType } from "../../types/commonTypes";
import {ConfirmAddress} from "../../components/AddRental/ConfirmAddress/ConfirmAddress";

export const AddRentalPage: React.FC<{}> = () => {
  const [closeVisible, setCloseVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ADD_RENTAL_STEPS>(
    ADD_RENTAL_STEPS.INFORMATIONS
  );
  const [currentRental, setCurrentRental] = useState<
    AddRentalType | undefined
  >();

  const handleCurrentRentalTarification = (
    values: AddRentalTarificationType
  ) => {
    if (currentRental) {
      const updated = {
        tarification: currentRental.hasOwnProperty("tarification")
          ? { ...currentRental["tarification"], ...values }
          : { ...values },
      };
      setCurrentRental({ ...currentRental, ...updated });
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePreviousAddressStep = () => {
    setCurrentStep(currentStep - 0.5);
  };

  const handleNextStepInfos = (values: AddRentalInfosType) => {
    setCurrentRental({ ...currentRental, infos: values });
    setCurrentStep(currentStep + 0.5);
  };

  const handleNextStepConfirmAddress = (values: AddRentalInfosType) => {
    setCurrentRental({ ...currentRental, infos: { ...currentRental?.infos, ...values } });
    setCurrentStep(currentStep + 0.5);
  };

  const handleNextStepRooms = (rooms: AddRentalRoomType[]) => {
    console.log('[handleNextStepRooms]');
    if (!currentRental?.facilities) {
      setCurrentRental({
        ...currentRental,
        facilities: ["base_equipments", "linens", "kitchen_base", "cutlery"],
        rooms,
      });
    } else {
      setCurrentRental({ ...currentRental, rooms });
    }
    setCurrentStep(currentStep + 1);
  };

  const handleNextStepPhotos = (values: AddRentalPhotosType) => {
    setCurrentRental({ ...currentRental, photos: values });
    setCurrentStep(currentStep + 1);
  };

  const handleNextStepFacilities = (facilities: string[]) => {
    setCurrentRental({ ...currentRental, facilities });
    setCurrentStep(currentStep + 1);
  };

  const handleNextStepRules = (values: AddRentalRulesType) => {
    setCurrentRental({ ...currentRental, rules: values });
    setCurrentStep(currentStep + 1);
  };

  const handleNextStepTarification = (
    values: AddRentalTarificationType,
    rental_id: ValueType,
    isActive: boolean
  ) => {
    setCurrentRental({
      ...currentRental,
      tarification: values,
      rental_id,
      isActive,
    });
    setCurrentStep(currentStep + 1);
  };

  const onClose = () => {
    setCloseVisible(true);
  };
  const [displayOtherInputAddress, setDisplayOtherInputAddress] = useState<boolean>(false);

  return (
    <>
      <AddRentalCloseModal
        isVisible={closeVisible}
        onClose={() => setCloseVisible(false)}
      />

      <FullScreenLayout onClose={onClose}>
        {/* Left */}
        <div className="w-2/5 max-w-md p-2">
          <AddRentalSummary rental={currentRental} currentStep={currentStep} />
        </div>

        {/* Right */}
        <div className="flex-1 pt-8 ps-28">
          {currentStep === ADD_RENTAL_STEPS.INFORMATIONS && (
            <AddRentalInfos
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepInfos}
              displayOtherInputAddress={displayOtherInputAddress}
              setDisplayOtherInputAddress={setDisplayOtherInputAddress}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.CONFIRM_ADDRESS && (
              <ConfirmAddress
                  rental={currentRental}
                  onCancel={onClose}
                  onNext={handleNextStepConfirmAddress}
                  onBack={handlePreviousAddressStep}
              />
          )}

          {currentStep === ADD_RENTAL_STEPS.ROOMS && (
            <AddRentalRooms
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepRooms}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.FACILITIES && (
            <AddRentalFacilities
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepFacilities}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.PHOTOS && (
            <AddRentalPhotos
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepPhotos}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.RULES && (
            <AddRentalRules
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepRules}
              onBack={handlePreviousStep}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.TARIFICATION && (
            <AddRentalTarification
              rental={currentRental}
              onCancel={onClose}
              onNext={handleNextStepTarification}
              onBack={handlePreviousStep}
              onChange={handleCurrentRentalTarification}
            />
          )}

          {currentStep === ADD_RENTAL_STEPS.DONE && (
            <AddRentalDone
              rental={currentRental}
              onDisable={() =>
                setCurrentRental({ ...currentRental, isActive: false })
              }
              onEnable={() =>
                setCurrentRental({ ...currentRental, isActive: true })
              }
            />
          )}
        </div>
      </FullScreenLayout>
    </>
  );
};
