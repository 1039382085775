import moment from "moment";

export const getMonday = (d: Date) => {
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getDayName = (date: Date) => {
  if (moment(date).format("ddd") === "Mon") return "Lun";
  else if (moment(date).format("ddd") === "Tue") return "Mar";
  else if (moment(date).format("ddd") === "Wed") return "Mer";
  else if (moment(date).format("ddd") === "Thu") return "Jeu";
  else if (moment(date).format("ddd") === "Fri") return "Ven";
  else if (moment(date).format("ddd") === "Sat") return "Sam";
  else if (moment(date).format("ddd") === "Sun") return "Dim";

  return moment(date).format("ddd");
};

export const getMonthName = (date: Date) => {
  if (moment(date).format("MMMM") === "January") return "Janvier";
  if (moment(date).format("MMMM") === "February") return "Février";
  if (moment(date).format("MMMM") === "March") return "Mars";
  if (moment(date).format("MMMM") === "April") return "Avril";
  if (moment(date).format("MMMM") === "May") return "Mai";
  if (moment(date).format("MMMM") === "June") return "Juin";
  if (moment(date).format("MMMM") === "July") return "Juillet";
  if (moment(date).format("MMMM") === "August") return "Août";
  if (moment(date).format("MMMM") === "September") return "Septembre";
  if (moment(date).format("MMMM") === "October") return "Octobre";
  if (moment(date).format("MMMM") === "November") return "Novembre";
  if (moment(date).format("MMMM") === "December") return "Décembre";

  return moment(date).format("MMMM");
};

export const convertInputDateToLocaleFormat = (
  date: string,
  format: string
) => {
  let nextDate: Date = moment(date).toDate();
  return moment(nextDate).format(format);
};

export const isValidStringDate = (date: string): boolean =>
  moment(date, "DD/MM/YYYY", true).isValid();

export const isValidHour = (hour: string | null | undefined): boolean =>
  moment(hour, "HH:mm", true).isValid();

export const getDatesBetweenDates = (
  start: string,
  end: string,
  includeEdges: boolean = true
): string[] => {
  const startDate = includeEdges
    ? moment(start, "YYYY-MM-DD")
    : moment(start, "YYYY-MM-DD").add(1, "day");
  const endDate = includeEdges
    ? moment(end, "YYYY-MM-DD")
    : moment(end, "YYYY-MM-DD").subtract(1, "day");

  const dates: string[] = [];

  let currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate)) {
    // Format currentDate to YYYY-MM-DD and push to dates array
    dates.push(currentDate.format("YYYY-MM-DD"));

    // Move to the next day
    currentDate.add(1, "day");
  }

  return dates;
};

export const calculateDaysUntilCheckin = (checkinDateString: string) => {
  const today = moment().startOf("day");
  const checkinDate = moment(checkinDateString).startOf("day");

  return checkinDate.diff(today, "days");
};

export const formatTime = (time: string | null) => {
  if (!time) return "";

  return moment(time, ["H:mm", "HH:mm"]).format("HH:mm");
};
