import React, {useEffect, useState} from "react";
import { BookingTaskPageProps } from "./BookingTaskListPage.type";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { Button } from "../../components/Common/Button/Button";
import { useTranslation } from "react-i18next";

import PlusIcon from "../../assets/icons/plus-circle-white.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import CheckIcon from "../../assets/icons/check-circle-green.svg?react";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import TrashIcon from "../../assets/icons/trash-active.svg?react";
import RentalImageSrc from "../../assets/images/rental1.webp";
import UserAvatar from "../../assets/images/user-avatar.svg?react";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";
import {useParams} from "react-router-dom";
import {ReservationResponse} from "../../types/GETTypes";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {fetchOneReservation} from "../../helpers/reservationHelper";

export const BookingTaskListPage: React.FC<BookingTaskPageProps> = () => {
  const { t } = useTranslation();


    const { reservationId } = useParams();
    const [reservation, setReservation] = useState<ReservationResponse>();
    const tableMessages = useTablePage(
        `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
        "messages",
        { combine: true, reverseData: true }
    );


    const handleReservationSuccess = (value: ReservationResponse) => {
        setReservation(value);
    };

    useEffect(() => {
        if (reservationId) {
            fetchOneReservation(
                reservationId,
                handleReservationSuccess,
                ()=> {}
            );

            tableMessages.fetch({
                limit: 10,
                sort_field: "captured_at",
                sort_direction: "desc",
            });

        }
    }, [reservationId]);

  const getActions = () => {
    return (
      <div>
          {/*
        <Button RightIcon={PlusIcon} onClick={() => {}}>
          {t("Booking.addTask")}
        </Button>
        */}
      </div>
    );
  };

  return (
      <BookingLayout navbarActiveItem="activity" rightChildren={getActions()} reservation={reservation}>
        <div className="flex flex-col flex-1 gap-3">
          <InProgressPlaceholder/>
        </div>
      </BookingLayout>
  );
};
