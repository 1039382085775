import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import { Button } from "../../Common/Button/Button";
import { ReservationSmallDetailsProps } from "./ReservationSmallDetails.type";
import { ReservationSmallDetailsSkeleton } from "./ReservationSmallDetailsSkeleton";

import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import BuildingIcon from "../../../assets/icons/building.svg?react";
import CashIcon from "../../../assets/icons/cash.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import FlagIcon from "../../../assets/icons/flag.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import MoonIcon from "../../../assets/icons/moon.svg?react";
import PhoneIcon from "../../../assets/icons/phone.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import { post } from "../../../helpers/APIHelper";
import { getPlatformIcon } from "../../../helpers/platformHelper";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { useEditPrivateNote } from "../../../hooks/useEditPrivateNotes";
import { getLanguageString } from "../../../helpers/languageHelper";

export const ReservationSmallDetails: React.FC<
  ReservationSmallDetailsProps
> = ({ loading, reservation, removeToHeight = 120 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const editPrivateNote = useEditPrivateNote(reservation);

  const handleClickDetails = () => {
    navigate(`${paths.RESERVATIONS}/${reservation?.id}`);
  };

  if (loading)
    return <ReservationSmallDetailsSkeleton removeToHeight={removeToHeight} />;

  return (
    <div
      className="flex flex-col px-3 py-4 overflow-y-scroll border-l-0 min-w-80 w-80 border-r-1 border-element-border"
      style={{
        height: `calc(100vh - ${removeToHeight}px)`,
      }}
    >
      <div className="flex flex-row items-center space-x-2">
        <div className="relative w-8 h-8 rounded-full border-1 border-element-border">
          <img src={reservation?.guest?.photo!} className="rounded-full" />

          <div className="absolute top-4 left-4">
            {getPlatformIcon(reservation?.platform!, "small")}
          </div>
        </div>

        <div className="flex-1">
          <p className="text-sm font-semibold text-high-contrast">
            {reservation?.guest?.first_name} {reservation?.guest?.last_name}
          </p>

          {reservation?.guest?.city &&
          reservation?.guest?.city.trim() !== "" ? (
            <p className="text-low-contrast text-[12px] font-light">
              {reservation?.guest?.city}, {reservation?.guest?.country}
            </p>
          ) : (
            <p className="text-low-contrast text-[12px] font-light">
              {reservation?.guest?.country ?? "-"}
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center pb-2 mt-3 space-x-2 border-b-1 border-element-border-light">
        <MailIcon className="flex-shrink-0 w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          {reservation?.guest?.email ?? "-"}
        </p>
      </div>

      <div className="flex flex-row items-center pb-2 mt-3 space-x-2 border-b-1 border-element-border-light">
        <PhoneIcon className="flex-shrink-0 w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          {reservation?.guest?.phone ?? "-"}
        </p>
      </div>

      <div className="flex flex-row items-center pb-2 mt-3 space-x-2 border-b-1 border-element-border-light">
        <FlagIcon className="flex-shrink-0 w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          {getLanguageString(reservation?.guest?.languages ?? "[]")}
        </p>
      </div>

      <div className="flex w-full mt-3">
        <Button onClick={handleClickDetails} type="secondary">
          <span className="text-sm">{t("MessageList.viewBookingDetails")}</span>
        </Button>
      </div>

      <div className="flex flex-row gap-2 mt-3 text-sm font-semibold border-b-1 border-element-border-light">
        <div className="flex-1 py-3 border-r-1 border-element-border-light">
          <UsersIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
          {t("Global.adults", { count: reservation?.adults_count ?? 0 })}
        </div>
        <div className="flex-1 py-3">
          <EmojiHappyIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
          {t("Global.children", { count: reservation?.children_count ?? 0 })}
        </div>
      </div>

      <div className="flex flex-row items-center pb-2 mt-3 space-x-2 border-b-1 border-element-border-light">
        <BuildingIcon className="flex-shrink-0 w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          {reservation?.rental?.details.informations.name}
        </p>
      </div>

      <div className="flex flex-row gap-2 text-sm font-semibold border-b-1 border-element-border-light">
        <div className="flex-1 py-3 border-r-1 border-element-border-light">
          <MoonIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
          {t("Global.nights", {
            count: moment(reservation?.checkout).diff(
              moment(reservation?.checkin),
              "days"
            ),
          }).toLowerCase()}
        </div>
        <div className="flex-1 py-3">
          <CashIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
          {reservation?.total_guest_price} €
        </div>
      </div>

      <div className="flex flex-row pb-2 mt-3 space-x-2 border-b-1 border-element-border-light">
        <ArrowRightIcon className="w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          <span className="font-light">{t("MessageList.checkinAt")}</span>{" "}
          {moment(reservation?.checkin).format("DD/MM/YYYY")}{" "}
          <span className="font-light">{t("MessageList.at")}</span>{" "}
          {reservation?.time_in}
        </p>
      </div>

      <div className="flex flex-row pb-2 mt-3 mb-3 space-x-2 border-b-1 border-element-border-light">
        <ArrowLeftIcon className="w-5" />
        <p className="text-sm font-semibold text-high-contrast">
          <span className="font-light">{t("MessageList.checkoutAt")}</span>{" "}
          {moment(reservation?.checkout).format("DD/MM/YYYY")}{" "}
          <span className="font-light">{t("MessageList.at")}</span>{" "}
          {reservation?.time_out}
        </p>
      </div>

      <TextAreaInput
        label={t("MessageList.remarks")}
        rows={4}
        value={editPrivateNote.privateNotes}
        onTextChange={editPrivateNote.handleChange}
        onSave={editPrivateNote.handleSave}
        onCancel={editPrivateNote.handleCancel}
        error={editPrivateNote.error}
        disabled={editPrivateNote.loading}
      />

      <div className="mt-3">
        <TextAreaInput
          label={t("MessageList.otherInformations")}
          rows={4}
          value={reservation?.platform_notes!}
          onTextChange={() => {}}
          disabled={true}
        />
      </div>
    </div>
  );
};
