import React from "react";
import { cn } from "../../../helpers/classHelper";
import { SimpleRadioProps } from "./SimpleRadio.type";

export const SimpleRadio: React.FC<SimpleRadioProps> = ({
  classNames,
  Icon,
  label,
  value,
  disabled = false,
  onClick,
}) => {
  const getBorder = () => {
    if (!value) {
      return `
      border-1
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
    }

    return "";
  };

  const getBackground = () => {
    if (value) {
      return "bg-button-primary-default-top";
    } else if (disabled) {
      return "bg-element-background-disabled";
    } else {
      return "bg-white";
    }
  };

  const opacity = disabled ? "opacity-64" : "";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <div
      className={cn(`flex items-center gap-2`, classNames?.parent)}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        className={cn(
          `flex w-4 h-4 items-center justify-center rounded-full ${getBorder()} ${getBackground()} ${opacity} ${cursor}`,
          classNames?.radio
        )}
        onClick={handleClick}
      >
        {value ? (
          <div
            className={cn(
              "w-1 h-1 bg-white rounded-full",
              classNames?.contentRadio
            )}
          ></div>
        ) : null}
      </div>

      {Icon ? (
        <div>
          <Icon />
        </div>
      ) : null}

      {label ? (
        <p
          className={cn(
            `${cursor} text-high-contrast select-none tracking-wide ${classNames?.style}`,
            classNames?.label
          )}
          onClick={handleClick}
        >
          {label}
        </p>
      ) : null}
    </div>
  );
};
