import React from "react";
import {PictureWithDeleteProps} from "./PictureWithDelete.type";

import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/file.svg?react";
import {isImageFile, isVideoFile} from "../../../helpers/fileHelper";

export const PictureWithDelete: React.FC<PictureWithDeleteProps> = ({
  src,
  isCover = false,
  disabled,
  onRemove,
  mime_type,
  name,
  type = "image",
}) => {
  const getCursor = () => {
    if (disabled) return "cursor-not-allowed";
    else return "cursor-pointer";
  };

  const getFileType = (fileUrl: string) => {
    if (!fileUrl) return "unknown";

    const imageTypes = /.(jpeg|jpg|gif|png|webp)$/i;
    const pdfType = /\.pdf$/i;

    if (imageTypes.test(fileUrl)) {
      return "image";
    } else {
      return "pdf";
    }
  };

  const returnVideoOrImage = () => {
    if (mime_type && src) {
      if (isImageFile(mime_type)) {
        return (
          <img
            src={src}
            className="h-24 w-24 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"
          />
        );
      } else if (isVideoFile(mime_type)) {
        return (
          <div className="relative h-24 w-24">
            <video
              src={src}
              className="h-full w-full object-cover rounded-lg"
              muted
              preload="metadata"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.752 11.168l-7.197-4.132A1 1 0 006 7.868v8.264a1 1 0 001.555.832l7.197-4.132a1 1 0 000-1.664z"
                />
              </svg>
            </div>
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <div className={`group relative h-24 ${type === "file" ? "w-40" : "w-24"}`}>
      {src && type !== "file" ? (
        returnVideoOrImage()
      ) : type === "file" ? (
        <div
          className={`flex flex-col items-center justify-center ${
            type === "file" ? "w-40" : "w-24"
          } h-24 bg-element-background rounded-6px border-1 border-element-border gap-2 ${getCursor()} hover:bg-element-background-active`}
        >
          <div className="flex flew-row gap-2">
            <PhotoIcon className="w-10 h-10" />
            <a
              href={src ?? "#"}
              target="_blank"
              className="font-semibold text-high-contrast text-center underline"
            >
              {name?.substring(0, 10)} ...
            </a>
          </div>
        </div>
      ) : (
        <div className="h-24 w-24 border-2 border-element-border/50 rounded-6px border-dashed object-cover object-center bg-element-background" />
      )}
      {isCover && type !== "file" && (
        <span className="absolute top-2 right-2 text-xxs text-warning font-bold bg-element-background-warning rounded p-1">
          Couverture
        </span>
      )}
      {src && !disabled && (
        <div
          className={`h-6 w-6 bg-white rounded-sm border-1 border-element-border flex items-center justify-center absolute group-hover:opacity-100 opacity-0 transition-opacity duration-300 ${getCursor()}`}
          style={{right: -6, top: -6}}
          onClick={disabled ? () => {} : onRemove}
        >
          <MinusIcon width={20} height={20} />
        </div>
      )}
    </div>
  );
};
