import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import rentalCategories from "../../../res/rental_categories";
import rentalTypes from "../../../res/rental_types";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { TextInput } from "../../Common/TextInput/TextInput";
import { AddRentalInfosType, AddRentalType } from "../AddRental.type";

import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import { ValueType } from "../../../types/commonTypes";
import countries from "../../../constants/countries";
import {GoogleAddressInput} from "../../GoogleAddressInput/GoogleAddressInput";
import {Link} from "react-router-dom";
import ArrowLeft from "../../../assets/icons/arrow-left-custom.svg?react";
import {getCityAndZip} from "../../../helpers/geocodeHelper";


export const AddRentalInfos: React.FC<{
  rental: AddRentalType | undefined;
  onNext: (values: AddRentalInfosType) => void;
  onCancel: () => void;
  displayOtherInputAddress: boolean;
  setDisplayOtherInputAddress: (value: boolean) => void;
}> = ({ rental, onNext, onCancel, displayOtherInputAddress, setDisplayOtherInputAddress }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


    const franceId =
    countries.find((country) => country.code === "FR")?.value ?? 73;

  const form = useForm<AddRentalInfosType>({
    defaultValues: {
      name: "",
      rentalCategory: 1,
      rentalType: 1,
      rentingType: "ENTIRE_HOUSE",
      managementType: "OWN_PROPERTY",
      address: "",
      postal_code: "",
      city: "",
      country: franceId,
      maxGuests: 2,
      bathrooms: 1,
      maxAdults: 1,
      maxChildren: 0,
    },
  });

  useEffect(() => {

    if (rental) {
      form.setValue("name", rental?.infos?.name ?? "");
      form.setValue("rentalCategory", rental?.infos?.rentalCategory ?? 1);
      form.setValue("rentalType", rental.infos?.rentalType ?? 1);
      form.setValue("rentingType", rental.infos?.rentingType ?? "ENTIRE_HOUSE");
      form.setValue("managementType", rental.infos?.managementType ?? "OWN_PROPERTY"
      );
      form.setValue("postal_code", rental.infos?.postal_code ?? "");
      form.setValue("city", rental.infos?.city ?? "");
      form.setValue("country", rental.infos?.country ?? franceId);
      form.setValue("address", rental.infos?.address!);
      form.setValue("maxGuests", rental.infos?.maxGuests ?? 2);
      form.setValue("bathrooms", rental.infos?.bathrooms ?? 1);
      form.setValue("maxAdults", rental.infos?.maxAdults ?? 1);
      form.setValue("maxChildren", rental.infos?.maxChildren ?? 0);
      form.setValue("latitude", rental.infos?.latitude ?? 0);
      form.setValue("longitude", rental.infos?.longitude ?? 0);
      form.setValue("track_number", rental.infos?.track_number ?? "");

    }
  }, [rental]);
    const watchAddress = form.watch('address');


    const ReformatAddress =async (values) => {
        // Debug initial values
        //console.log('[ReformatAddress]');

        // Create an info object with the initial values or default values
        const info = {
            ...values,
            address: values?.address || "",
            postal_code: values?.postal_code || "",
            city: values?.city || "",
            country: values?.country || "",
            maxGuests: values?.maxGuests || 0,
            maxAdults: values?.maxAdults || 0,
            maxChildren: values?.maxChildren || 0,
            bathrooms: values?.bathrooms || 0,
            latitude: values?.latitude || 0,
            longitude: values?.longitude || 0,
            track_number: values?.track_number || ""
        };

       // console.log('info after initial assignment:', info);

        // Check if other input address display condition
        if (displayOtherInputAddress) {
            info.address = `${info.track_number} ${info.city} ${info.postal_code}`;
            const { city, zip,countryCode, latitude,longitude } = await getCityAndZip(info.address);
            info.longitude = longitude;
            info.latitude = latitude;
        } else {
            const addressGoogle = info.address;

            // Debug addressGoogle
            //console.log('addressGoogle:', typeof  addressGoogle);

            // Assuming addressGoogle has these properties
            info.country = countries.find(
                (country) => country.code === addressGoogle?.countryCode
            )?.value || info.country;

            info.city = addressGoogle?.cityName || info.city;
            info.postal_code = addressGoogle?.cityZip || info.postal_code;
            info.address = addressGoogle?.formattedAddress || info.address;
            info.longitude = addressGoogle?.longitude || info.longitude;
            info.latitude = addressGoogle?.latitude || info.latitude;
        }
        return info;
    };


    const handleNextStep = async (values: AddRentalInfosType) => {
        setLoading(true);
        setError(null);
        const copyValues = {...values};
        const newData = await ReformatAddress(copyValues);
        // @ts-ignore
        const res = await post(
            `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_INFOS}`,
            {
                name: newData.name,
                rental_category_id: newData.rentalCategory,
                rental_type_id: newData.rentalType,
                renting_type: newData.rentingType,
                management_type: newData.managementType,
                address: newData.address,
                postal_code: newData.postal_code,
                city: newData.city,
                country_code: countries.find(
                    (country) => country.value === newData.country
                )?.code,
                guests_max_capacity: newData.maxGuests,
                adults_max_capacity: newData.maxAdults,
                children_max_capacity: newData.maxChildren,
                bathrooms_count: newData.bathrooms,
                latitude: newData.latitude,
                longitude: newData.longitude
            }
        );

        if (res.data?.success) {
            onNext(newData);
        } else {
            setError(res.response.data.message);
        }

    setLoading(false);
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
        <div className="flex flex-col w-full text-sm">
            <p className="text-lg font-semibold text-high-contrast">
                {t("AddRental.Infos.title")}
            </p>

            <p className="mt-4 mb-6 font-light text-low-contrast">
                {t("AddRental.Infos.subTitle")}
            </p>

            <Controller
                control={form.control}
                name="name"
                rules={{
                    required: {value: true, message: t("AddRental.Infos.nameError")},
                }}
                render={({field: {value, onChange}}) => {
                    return (
                        <TextInput
                            label={t("AddRental.Infos.name")}
                            value={value}
                            size="large"
                            placeholder={t("AddRental.Infos.namePlaceholder")}
                            onChangeText={onChange}
                            error={form.formState.errors.name?.message}
                            required={true}
                        />
                    );
                }}
            />

            <div className="flex w-full gap-3 mt-3">
                <div className="flex-1">
                    <Controller
                        control={form.control}
                        name="rentalCategory"
                        render={({field: {value, onChange}}) => {
                            const handleChange = (next: ValueType) => {
                                onChange(next);
                                form.setValue("rentalType", null);
                            };

                            return (
                                <div className="mt-3">
                                    <InputSelect
                                        label={t("AddRental.Infos.rentalCategory")}
                                        items={rentalCategories.map((cat) => {
                                            return {value: cat.id, label: cat.fr};
                                        })}
                                        selectedValue={value}
                                        onSelect={handleChange}
                                        error={form.formState.errors.rentalCategory?.message}
                                        required={true}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>

                <div className="flex-1">
                    <Controller
                        control={form.control}
                        name="rentalType"
                        rules={{
                            required: {
                                value: true,
                                message: t("AddRental.Infos.rentalTypeError"),
                            },
                        }}
                        render={({field: {value, onChange}}) => {
                            return (
                                <div className="mt-3">
                                    <InputSelect
                                        label={t("AddRental.Infos.rentalType")}
                                        items={rentalTypes
                                            .filter(
                                                (t) =>
                                                    t.rental_category_id ===
                                                    form.watch("rentalCategory")
                                            )
                                            .map((t) => {
                                                return {value: t.id, label: t.fr};
                                            })}
                                        selectedValue={value ?? ""}
                                        onSelect={onChange}
                                        error={form.formState.errors.rentalType?.message}
                                        required={true}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
            </div>

            <div className="flex w-full gap-3 mt-3">
                <Controller
                    control={form.control}
                    name="rentingType"
                    render={({field: {value, onChange}}) => {
                        return (
                            <div className="flex-1">
                                <InputSelect
                                    label={t("AddRental.Infos.rentingType")}
                                    items={[
                                        {
                                            value: "ENTIRE_HOUSE",
                                            label: t("AddRental.Infos.RentingTypeLabel.full"),
                                        },
                                        {
                                            value: "SINGLE_ROOM",
                                            label: t("AddRental.Infos.RentingTypeLabel.room"),
                                        },
                                        {
                                            value: "SHARED_ROOM",
                                            label: t("AddRental.Infos.RentingTypeLabel.shared"),
                                        },
                                    ]}
                                    selectedValue={value}
                                    onSelect={onChange}
                                    error={form.formState.errors.rentingType?.message}
                                    required={true}
                                />
                            </div>
                        );
                    }}
                />

                <Controller
                    control={form.control}
                    name="managementType"
                    render={({field: {value, onChange}}) => {
                        return (
                            <div className="flex-1">
                                <InputSelect
                                    label={t("AddRental.Infos.managementType")}
                                    items={[
                                        {
                                            value: "OWN_PROPERTY",
                                            label: t("AddRental.Infos.ManagementTypeLabel.mine"),
                                        },
                                        {
                                            value: "CONCIERGE",
                                            label: t(
                                                "AddRental.Infos.ManagementTypeLabel.conciergerie"
                                            ),
                                        },
                                        {
                                            value: "SUBLETTING",
                                            label: t(
                                                "AddRental.Infos.ManagementTypeLabel.location"
                                            ),
                                        },
                                    ]}
                                    selectedValue={value}
                                    onSelect={onChange}
                                    error={form.formState.errors.managementType?.message}
                                    required={true}
                                />
                            </div>
                        );
                    }}
                />
            </div>

            {
                !displayOtherInputAddress && (
                    <div className="flex flex-col mt-3">

                        <Controller
                            control={form.control}
                            name="address"
                            rules={{
                                required: {
                                    value: true,
                                    message: t("AddRental.Infos.addressError"),
                                },
                            }}
                            render={({field: {value, onChange}}) => {
                                return (
                                    <div className="flex-1">
                                        <GoogleAddressInput
                                            label={t("AddRental.Infos.address")}
                                            error={form.formState.errors.address?.message}
                                            defaultValue={value}
                                            onAddressValid={(address) => {
                                                form.setValue("address", address);
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />




                    </div>
                )
            }

            {
                displayOtherInputAddress && (
                    <>
                        <div className="flex mt-3 gap-3">
                            <Controller
                                control={form.control}
                                name="track_number"
                                rules={{
                                    required: {
                                        value: displayOtherInputAddress ? true: false,
                                        message: t("AddRental.Infos.trackNumberError"),
                                    },
                                }}
                                render={({field: {value, onChange}}) => {
                                    return (
                                        <div className="flex-1">
                                            <TextInput
                                                label={t("AddRental.Infos.trackNumber")}
                                                value={value}
                                                size="large"
                                                placeholder={t("AddRental.Infos.trackNumberPlaceholder")}
                                                onChangeText={onChange}
                                                error={form.formState.errors.track_number?.message}
                                                required={ displayOtherInputAddress ? true: false}
                                            />
                                        </div>
                                    );
                                }}
                            />

                            <Controller
                                control={form.control}
                                name="country"
                                rules={{
                                    required: {
                                        value: displayOtherInputAddress ? true: false,
                                        message: t("AddRental.Infos.countryError"),
                                    },
                                }}
                                render={({field: {onChange}}) => (
                                    <div className="flex-1">
                                        <InputSelect
                                            required={true}
                                            label={t("AddRental.Infos.country")}
                                            selectedValue={form.getValues("country")}
                                            items={countries}
                                            error={form.formState.errors.country?.message}
                                            onSelect={onChange}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex mt-3 gap-3">
                            <Controller
                                control={form.control}
                                name="postal_code"
                                rules={{
                                    required: {
                                        value: displayOtherInputAddress ? true: false,
                                        message: t("AddRental.Infos.postalCodeError"),
                                    },
                                }}
                                render={({field: {value, onChange}}) => {
                                    return (
                                        <div className="flex-1">
                                            <TextInput
                                                label={t("AddRental.Infos.postalCode")}
                                                value={value}
                                                size="large"
                                                placeholder={t("AddRental.Infos.postalCodePlaceholder")}
                                                onChangeText={onChange}
                                                error={form.formState.errors.postal_code?.message}
                                                required={displayOtherInputAddress ? true: false}
                                            />
                                        </div>
                                    );
                                }}
                            />

                            <Controller
                                control={form.control}
                                name="city"
                                rules={{
                                    required: {
                                        value: displayOtherInputAddress ? true: false,
                                        message: t("AddRental.Infos.cityError"),
                                    },
                                }}
                                render={({field: {value, onChange}}) => {
                                    return (
                                        <div className="flex-1">
                                            <TextInput
                                                label={t("AddRental.Infos.city")}
                                                value={value}
                                                size="large"
                                                placeholder={t("AddRental.Infos.cityPlaceholder")}
                                                onChangeText={onChange}
                                                error={form.formState.errors.city?.message}
                                                required={displayOtherInputAddress ? true: false}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </>
                )
            }
                    <Link className="cursor-pointer mt-2"
                          onClick={() => setDisplayOtherInputAddress(!displayOtherInputAddress)}>
                        <div className="mt-2 flex flex row">
                            {displayOtherInputAddress && <ArrowLeft className="h-4 w-4 fill-[#E54D2E] mr-1"/>}
                            <p className="text-red-500">
                                {displayOtherInputAddress ? t("AddRental.Infos.useGoogleAddress") : t("AddRental.Infos.useOtherAddress")}
                            </p>
                        </div>
                    </Link>


            <div className="flex gap-3 mt-3">
            <Controller
                    control={form.control}
                    name="maxGuests"
                    render={({field: {value, onChange}}) => {
                        const handleChange = (value: string | number) => {
                            onChange(value);

                            if (form.watch("maxAdults") > Number(value)) {
                                form.setValue("maxAdults", Number(value));
                            }

                            if (form.watch("maxChildren") + 1 > Number(value)) {
                                form.setValue("maxChildren", Number(value) - 1);
                            }
                        };

                        return (
                            <NumberInput
                                label={t("AddRental.Infos.maxGuests")}
                                value={value}
                                onChangeText={handleChange}
                                error={form.formState.errors.maxGuests?.message}
                                size="large"
                                min={1}
                                required={true}
                            />
                        );
                    }}
                />

                <Controller
                    control={form.control}
                    name="bathrooms"
                    render={({field: {value, onChange}}) => {
                        return (
                            <NumberInput
                                label={t("AddRental.Infos.bathrooms")}
                                value={value}
                                onChangeText={onChange}
                                error={form.formState.errors.bathrooms?.message}
                                size="large"
                                min={0}
                                required={true}
                            />
                        );
                    }}
                />
            </div>

            <div className="flex gap-3 mt-3">
                <Controller
                    control={form.control}
                    name="maxAdults"
                    render={({field: {value, onChange}}) => {
                        return (
                            <NumberInput
                                label={t("AddRental.Infos.maxAdults")}
                                value={value}
                                onChangeText={onChange}
                                error={form.formState.errors.maxAdults?.message}
                                size="large"
                                min={1}
                                max={form.watch("maxGuests")}
                                required={true}
                            />
                        );
                    }}
                />

                <Controller
                    control={form.control}
                    name="maxChildren"
                    render={({field: {value, onChange}}) => {
                        return (
                            <NumberInput
                                label={t("AddRental.Infos.maxChildren")}
                                value={value}
                                onChangeText={onChange}
                                error={form.formState.errors.maxChildren?.message}
                                size="large"
                                min={0}
                                max={form.watch("maxGuests") - 1}
                                required={true}
                            />
                        );
                    }}
                />
            </div>

            <div className="mt-6">
                <ErrorMessage>{error}</ErrorMessage>
            </div>

            <div className="flex gap-4 pb-4 mt-8">
                <Button type="secondary" onClick={onCancel} disabled={loading}>
                    {t("Global.cancel")}
                </Button>
                <Button RightIcon={ArrowRightWhiteIcon} loading={loading}>
                    {t("AddReservation.Infos.nextStep")}
                </Button>
            </div>
        </div>
    </form>
  );
};
