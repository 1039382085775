const languages = [
    {
        label: "🇫🇷 Français",
        value: "fr",
    },
    {
        label: "🇬🇧 English",
        value: "en",
    },
    {
        label: "🇪🇸 Español",
        value: "es",
    },
    {
        label: "🇵🇹 Português",
        value: "pt",
    }


]

export default languages
