import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";

export const PlatFormPage = () => {
    const { t } = useTranslation();
  return (
    <>

      <MainLayout
        title={t("Global.platforms")}
        sidebarActiveItem="platforms"
        sidebarActiveSubItem="general"
      >
        <InProgressPlaceholder/>
      </MainLayout>
    </>
  );
};
