import React, { useEffect, useState } from "react";
import { AnalyticsEvaluationsPageProps } from "./AnalyticsEvaluationsPage.type";
import { useTranslation } from "react-i18next";
import { InputSelect } from "../../components/Common/InputSelect/InputSelect";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { Button } from "../../components/Common/Button/Button";

import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import AirBnBIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import VRBOIcon from "../../assets/icons/vrbo.svg?react";
import StarFilledIcon from "../../assets/icons/star-filled.svg?react";
import StarHalfIcon from "../../assets/icons/star-half.svg?react";
import StarEmptyIcon from "../../assets/icons/star-empty.svg?react";
import CalendarIcon from "../../assets/icons/calendar.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import resEvaluations from "../../res/evaluations";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { MultiSelectRentals } from "../../components/Common/MultiSelectRentals/MultiSelectRentals";
import { useGetRentals } from "../../hooks/useGetRentals";

export const AnalyticsEvaluationsPage: React.FC<
  AnalyticsEvaluationsPageProps
> = () => {
  const { t } = useTranslation();

  const [evaluations, setEvalutations] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentNbPerPage, setCurrentNbPerPage] = useState<number>(5);

  const { currentRentals, rentalGroups, setCurrentRentals } = useGetRentals();

  useEffect(() => {
    const min = (currentPage - 1) * currentNbPerPage;
    const max = currentPage * currentNbPerPage;

    const result = resEvaluations?.filter(
      (e, index) => index >= min && index < max
    );

    setEvalutations(result);
  }, [currentPage, currentNbPerPage]);

  const handlePageBack = () => {
    const result = currentPage - 1;

    if (result <= 1) setCurrentPage(1);
    else setCurrentPage(result);
  };

  const handlePageNext = () => {
    const next = currentPage + 1;
    const max = Math.ceil(resEvaluations.length / currentNbPerPage);

    if (next >= max) setCurrentPage(max);
    else setCurrentPage(next);
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Analytics.title")} <ChevronRightIcon />
        {t("Analytics.evaluations")}
      </p>
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="evaluations"
      >
        {/* Filter */}
        <div className="flex flex-row gap-2 mt-2">
          <GroupButton
            items={[
              {
                label: `${t("Dashboard.from")} 1 jan. ${t(
                  "Dashboard.to"
                )} 31 dec.`,
                value: 1,
                Icon: CalendarIcon,
                isPeriod: true,
              },
              {
                label: `${t("Dashboard.today")}`,
                value: 2,
              },
              {
                label: `${t("Dashboard.thisWeek")}`,
                value: 3,
              },
              {
                label: `${t("Dashboard.thisMonth")}`,
                value: 4,
              },
              {
                label: `${t("Dashboard.allTime")}`,
                value: 5,
                isActive: true,
              },
            ]}
          />

          <div className="w-52">
            <MultiSelectRentals
              selectedRentals={currentRentals}
              onSelect={(values) => setCurrentRentals(values)}
            />
          </div>
        </div>

        {/* Table */}
        <div className="mt-4">
          <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
            {/* Table Header */}
            <tr className="bg-element-background border-b-1 border-element-border">
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p>{t("Analytics.platform")}</p>
                  <ChevronDownIcon />
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.guest")}</p>
                  <ChevronDownIcon />
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.date")}</p>
                  <ChevronDownIcon />
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.rate")}</p>
                  <ChevronDownIcon />
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.publicComment")}</p>
                </div>
              </th>
            </tr>

            {evaluations.map((e) => (
              <EvaluationLine
                key={e.id}
                // @ts-ignore
                plateform={e.platform}
                fullName={e.fullName}
                date={e.date}
                rate={e.rate}
                comment={e.comment}
              />
            ))}
          </table>
        </div>

        {/* Bottom with paging */}
        <div className="flex flex-row mt-4 items-center">
          <div className="flex flex-1 flex-row items-center space-x-2">
            <p className="text-high-contrast text-sm font-semibold">
              {t("Analytics.displayUpTo")}
            </p>

            <div>
              <InputSelect
                items={[
                  { value: 5, label: t("Analytics.pagingEvaluations5") },
                  { value: 10, label: t("Analytics.pagingEvaluations10") },
                ]}
                selectedValue={currentNbPerPage}
                onSelect={(value) => setCurrentNbPerPage(Number(value))}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <Button
              type="secondary"
              LeftIcon={ArrowLeftIcon}
              disabled={currentPage <= 1}
              onClick={handlePageBack}
            ></Button>
            <Button
              type="secondary"
              onClick={() => {
                console.log("click page 1");
              }}
            >
              <span className="px-1">{currentPage}</span>
            </Button>
            <Button
              type="secondary"
              LeftIcon={ArrowRightIcon}
              disabled={
                currentPage >=
                Math.ceil(resEvaluations.length / currentNbPerPage)
              }
              onClick={handlePageNext}
            ></Button>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

type EvaluationLineProps = {
  plateform: "airbnb" | "VRBO" | "booking";
  fullName: string;
  date: string;
  rate: number;
  comment: string;
};

const EvaluationLine: React.FC<EvaluationLineProps> = ({
  plateform,
  fullName,
  date,
  rate,
  comment,
}) => {
  const getStars = () => {
    if (rate === 5) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
        </>
      );
    } else if (rate >= 4.5) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarHalfIcon />
        </>
      );
    } else if (rate >= 4) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 3.5) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarHalfIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 3) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarFilledIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 2.5) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarHalfIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 2) {
      return (
        <>
          <StarFilledIcon />
          <StarFilledIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 1.5) {
      return (
        <>
          <StarFilledIcon />
          <StarHalfIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 1) {
      return (
        <>
          <StarFilledIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else if (rate >= 0.5) {
      return (
        <>
          <StarHalfIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    } else {
      return (
        <>
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
          <StarEmptyIcon />
        </>
      );
    }
  };

  return (
    <tr>
      <td className="px-2 py-2 text-sm">
        {plateform === "airbnb" && <AirBnBIcon className="w-6 h-6" />}
        {plateform === "VRBO" && <VRBOIcon className="w-6 h-6" />}
        {plateform === "booking" && <BookingIcon className="w-6 h-6" />}
      </td>

      <td className="w-44 px-2 py-2 text-sm text-high-contrast font-semibold">
        {fullName}
      </td>

      <td className="px-2 py-2 text-sm text-low-contrast">{date}</td>

      <td className="px-2 py-2 text-sm text-low-contrast">
        <div className="flex flex-row items-center">
          {getStars()}
          <p className="ml-2">{rate}/5</p>
        </div>
      </td>

      <td className="px-2 py-2 text-sm text-low-contrast">{comment}</td>
    </tr>
  );
};
