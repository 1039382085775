import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { TextInput } from "../../Common/TextInput/TextInput";
import { RentalFiltersProps } from "./RentalFilters.type";

import PlusCircleWhiteIcon from "../../../assets/icons/plus-circle-white.svg?react";
import PlusCircleIcon from "../../../assets/icons/plus-circle.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";

export const RentalFilters: React.FC<RentalFiltersProps> = ({
  rentals,
  onAddRental,
  onAddRentalGroup,
  onSearchText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-3">
      <div className="flex-1">
        <TextInput
          size="large"
          Icon={SearchIcon}
          placeholder={t("Global.search")}
          onChangeText={onSearchText}
        />
      </div>

      {onAddRentalGroup && (
        <div>
          <Button
            onClick={() => onAddRentalGroup("create", true, undefined)}
            RightIcon={PlusCircleIcon}
            type="secondary"
          >
            {t("RentalList.addRentalGroup")}
          </Button>
        </div>
      )}

      <div>
        <Button
          disabled={rentals ? rentals.length === 0 : false}
          onClick={onAddRental}
          RightIcon={PlusCircleWhiteIcon}
        >
          {t("RentalList.addRental")}
        </Button>
      </div>

      {/* <div>
        <Button onClick={() => {}} RightIcon={PlugWhiteIcon} disabled={true}>
          {t("RentalList.connectPlatform")}
        </Button>
      </div> */}
    </div>
  );
};
