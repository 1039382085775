import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import { get, post } from "../../../helpers/APIHelper";
import { RequiredFields } from "../../../types/commonTypes";
import { GuestResponse } from "../../../types/GETTypes";
import { AutoCompleteInput } from "../../Common/AutoCompleteInput/AutoCompleteInput";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { PhoneInput } from "../../Common/PhoneInput/PhoneInput";
import { TextInput } from "../../Common/TextInput/TextInput";
import {
  AddReservationGuestType,
  AddReservationType,
} from "../AddReservation.type";
type formSchema = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  zip: string;
  city: string;
  country_id: number;
};

export const AddReservationGuest: React.FC<{
  reservation: AddReservationType;
  onCancel: () => void;
  onNext: (values: AddReservationGuestType) => void;
  onBack: () => void;
}> = ({ reservation, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [guests, setGuests] = useState<GuestResponse[]>([]);
  const [isGuestSelected, setIsGuestSelected] = useState<boolean>(false);
  const [isSearchingGuests, setIsSearchingGuest] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<formSchema>({
    mode: "all",
    defaultValues: {
      firstName: reservation?.guest?.first_name,
      lastName: reservation?.guest?.last_name,
      email: reservation?.guest?.email,
      phone: reservation?.guest?.phone,
      address: reservation?.guest?.address,
      zip: reservation?.guest?.postal_code,
      city: reservation?.guest?.city,
      country_id:
        countries.find(
          (country) => country.label === reservation?.guest?.country
        )?.value ?? 73,
    },
  });

  const requiredFields: RequiredFields<formSchema> = {
    firstName: true,
    lastName: true,
    email: true,
    phone: false,
    address: false,
    zip: false,
    city: false,
    country_id: false,
  };

  useEffect(() => {
    if (reservation) {
      if (reservation.guest?.id) {
        setIsGuestSelected(true);
      }
    }
  }, [reservation]);

  const handleNextStep = async (values: formSchema) => {
    setError(null);

    let isError = false;

    if (isError) {
      return;
    }

    setLoading(true);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RESERVATION_ADD_CHECK_GUEST}`,
      {
        rental_id: reservation.rental?.id.toString(),
        platform_id: reservation?.general?.platform_id,
        checkin: moment(reservation?.general?.checkin).format("YYYY-MM-DD"),
        checkout: moment(reservation?.general?.checkout).format("YYYY-MM-DD"),
        time_in: reservation.general?.time_in,
        time_out: reservation.general?.time_out,
        adults_count: reservation.general?.adults_count,
        children_count: reservation.general?.children_count,
        nights_count: moment(reservation.general?.checkout).diff(
          moment(reservation.general?.checkin),
          "days"
        ),
        nights_total_price: reservation?.tarification?.totalNights,
        city_tax: reservation?.tarification?.cityTaxes,
        cleaning_default: reservation?.tarification?.cleanFees,
        additional_guests_total_price:
          reservation?.tarification?.extraGuestFees,
        pet_default: reservation?.tarification?.animalsFees,
        infant_bed_default: reservation?.tarification?.babyBedFees,
        other: reservation?.tarification?.otherFees,
        reservation_total_price: reservation?.tarification?.total,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        city: values.city,
        postal_code: values.zip,
        country_code: countries.find(
          (country) => country.value === values.country_id
        )?.code,
      }
    );

    if (!res?.data?.success) {
      setError(res?.response?.data?.message);
      setLoading(false);
      return;
    }

    const result = res.data.result;

    onNext(result.guest);
  };

  const debouncedSearchGuest = React.useRef(
    debounce(async (search: string) => {
      if (search === "") {
        setIsSearchingGuest(false);
        setGuests([]);
        return;
      }

      const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.GUESTS}?search=${search}`
      );

      if (res?.data?.success) {
        setGuests(res.data?.result?.guests);
      } else {
        setError(res?.response?.data?.message);
      }

      setIsSearchingGuest(false);
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearchGuest.cancel();
    };
  }, [debouncedSearchGuest]);

  const handleSearchGuest = (text: string) => {
    if (text !== "") setIsSearchingGuest(true);

    debouncedSearchGuest(text);
  };

  const handleSelectGuest = (item: { value: string; label: string } | null) => {
    if (item) {
      debouncedSearchGuest.cancel();
      setIsSearchingGuest(false);

      setIsGuestSelected(true);
      const index = guests.findIndex((g) => g.id.toString() === item.value);
      const guest = guests[index];
      const country = countries.find((c) => c.label === guest.country);
      form.setValue("firstName", guest.first_name!);
      form.setValue("lastName", guest.last_name!);
      form.setValue("email", guest.email!);
      form.setValue("phone", guest.phone!);
      form.setValue("address", guest.address!);
      form.setValue("zip", guest.postal_code!);
      form.setValue("city", guest.city!);
      form.setValue("country_id", country?.value!);

      form.clearErrors();
    } else setIsGuestSelected(false);
  };

  const handleEditGuest = () => {
    debouncedSearchGuest.cancel();
    setIsSearchingGuest(false);

    setIsGuestSelected(false);
  };

  const handleReinitGuest = () => {
    debouncedSearchGuest.cancel();
    setIsSearchingGuest(false);

    setIsGuestSelected(false);

    form.setValue("firstName", "");
    form.setValue("lastName", "");
    form.setValue("email", "");
    form.setValue("phone", "");
    form.setValue("address", "");
    form.setValue("zip", "");
    form.setValue("city", "");
    form.setValue("country_id", 73);
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <div className="flex flex-col text-sm">
        <div className="flex justify-start pb-12">
          <div className="w-auto min-w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        <p className="text-lg font-semibold text-high-contrast">
          {t("AddReservation.Guest.title")}
        </p>

        <p className="mt-4 font-light text-low-contrast">
          {t("AddReservation.Guest.subTitle")}
        </p>

        <div className="mt-10">
          <AutoCompleteInput
            label={t("AddReservation.Guest.search")}
            initialValue={
              reservation?.guest?.first_name
                ? `${reservation?.guest?.first_name} ${reservation?.guest?.last_name}`
                : undefined
            }
            placeholder={t("AddReservation.Guest.searchPlaceholder")}
            disabled={loading}
            isSearching={isSearchingGuests}
            data={guests?.map((g) => {
              return {
                value: g.id.toString(),
                label: `${g.first_name} ${g.last_name}`,
              };
            })}
            guests={guests}
            onSearch={handleSearchGuest}
            onTrueDataChoosen={handleSelectGuest}
            onEdit={handleEditGuest}
            onReinit={handleReinitGuest}
            includeCheck={{ guestByEmail: true }}
          />

          <p className="mt-1 text-xs font-light text-low-contrast">
            {t("AddReservation.Guest.searchHint")}
          </p>
        </div>

        <div className="flex gap-4 mt-6">
          <Controller
            control={form.control}
            name="firstName"
            rules={{
              required: {
                value: requiredFields.firstName,
                message: t("AddReservation.Guest.firstNameRequired"),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.firstName}
                label={`${t("AddReservation.Guest.firstName")}`}
                placeholder={t("AddReservation.Guest.firstNamePlaceholder")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.firstName?.message}
              />
            )}
          />

          <Controller
            control={form.control}
            name="lastName"
            rules={{
              required: {
                value: requiredFields.lastName,
                message: t("AddReservation.Guest.lastNameRequired"),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.lastName}
                label={`${t("AddReservation.Guest.lastName")}`}
                placeholder={t("AddReservation.Guest.lastNamePlaceholder")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.lastName?.message}
              />
            )}
          />
        </div>

        <div className="flex gap-4 mt-6">
          <Controller
            control={form.control}
            name="email"
            rules={{
              required: {
                value: requiredFields.email,
                message: t("AddReservation.Guest.emailInfoText"),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.email}
                label={`${t("AddReservation.Guest.email")}`}
                placeholder={t("AddReservation.Guest.emailPlaceholder")}
                infoText={t("AddReservation.Guest.emailInfoText")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.email?.message}
              />
            )}
          />

          <Controller
            control={form.control}
            name="phone"
            rules={{
              required: {
                value: requiredFields.phone,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Guest.phone"),
                }),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                label={`${t("AddReservation.Guest.phone")}`}
                placeholder={t("AddReservation.Guest.phonePlaceholder")}
                value={value}
                size="large"
                onChangeText={onChange}
                error={form.formState.errors.phone?.message}
                disabled={loading || isGuestSelected}
              />
            )}
          />
        </div>

        <div className="flex gap-4 mt-6">
          <Controller
            control={form.control}
            name="address"
            rules={{
              required: {
                value: requiredFields.address,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Guest.address"),
                }),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.address}
                label={`${t("AddReservation.Guest.address")}`}
                placeholder={t("AddReservation.Guest.addressPlaceholder")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.address?.message}
              />
            )}
          />

          <Controller
            control={form.control}
            name="zip"
            rules={{
              required: {
                value: requiredFields.zip,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Guest.zip"),
                }),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.zip}
                label={`${t("AddReservation.Guest.zip")}`}
                placeholder={t("AddReservation.Guest.zipPlaceholder")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.zip?.message}
              />
            )}
          />
        </div>

        <div className="flex gap-4 mt-6">
          <Controller
            control={form.control}
            name="city"
            rules={{
              required: {
                value: requiredFields.city,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Guest.city"),
                }),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                required={requiredFields.city}
                label={`${t("AddReservation.Guest.city")}`}
                placeholder={t("AddReservation.Guest.cityPlaceholder")}
                value={value}
                onChangeText={onChange}
                disabled={loading || isGuestSelected}
                size="large"
                error={form.formState.errors.city?.message}
              />
            )}
          />

          <div className="flex-1">
            <Controller
              control={form.control}
              name="country_id"
              rules={{
                required: {
                  value: requiredFields.country_id,
                  message: t("AddReservation.Guest.countryRequired"),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputSelect
                  required={requiredFields.country_id}
                  label={`${t("AddReservation.Guest.country")} *`}
                  items={countries}
                  selectedValue={value}
                  onSelect={onChange}
                  size="large"
                  disabled={loading || isGuestSelected}
                  error={form.formState.errors.country_id?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="mt-6">
          <ErrorMessage>{error}</ErrorMessage>
        </div>

        <div className="flex gap-4 pb-4 mt-8">
          <Button type="secondary" disabled={loading} onClick={onCancel}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={ArrowRightWhiteIcon}
            loading={loading}
            disabled={
              loading || Object.entries(form.formState.errors).length !== 0
            }
          >
            {t("AddReservation.Guest.nextStep")}
          </Button>
        </div>
      </div>
    </form>
  );
};
